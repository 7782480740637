import axios from 'services/configApi';
const locale = 'en';

export const defaultCard = async (id) => {
  try {
    var formData = new FormData();
    formData.append("card", id);

    const result = await axios(`api/${locale}/data-search/payment-method/default-card`, { 
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
        },
      })

    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
