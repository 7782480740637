import React, {useEffect} from 'react';
import {
  Link
} from "react-router-dom";
import LoginPage from '../../components/Login'
const Login  = () => {
  useEffect(() => {
    document.title = 'Login SpoutINFO'
  });
  return (
    <div>
      <LoginPage/>
    </div>
  );
}
export default Login;