import React,{useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { USER_UPDATE_PROFILE_FAIL,
   USER_UPDATE_PROFILE_REQUEST,
   USER_ISUPDATE_PROFILE} from 'redux/reducers/User/actionTypes';
import {useForm} from 'react-hook-form';
import { resendProfileOTP } from 'services/user/resendProfileOTP';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
}));

const Profile = (props) => {

  const { handleSubmit, register, errors } = useForm();
  const error = useSelector(state => state.User.error_user_update_profile);
  const isUpdateProfile = useSelector(state => state.User.isUpdateProfile);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [giay, setGiay] = useState('00')
  const [phut, setPhut] = useState(3)
  const [counter, setCounter] = useState(179000);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (counter > 0) {
        setGiay(String(Math.floor((counter / 1000) % 60)).padStart(2, '0'));
        setPhut(Math.floor((counter / 1000 / 60) % 60))
        setCounter(counter - 1000);
      }
      else setGiay('00');
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };

  }, [counter]);
  
  useEffect(()=>{
    if(error.isError){
      Swal.fire({
        title: 'ERROR',
        text: error.message,
        icon: 'error',
        confirmButtonText: "Confirm",
        }
      ).then(
        function (isConfirm) {
          if (isConfirm) {
            dispatch({
              type: USER_UPDATE_PROFILE_FAIL,
              error: {
                isError: false,
                message: '',
              }
            });
          }
        },
        
      );
    }
      if(isUpdateProfile) {
      Swal.fire({
        title: 'SUCCESSFUL',
        text: 'User profile has been updated successfully',
        icon: 'success',
        confirmButtonText: "Confirm",
        }
      ).then(
        function (isConfirm) {
          if (isConfirm) {
            dispatch({
              type: USER_ISUPDATE_PROFILE,
              isUpdateProfile : false
            });
          }
        },
        
      );
    }

  },[error,isUpdateProfile])

const Resend = async() => {
  try {
    const result = await resendProfileOTP();
    Swal.fire({
      title: 'SUCCESSFUL',
      text: 'A new OTP has been sent',
      icon: 'success',
      confirmButtonText: "Confirm",
      }
    ).then(
      function (isConfirm) {
        if (isConfirm) {
          setCounter(179000);
          setPhut(3);
          setGiay('00')
        }
      },
      
    );
  }
  catch (error) {
    Swal.fire({
      title: 'ERROR',
      text: 'Please wait for 3 minutes and try again',
      icon: 'error',
      confirmButtonText: "Confirm",
      }
    ).then(
      function (isConfirm) {
        if (isConfirm) {
          setCounter(179000);
          setPhut(3);
          setGiay('00')
        }
      },
      
    );
  }
}
const updateProfile = (values) => {
  props.formData.append('code',values.code)
  dispatch({
    type: USER_UPDATE_PROFILE_REQUEST,
    payload : props.formData
  });
}

  return (
    <Paper className={`paper-profile otp ${classes.paper}`}>
      <div className="otp-title">
        <h3>Update profile</h3>
        <p>To protect your account, we have sent an OTP to<br/>your mobile number at +{props.country_code} ***** {props.phone_number}</p>
      </div>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          error={errors.code ? true : false}
          id="standard-full-width"
          label="OTP"
          style={{ margin: 8 }}
          placeholder="Enter OTP"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({
            required: 'Required'
          })}
          name="code"
        />
        <Button onClick={handleSubmit(updateProfile)} className="btn-user-profile" variant="contained" fullWidth>Submit</Button>
      </form>
      <p className="otp-noti">
        <span>Didn’t receive?</span>
        { counter ? <strong>Resend in {phut} minute {giay}s</strong>
        : <strong onClick={Resend} style={{textDecoration: 'underline', cursor:'pointer'}}>Resend</strong>}
      </p>
    </Paper>
  );
}
export default Profile; 