import axios from 'services/configApi';
const locale = 'en';

export const getListData = async (data) => {
  try {
    var formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });   
    const result = await axios(`api/${locale}/data-search/latest-price`, { 
        method: "POST",
        data : formData,
        headers: {
          Accept: "application/json",
        //   'USER-TOKEN': ''
        },
      })
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
