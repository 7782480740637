import React , {useEffect, useState} from 'react';
import { Grid,
  Button, } from '@material-ui/core';
import Input from '../../Input'
import ErrorMessage from 'components/ErrorMessage';
import {useForm} from 'react-hook-form';
import { useSelector, useDispatch } from "react-redux";
import {isEmpty} from 'lodash';
import { 
  USER_FORGET_PASSWORD_SUBMITED_REQUEST,
  USER_RESEND_RESET_PASSWORD_REQUEST,
  USER_UPDATE_STATUS_RESET
 } from 'redux/reducers/User/actionTypes';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
  BrowserRouter as Router,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import Web_logo_1 from 'asset/img/Web-logo-1.png';
import Web_logo_2 from 'asset/img/Web-logo-2.png';
const ForgetPasswordSubmited = () => {

  const {
    register,
    errors,
    handleSubmit
  } = useForm();
  let history = useHistory();
  const dispatch = useDispatch();
  const [giay, setGiay] = useState('15')
  const [phut, setPhut] = useState(3)
  const [counter, setCounter] = useState(194000);
  const user = useSelector(state => state.User);
  const [numberContries,setNumberContries] = useState('00')
  const [numberPhone,setNumberPhone] = useState('0000')
  const isResend = useSelector(state => state.User.isResend);
  const resendError = useSelector(state => state.User.resend_reset_password_error);
  
  useEffect(()=>{
    if(isEmpty(user.info_number))
    {
      history.push('/reset-password');
    }
    else{
      setNumberContries(user.info_number.country_code);
      setNumberPhone(user.info_number.phone_number.substr(-4,4));
    }
   
  },[user.info_number])
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (counter > 0) {
        setGiay(String(Math.floor((counter / 1000) % 60)).padStart(2, '0'));
        setPhut(Math.floor((counter / 1000 / 60) % 60))
        setCounter(counter - 1000);
      }
      else{
        setGiay('00');
      }
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };

  }, [counter]);
  useEffect(()=>{
    if(user.error_user_forget_submited.isError)
    {
      Swal.fire({
        title: 'Error!',
        text: user.error_user_forget_submited.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      .then(
        function (isConfirm) {
          dispatch({
            type : USER_UPDATE_STATUS_RESET
          })
        },
        
      );
    }
    // if(user.is_forget){
    //   history.push('/forget-password/submited')
    // }
  },[user])
  useEffect(()=>{
    if(isResend){
      Swal.fire({
        title: 'Success!',
        text: 'A new OTP has been sent',
        icon: 'success',
        confirmButtonText: 'Ok'
      }).then(
        function (isConfirm) {
          if (isConfirm) {
            setPhut(3);
            setGiay('15');
            setCounter(194000);
          }
        },
        
      );
    }
    if(resendError.isError){
      Swal.fire({
        title: 'Error!',
        text: resendError.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      }).then(
        function (isConfirm) {
          if (isConfirm) {
            setPhut(3);
            setGiay('15');
            setCounter(194000);
          }
        }
      ).catch ((error) =>{
        console.log(error);
      })
    }
  },[resendError, isResend])
  const onSubmit = async (data,e) =>{
    e.preventDefault(); 
    const info = {
      country_code: user.info_number.country_code,
      phone_number: user.info_number.phone_number,
      code: data.OTP
    }
    dispatch({
      type: USER_FORGET_PASSWORD_SUBMITED_REQUEST,
      
      data: info,
    })
  } 
  const redirectDasboard = () => {
    history.push('/');
  }
  const resendOTP = () => {
    const data = {
      country_code: user.info_number.country_code,
      phone_number: user.info_number.phone_number,
    }
    dispatch({
      type: USER_RESEND_RESET_PASSWORD_REQUEST,
      data: data
    });
  }
  const redirecteFeedlink = () => {
    window.open(
      'http://www.efeedlink.com/',
      '_blank'
    );
  }
  return (
    
        <Grid className="container_fp_submited" container >
            <Grid className="form" item lg={4} md={6} xs={12}>
                <Grid className="contentLeft" item lg={8} md={8} xs={12}>
                  <Grid className="account-box-logo"  item lg={12} md={12} xs={12}>
                    <div className="account-logo account-logo-efeed" onClick={redirecteFeedlink}>
                      <img src={Web_logo_1} width="116" height="34"/>
                    </div>
                    <div className="account-logo account-logo-spout" onClick={redirectDasboard}>
                      <img src={Web_logo_2} width="177" height="36"/>
                    </div>
                  </Grid>
                  <Grid className="formOTP" item lg={12} md={12} xs={12}>
                    <p>Enter OTP</p>
                    <p className="noted">We have sent an OTP to your mobile number at +{numberContries} ***** {numberPhone}</p>
                    <form  onSubmit={handleSubmit(onSubmit)} >
                    <Input
                      label={'OTP'}
                      name={'OTP'}
                      type={'string'}
                      fullWidth
                      required
                      placeholder={'OTP'}
                      error={errors.OTP }
                      validate={register({
                        required: true,
                        pattern:"/^[a-z\b]+$"
                      })}
                    />
                      {errors.OTP &&
                      (errors.OTP ).type === 'required' && (
                        <ErrorMessage>Please enter your OTP.</ErrorMessage>
                      )}
                      {errors.OTP &&
                      (errors.OTP ).type === 'pattern' && (
                        <ErrorMessage>Please enter your OTP.</ErrorMessage>
                      )}
                      <Button className="btn"  type='submit'>
                        Submit
                      </Button>
                      </form>
                  </Grid>
                  <Grid className="linkSignup">
                      <Grid><span>Didn’t receive?</span>
                      {!counter ?
                        <span onClick={resendOTP} className="time resend-OTP"> Resend code</span>
                      :
                        <span className="time"> Resend in {phut} minute {giay}s</span>
                      }
                        
                      </Grid>
                  </Grid>
                  <Grid className="Copyright">
                    <p>Copyright ©2020 SpoutINFO. All rights reserved.</p>
                  </Grid>
                </Grid>
            </Grid>
            <Grid className="imageLogin" item lg={8} md={6} xs>
              <Grid className="content" item lg={12} md={12} xs={12} sm={12}>
                    <Grid className="lable_1">A MARKET DATA PLATFORM FOR</Grid>
                    <Grid className="lable_2">THE FEED & LIVESTOCK SECTORS</Grid>
                    <Grid className="lable_3">Feed Additives | Livestock | Feed Grains</Grid>
                </Grid>
            </Grid>
        </Grid>
     
  );
}
export default ForgetPasswordSubmited; 