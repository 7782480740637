import React, {useEffect} from 'react';
import { Button } from '@material-ui/core';
import Select from './Select';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { 
  GET_STATS_REQUEST, 
  GET_LOCATION_REQUEST,
  UPDATE_SECTOR,
  GET_DATA_AVG_PRICE_REQUEST, 
  GET_DATA_PRICE_HISTORY_REQUEST,
  UPDATE_DATA_PRICE_HISTORY,
  GET_DATA_FILE_REPORT_REQUEST,
  GET_DATA_COMMENT_REQUEST,
  LOADING,
} from 'redux/reducers/SectorDetail/actionTypes'
import { GET_LIST_DATA_HOME_REQUEST, GET_LIST_MARKET_DATA_REQUEST } from 'redux/reducers/Data/ActionType';
import { 
  GET_DATA_CHART_REQUEST
} from 'redux/reducers/Chart/actionTypes';
import { GET_LIST_MARKET_COMM_REQUEST,UPDATE_DATA_MARKET_COMM,CHECK_DATA_HIDDEN } from 'redux/reducers/MarketComm/ActionType';
import {  } from 'redux/reducers/Data/ActionType';
const SelectTag  = (props) => {
  const sector = useSelector(state => state.Product);
  const dispatch = useDispatch();
  const getAnalistProduct = () =>{
    dispatch({
      type: GET_LIST_DATA_HOME_REQUEST,
      payload: {
        sector: sector.chosenSector.id,
        country : sector.chosenCountry.id,
        position: 0,
        user_country: "all"
      }
    });
  }
  const getMarketCom = () =>{
        dispatch({
          type: GET_LIST_MARKET_COMM_REQUEST,
          payload: {
            page: 1,
            country: sector.chosenCountry.id,
            filter: sector.chosenSector.id,
            user_country: 'all'
          }
        });
  }
  const getStats = () =>{

    dispatch({
      type: GET_STATS_REQUEST,
      pms :{
        product         : (sector.chosenProduct && sector.chosenProduct.id) ? sector.chosenProduct.id : '',
        sub_category_1  : (sector.chosenTier2 && sector.chosenTier2.id) ? sector.chosenTier2.id : '',
        sub_category_2  : (sector.chosenTier3 && sector.chosenTier3.id) ? sector.chosenTier3.id : ''
      }
    });
  }
  const getLocation = () =>{
    dispatch({
      type: GET_LOCATION_REQUEST,
      pms :{
        product         : (sector.chosenProduct && sector.chosenProduct.id) ? sector.chosenProduct.id : '',
        sub_category_1  : (sector.chosenTier2 && sector.chosenTier2.id) ? sector.chosenTier2.id : '',
        sub_category_2  : (sector.chosenTier3 && sector.chosenTier3.id) ? sector.chosenTier3.id : '',
        stats_id        : sector.chosenStat &&  sector.chosenStat.id  ? sector.chosenStat.id : null,
        stats_type      : sector.chosenStat &&  sector.chosenStat.type ? sector.chosenStat.type : ''
      }
    });
  }
  const getAvgPrice = () =>{
    dispatch({
      type: GET_DATA_AVG_PRICE_REQUEST,
      pms :{
        country: (sector.chosenCountry && sector.chosenCountry.id) ? sector.chosenCountry.id : '',
        region: (sector.chosenProvince && sector.chosenProvince.id) ? sector.chosenProvince.id : '',
        city: (sector.chosenCity && sector.chosenCity.id) ? sector.chosenCity.id : '',
        product: (sector.chosenProduct && sector.chosenProduct.id) ? sector.chosenProduct.id : '',
        sub_category_1: (sector.chosenTier2 && sector.chosenTier2.id) ? sector.chosenTier2.id : '',
        sub_category_2: (sector.chosenTier3 && sector.chosenTier3.id) ? sector.chosenTier3.id : '',
        stats_id: sector.chosenStat.id,
        stats_type: sector.chosenStat.type
      }
    });
  }
  const getHistory = () =>{
    dispatch({
      type: GET_DATA_PRICE_HISTORY_REQUEST,
      pms :{
        country:  (sector.chosenCountry && sector.chosenCountry.id) ? sector.chosenCountry.id : '',
        region: (sector.chosenProvince && sector.chosenProvince.id) ? sector.chosenProvince.id : '',
        city: (sector.chosenCity && sector.chosenCity.id) ? sector.chosenCity.id : '',
        product: (sector.chosenProduct && sector.chosenProduct.id) ? sector.chosenProduct.id : '',
        sub_category_1: (sector.chosenTier2 && sector.chosenTier2.id) ? sector.chosenTier2.id : '',
        sub_category_2: (sector.chosenTier3 && sector.chosenTier3.id) ? sector.chosenTier3.id : '',
        stats_id: sector.chosenStat.id,
        stats_type: sector.chosenStat.type,
        start_date: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        show_all: 'no'
      }
    });
  }
  const getChartData = () =>{
    dispatch({
      type: GET_DATA_CHART_REQUEST,
      pms :{
        country: (sector.chosenCountry && sector.chosenCountry.id) ? sector.chosenCountry.id : '',
        region: (sector.chosenProvince && sector.chosenProvince.id) ? sector.chosenProvince.id : '',
        city: (sector.chosenCity && sector.chosenCity.id) ? sector.chosenCity.id : '',
        product:(sector.chosenProduct && sector.chosenProduct.id) ? sector.chosenProduct.id : '',
        sub_category_1: (sector.chosenTier2 && sector.chosenTier2.id) ? sector.chosenTier2.id : '',
        sub_category_2: (sector.chosenTier3 && sector.chosenTier3.id) ? sector.chosenTier3.id : '',
        stats_id: sector.chosenStat.id,
        stats_type: sector.chosenStat.type,
        start_date: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD')
      }
    }); 

  }
  const getCommentReport = () =>{
    dispatch({
      type: GET_DATA_FILE_REPORT_REQUEST,
      data :{
        country_id:  (sector.chosenCountry && sector.chosenCountry.id) ? sector.chosenCountry.id : '',
        product_id: (sector.chosenProduct && sector.chosenProduct.id) ? sector.chosenProduct.id : '',
      }
    });
  }
  const getFileReport = () =>{
    dispatch({
      type: GET_DATA_COMMENT_REQUEST,
      data :{
         country_id:  (sector.chosenCountry && sector.chosenCountry.id) ? sector.chosenCountry.id : '',
         product_id: (sector.chosenProduct && sector.chosenProduct.id) ? sector.chosenProduct.id : '',
      }
    });
  }
  const marketData = () => {
    dispatch({
      type: GET_LIST_MARKET_DATA_REQUEST,
      payload: {
        sector: sector.chosenSector.id,
        country : sector.chosenCountry.id,
        position: 0,
        user_country: "all"
      }
    })
  }
   useEffect(() => {
    if(sector.chosenProduct && sector.chosenTier2 && sector.chosenTier3 && sector.isStats)
    {
        getStats();
    }
    if(sector.chosenProduct && sector.chosenTier2 && sector.chosenTier3 && sector.chosenStat && sector.isLocation)
    {
      getLocation();
    }
    
  }, [sector.chosenStat]);
  useEffect(() => {
    if(sector.chosenTier3 != null)
    {
        getStats();
    }
  }, [sector.chosenTier3]);
 
  const selectSector = (e) =>{
    let productId = sector.sectorDetail.sector_data[e.id].children[0].id;
    dispatch({
      type:UPDATE_SECTOR,
      
      
      chosenSector    : e,
      chosenProduct   : sector.sectorDetail.sector_data[e.id].children[0],
      // sectorProducts  : sector.sectorProducts,
      sectorProducts  : (productId != null) ? sector.sectorDetail.sector_data[e.id].children : [],
      tier2List       : (productId != null) ? sector.sectorDetail.product_data[productId].children : [],
      chosenTier2     : null,
      tier3List       : [],
      chosenTier3     : null,
      //*stats

      statList        : [],
      chosenStat      : {id: 0, name: 'Loading...'},
      //*country

      locationList    : [],
      chosenCountry   : {id: 0, name: 'Loading...'},
      provinceList    : [],
      chosenProvince  : null,
      CityList        : [],
      chosenCity      : null
    })
  }
  const selectProduct = (e) =>{
    dispatch({
      type:UPDATE_SECTOR,
      
      
      chosenSector    : sector.chosenSector,
      chosenProduct   : e,
      sectorProducts  : sector.sectorProducts,
      tier2List       : sector.sectorDetail.product_data[e.id].children  ? sector.sectorDetail.product_data[e.id].children : [],
      chosenTier2     : null,
      tier3List       : [],
      chosenTier3     : null,
      //*stats

      statList        : [],
      chosenStat      : {id: 0, name: 'Loading...'},
      //*country

      locationList    : [],
      chosenCountry   : {id: 0, name: 'Loading...'},
      provinceList    : [],
      chosenProvince  : null,
      CityList        : [],
      chosenCity      : null
    })
  }
  const selectTier2 = (e) =>{
    dispatch({
      type:UPDATE_SECTOR,
      
      
      chosenSector    : sector.chosenSector,
      chosenProduct   : sector.chosenProduct,
      sectorProducts  : sector.sectorProducts,
      tier2List       : sector.tier2List,
      chosenTier2     : e,
      tier3List       : sector.sectorDetail.sub_category_1_data[e.id].children ? sector.sectorDetail.sub_category_1_data[e.id].children : [],
      chosenTier3     : null,
      //*stats

      statList        : [],
      chosenStat      : {id: 0, name: 'Loading...'},
      //*country

      locationList    : [],
      chosenCountry   : {id: 0, name: 'Loading...'},
      provinceList    : [],
      chosenProvince  : null,
      CityList        : [],
      chosenCity      : null
    })
  }
  const selectTier3 = (e) =>{
    dispatch({
      type:UPDATE_SECTOR,
      
      
      chosenSector    :sector.chosenSector,
      chosenProduct   :sector.chosenProduct,
      sectorProducts  :sector.sectorProducts,
      tier2List       :sector.tier2List,
      chosenTier2     :sector.chosenTier2,
      tier3List       : sector.tier3List,
      chosenTier3     : JSON.parse(JSON.stringify(e)),
      //*stats

      statList        : [],
      chosenStat      : {id: 0, name: 'Loading...'},
      //*country

      locationList    : [],
      chosenCountry   : {id: 0, name: 'Loading...'},
      provinceList    : [],
      chosenProvince  : null,
      CityList        : [],
      chosenCity      : null
    })
   // getStats();
  }
  const selectStat = (e) =>{
     dispatch({
      type:UPDATE_SECTOR,
      
      
      chosenSector    :sector.chosenSector,
      chosenProduct   :sector.chosenProduct,
      sectorProducts  :sector.sectorProducts,
      tier2List       :sector.tier2List,
      chosenTier2     :sector.chosenTier2,
      tier3List       : sector.tier3List,
      chosenTier3     : sector.chosenTier3,
      //*stats

      statList        : sector.statList,
      chosenStat      :  JSON.parse(JSON.stringify(e)),
      isLocation         : true,
      //*country

      locationList    : [],
      chosenCountry   : {id: 0, name: 'Loading...'},
      provinceList    : [],
      chosenProvince  : null,
      CityList        : [],
      chosenCity      : null
    })
  }
  const selectCountry = (e) =>{
    dispatch({
      type:UPDATE_SECTOR,

      chosenSector    :sector.chosenSector,
      chosenProduct   :sector.chosenProduct,
      sectorProducts  :sector.sectorProducts,
      tier2List       :sector.tier2List,
      chosenTier2     :sector.chosenTier2,
      tier3List       :sector.tier3List,
      chosenTier3     :sector.chosenTier3,
      //*stats

      statList    : sector.statList,
      chosenStat  : sector.chosenStat,
      //*country

      locationList    : sector.locationList,
      chosenCountry   : e,
      provinceList    : sector.locationList.country_data[e.id].children,
      chosenProvince  : null,
      CityList        : [],
      chosenCity      : null
    })
    
  }
  const selectProvince = (e) =>{
    let list = sector.locationList.region_data[e.id].children
    dispatch({
      type:UPDATE_SECTOR,

      chosenSector    :sector.chosenSector,
      chosenProduct   :sector.chosenProduct,
      sectorProducts  :sector.sectorProducts,
      tier2List       :sector.tier2List,
      chosenTier2     :sector.chosenTier2,
      tier3List       :sector.tier3List,
      chosenTier3     :sector.chosenTier3,
      //*stats

      statList    : sector.statList,
      chosenStat  : sector.chosenStat,
      //*country

      locationList    : sector.locationList,
      chosenCountry   : sector.chosenCountry,
      provinceList    : sector.provinceList,
      chosenProvince  : e,
      CityList        : list,
      chosenCity      : list[0]
    })
  }
  const selectCity = (e) =>{
    
    dispatch({
      type:UPDATE_SECTOR,

      chosenSector    :sector.chosenSector,
      chosenProduct   :sector.chosenProduct,
      sectorProducts  :sector.sectorProducts,
      tier2List       :sector.tier2List,
      chosenTier2     :sector.chosenTier2,
      tier3List       :sector.tier3List,
      chosenTier3     :sector.chosenTier3,
      //*stats

      statList    : sector.statList,
      chosenStat  : sector.chosenStat,
      //*country

      locationList    : sector.locationList,
      chosenCountry   : sector.chosenCountry,
      provinceList    : sector.provinceList,
      chosenProvince  : sector.chosenProvince,
      CityList        : sector.cityList,
      chosenCity      : e
    })
  }
  const loading = () => {
    dispatch({
      type:LOADING,
      loading : true,
    })
  }
  const updateData = () =>{
    dispatch({
      type:UPDATE_DATA_MARKET_COMM,
    })
    dispatch({
      type:UPDATE_DATA_PRICE_HISTORY
    })
    dispatch({
      type:CHECK_DATA_HIDDEN,
      payload:false,
    })
  }

  const onSubmit = async () =>{
    loading();
    getAvgPrice();
    // getHistory();
    getChartData();
    getCommentReport();
    // getFileReport();
    getAnalistProduct();
    // getMarketCom();
    marketData();
    updateData();
  } 
  return(
   <form  className="formSelect">
         <div className="select">
            <Select
              label={'Sector'}
              required
              value={sector.chosenSector}
              name={'sector'}
              dataOptions={sector.sectorDetail.sectors}
              optionLabel={'title'}
              disabled={sector.sectorDetail.sectors.length == 0 ? true : false}
              onChangeFunction={selectSector}
            />
         </div>
         <div className="select">
            <Select
              label={'Product'}
              required
              name={'product'}
              value={sector.chosenProduct}
              dataOptions={sector.sectorProducts}
              optionLabel={'title'}
              disabled={(!sector.sectorProducts || sector.sectorProducts.length == 0) ? true : false}
              onChangeFunction={selectProduct}
              />
          </div>     
          <div className="select">
             <Select
                label={'Tier 2'}
                required
                name={'tier2'}
                value={sector.chosenTier2}
                dataOptions={sector.tier2List}
                optionLabel={'title'}
                disabled={(!sector.tier2List || sector.tier2List.length == 0) ? true : false}
                onChangeFunction={selectTier2}
              />
            </div> 
            <div className="select">
              <Select
                label={'Tier 3'}
                required
                name={'tier3'}
                value={sector.chosenTier3}
                dataOptions={sector.tier3List}
                optionLabel={'title'}
                disabled={(!sector.tier3List || sector.tier3List.length == 0) ? true : false}
                onChangeFunction={selectTier3}
              />
            </div>   
            <div className="select">
              <Select
                label={'Stats'}
                required
                name={'stats'}
                value={sector.chosenStat}
                dataOptions={sector.statList}
                disabled={(!sector.statList || sector.statList.length == 0) ? true : false}
                optionLabel={'name'}
                onChangeFunction={selectStat}
              />
            </div>   
            <div className="select">
                <Select
                  label={'Country'}
                  required
                  name={'country'}
                  value={sector.chosenCountry}
                  dataOptions={sector.locationList.countries}
                  optionLabel={'name'}
                  disabled={(!sector.locationList.countries || sector.locationList.countries.length == 0) ? true : false}
                  onChangeFunction={selectCountry}
                />
            </div> 
            <div className="select">
              <Select
                  label={'Province/State/Region'}
                  required
                  name={'region'}
                  value={sector.chosenProvince}
                  dataOptions={sector.provinceList}
                  optionLabel={'name'}
                  onChangeFunction={selectProvince}
                />
            </div>      
            <div className="select">
              <Select
                  label={'City'}
                  required
                  name={'city'}
                  value={sector.chosenCity}
                  dataOptions={sector.cityList}
                  optionLabel={'name'}
                  onChangeFunction={selectCity}
                />
                 <Button onClick={onSubmit} type="button" className={`btnApply ${sector.chosenSector && sector.chosenProduct && sector.chosenTier2 && sector.chosenTier3 && sector.chosenCountry ? '': `enable`}`}>Apply</Button>
            </div>  
             
    </form>
  )
}
export default SelectTag;