import React,{useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Profile from './Profile';
import Payment from './Payment';
import { useSelector, useDispatch } from "react-redux";
import OTP from './OTP';
import { 
  GET_LIST_CARD_REQUEST,
  SET_CARD_REQUEST, 
  SET_DEFAULT_CARD_REQUEST,
  REMOVE_CARD_REQUEST
} from 'redux/reducers/Card/ActionType';
import { USER_UPDATE_PROFILE_SENDOTP} from 'redux/reducers/User/actionTypes';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 882,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const UserProfile = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  
  const listCard = useSelector(state => state.Card.cards);
  const sendOTP = useSelector(state => state.User.sendOTP);
  const defaultCardFail = useSelector(state => state.Card.default_card_fail);
  const removeCardFail = useSelector(state => state.Card.remove_card_fail);
  
  const [value, setValue] = React.useState(0);
  const [isShow, setIsShow] = React.useState(true);
  const [expanded, setExpanded] = React.useState('payment-new');
  const [isDisabled, setIsDisabled] = React.useState(false);

  useEffect(() => {
      dispatch({
        type: GET_LIST_CARD_REQUEST,
      });
     
  }, []);

  useEffect(() => {
    if(defaultCardFail.isError && !defaultCardFail.isLoad) {
      Swal.fire({
        title: 'Error!',
        text: defaultCardFail.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }
    if(!defaultCardFail.isError && !defaultCardFail.isLoad) {
      Swal.fire({
        title: 'Success!',
        text: 'Set card as successful default payment.',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
    }
  },[defaultCardFail]);

  useEffect(() => {
    if(removeCardFail.isError && !removeCardFail.isLoad)  {
      setIsDisabled(false);
      Swal.fire({
        title: 'Error!',
        text: defaultCardFail.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }
    if(!removeCardFail.isError && !removeCardFail.isLoad) {
      setIsDisabled(false);
      Swal.fire({
        title: 'Success!',
        text: 'Successfully deleted the card.',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
    }
  },[removeCardFail]);

  const handleChange = (event, newValue) => {
    if(newValue){
      history.push('/payment-method')
    }
    else
    {
      history.push('/user-profile')
    }
    setValue(newValue);
  };

  const handleChangeExpand = panel => (event, isExpanded) => {
    event.stopPropagation();
    setExpanded(isExpanded ? panel : false);
  };

  const handleDefaultCard = card_id => (event) => {
    if(event.target.value == 0) {
      dispatch({
        type: SET_DEFAULT_CARD_REQUEST,
        id: card_id
      });
    }
    
  };

  const handleRemoveCard = card_id => (event) => {
    if(isDisabled) return;
    setIsDisabled(true);
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to delete this card?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        dispatch({
          type: REMOVE_CARD_REQUEST,
          id: card_id
        });
      }
      else {
        setIsDisabled(false);
      }
    })
  };

  const [firstUser, setFirstUse] = React.useState(true)
  useEffect(() => {
    dispatch({
      type: USER_UPDATE_PROFILE_SENDOTP,
      OTP: {
        verify_otp: false,
        country_code: '',
        phone_number: '',
        formData: null
      }
    })
  },firstUser);
  
  const handleChangeIndex = index => {
    setValue(index);
  };
  if(sendOTP.verify_otp){
    return ( <div className={`account ${classes.root}`} id="user-profile">
              <OTP formData={sendOTP.formData} country_code={sendOTP.country_code} phone_number={sendOTP.phone_number}/>
          </div>
    )
  }
  return (
    <div className={`account ${classes.root}`} id="user-profile">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="My Profile" {...a11yProps(0)} />
          {/*<Tab label="Payment Method" {...a11yProps(1)} />*/}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Profile/>
      </TabPanel>
      {/*<TabPanel value={value} index={1}>
        <Paper className={`paper-profile ${classes.paper}`}>
          {listCard.map((item,key)=>{
            return(
              <ExpansionPanel key={key} className="box-Payment" expanded={expanded === `payment-detail-${key}`} onChange={handleChangeExpand('payment-detail-' + `${key}`)}>
                <ExpansionPanelSummary
                  aria-controls="panel1c-content"
                  id="panel1c-header"
                  style={{ height:106 }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div className="card-info">
                        <p>Card Ending with <strong>{item.last4}</strong></p>
                        {item.is_default == 1 && expanded !== `payment-detail-${key}` ? (<p>Default Payment</p>) : (<p></p>)}
                      </div>
                      {expanded !== `payment-detail-${key}` ? (<span className="btn-edit">EDIT</span>) : ''}
                    </Grid>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.details}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div className="box-card box-card-number">
                        <label>Card Number</label>
                        <TextField
                          fullWidth
                          placeholder="XXXX XXXX XXXX XXXX"
                          margin="normal"
                          value={`****${item.last4}`}
                          disabled
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                        />
                      </div>
                      <div className="box-card box-expiry">
                        <label>Expiry Date</label>
                        <TextField
                          fullWidth
                          placeholder="MM/YY"
                          value={`${item.exp_month < 10 ? '0'+item.exp_month : item.exp_month }/${item.exp_year.toString().substr(2, 2)}`}
                          margin="normal"
                          disabled
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                        />
                      </div>
                      <div className="box-card box-cvc">
                        <label>CVV</label>
                        <TextField
                          fullWidth
                          value={`***`}
                          margin="normal"
                          disabled
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                        />
                      </div>
                      <div className="box-card">
                        <label>Card Holder Name</label>
                        <TextField
                          placeholder="Name"
                          fullWidth
                          value={item.name}
                          margin="normal"
                          disabled
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true
                          }}
                        />
                      </div>
                      <div className="payment-set-default">
                        <FormControlLabel className="radio-default" value={item.is_default} checked={item.is_default === 1} color="default" control={<Radio />} label="Default Payment" onClick={handleDefaultCard(`${item.id}`)} />
                      </div>
                    </Grid>
                    <Grid item xs={10} md={4}>
                      <Button className="btn-remove-card" onClick={handleRemoveCard(`${item.id}`)} disabled={isDisabled}>REMOVE CARD</Button>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
          <ExpansionPanel className="box-Payment" expanded={expanded === 'payment-new'} onChange={handleChangeExpand('payment-new')}>
            <ExpansionPanelSummary
              aria-controls="panel1c-content"
              id="panel1c-header"
              style={{ height:64 }}
            >
              <Grid item xs={12} className="title-card">
                <label>Add New Payment Method</label>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <Payment/>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Paper>
      </TabPanel>*/}
    </div>
  );
}
export default UserProfile; 