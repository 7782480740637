import styled from 'styled-components';

const Optional = styled.span`
  font-size: 12px;
  color: #616061;
  font-style: italic;
  font-family: Roboto;
`;

export default Optional;
