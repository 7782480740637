import React, {useEffect} from 'react';
import AboutPage from 'components/About';
import Header from 'components/Header';
import Footer from 'components/Footer';
import BgHeader from '../../components/HeaderBackgound';

const About = (props) => {
  useEffect(() => {
    document.title = 'About SpoutINFO'
  });
  return(
    <div>
      <Header breadcrumb={props.breadcrumb}/>
      <BgHeader isSearch={false}/>
      <AboutPage/>
      <Footer/>
    </div>
  )
}
export default About;