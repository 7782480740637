import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles(() =>
  createStyles({
    disabled: {
      backgroundColor: '#141518',
      color: 'white!important',
    },
    selectContainer: {
      position: 'absolute!important',
      top: 0,
      left: 0,
      borderRight: `1px solid #bfbfc2`,
      width: '100px',
      display: 'inline-block',
      '&:focus': {
        outline: 'unset',
      },
    },
    StyledInput: {
      border: 'none',
      width: '250px',
      marginLeft: '110px',
    },
    inputContainer: {
      position:'relative',
      border: `1px solid #bfbfc2`,
      borderRadius: '4px',
      height: '40px',
      width: '100%',
      marginBottom: '15px'
    },
    inputContainerError: {
      position:'relative',
      borderRadius: '4px',
      border: `solid 2px #db4437 !important`,
      backgroundColor: `rgba(219, 68, 55, 0.15) !important`,
    },
  })
);

export default styles;
