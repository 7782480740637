import React, {useEffect} from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import BgHeader from 'components/HeaderBackgound';
import {
  Link
} from "react-router-dom";
import UserProfile from 'components/UserProfile';
const AccountProfile  = (props) => {
  useEffect(() => {
    document.title = 'User Profile SpoutINFO'
  });
  return (
    <div>
      	<Header breadcrumb={props.breadcrumb}/>
      	<UserProfile/>
      	<Footer/>
    </div>
  );
}
export default AccountProfile;