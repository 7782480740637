import axios from 'services/configApi';

export const login = async (data) => {
  try {

    var formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });   
    const res = await axios(`api/en/data-search/auth/sign-in`, { 
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json, text/javascript, */*; q=0.01",
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
      })    
    localStorage.setItem('token',res.data.data.token)

    localStorage.setItem('user',JSON.stringify(res.data.data))
    
    return res.data.data;
    
  } catch (error) {

    return Promise.reject(error);

  }
};
