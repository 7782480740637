import axios from 'services/configApi';
const locale = 'en';

export const getListMarketData = async (params) => {
    try {
        var formData = new FormData();
        Object.keys(params).forEach(key => {
          formData.append(key, params[key]);
        });   
        const result = await axios(`api/${locale}/data-search/latest-price`, { 
        method: "POST",
        headers: {
            Accept: "application/json",
        },
        data : formData
        })
        return result.data;
    } catch (error) {
        return Promise.reject(error);
    }
};