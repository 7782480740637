import styled from 'styled-components';

const InputContainer = styled.div`
  margin-bottom: 15px;
  position: relative;
  @media only screen and (max-width: 414px) {
    width:100%;
  }
`;

export default InputContainer;
