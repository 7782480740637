import styled from 'styled-components';

const ErrorMessage = styled.div`
  color: Red;
  font-weight: 500;
  font-size: 12px;
  margin-top: 10px;
`;

export default ErrorMessage;
