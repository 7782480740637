import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useSelector, useDispatch } from "react-redux";
import { GET_LIST_COUNTRIES_MARKET_FORECAST_REQUEST } from 'redux/reducers/Countries/ActionType';
import { GET_LIST_CATEGORY_REQUEST } from 'redux/reducers/Category/ActionType';
import { GET_LIST_MARKET_COMM_REQUEST } from 'redux/reducers/MarketComm/ActionType';
import { SET_STATUS_LOADING_MARKET_FORECAST, SET_REDIRECT_MARKET_FORECAST, SET_TITLE_MARKET_FORECAST } from 'redux/reducers/MarketForecast/ActionType';
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import Analysis from 'components/Analysis';
import { useHistory } from "react-router-dom";
import { routes } from 'routers/routes';
import LoadingAnalysis from 'components/LoadingAnalysis';
import { getCommentReport } from 'services/SectorDetail/getCommentReport';
import Loading from 'components/loading'
import SubscribePopup from 'components/SubscribePopup';

const LayoutMarketComm = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [sectorId, setSectorID] = React.useState(0); 
  const [loadingApp, setLoadingApp] = React.useState(false); 
  const [countriesId, setCountriesId] = React.useState(0); 
  const ListCountries = useSelector(state => state.Countries.countriesMarketForecast);
  const ListCategory = useSelector(state => state.Category.category);
  const ListMarketComm = useSelector(state => state.MarketComm.marketComm);
  const LoadingStatus = useSelector(state => state.Data.isLoading);
  const [isPaid, setIsPaid] = React.useState(false); 

  useEffect(() => {
      dispatch({
        type: SET_REDIRECT_MARKET_FORECAST,
        payload: null
      });
      dispatch({
        type: GET_LIST_COUNTRIES_MARKET_FORECAST_REQUEST,
      });
      dispatch({
        type: GET_LIST_CATEGORY_REQUEST,
      });
  }, []);
  useEffect(() => {
    if(ListCountries.length && ListCategory.length)
    {
      setSectorID(ListCategory[Math.floor(Math.random() * (ListCategory.length-1) + 1)].id);
      setCountriesId(ListCountries[0].id);
      dispatch({
        type: GET_LIST_MARKET_COMM_REQUEST,
        payload: {
          page: 1,
          country: ListCountries[0].id,
          filter: ListCategory[0].id,
          user_country: ListMarketComm.user_country
        }
      });
    }
    
  }, [ListCountries, ListCategory]);
  const [selectedIndexCountries, setIndexCountries] = React.useState(0);
  const [selectedIndexCategory, setIndexCategory] = React.useState(0);
  const [selectedIndexMarket, setIndexIndexMarket] = React.useState();
  
  const handleChoseCategory = (index, item) => {
    let id = item.id;
    if(item.id == "all") {
      id = ListCategory[Math.floor(Math.random() * (ListCategory.length-1) + 1)].id;
    }
    setSectorID(id);
    dispatch({
      type: GET_LIST_MARKET_COMM_REQUEST,
      payload: {
        page: 1,
        country: ListMarketComm.country,
        filter: item.id,
        user_country: ListMarketComm.user_country
      }
    });
    setIndexCategory(index);
  };
  const handleChoseCountries =  (index, item) => {
    setCountriesId(item.id);
    dispatch({
      type: GET_LIST_MARKET_COMM_REQUEST,
      payload: {
        page: 1,
        country: item.id,
        filter: ListMarketComm.filter,
        user_country: ListMarketComm.user_country
      }
    });
    setIndexCountries(index);
  };
  const handleChoseMarket = async(index, item) => {
    setIndexIndexMarket(index);
    if(item.is_paid){
      document.cookie = `sector_id=${item.sector_id}`;
      document.cookie = `country_id=${item.country_id}`;
      document.cookie = `product_id=${item.product_id}`;
      setLoadingApp(true);
      dispatch({
        type: SET_STATUS_LOADING_MARKET_FORECAST,
        showLoading : true
      });
      dispatch({
        type: SET_TITLE_MARKET_FORECAST,
        payload : item.message
      });
        history.push(`/market-forecast-detail/${item.sector_id}/${item.country_id}/${item.product_id}`); 
    }
    else{
      setIsPaid(true);
     //history.push(routes.subscribe);
    }
  }
  const getInfoDate = (type ,date) => {
    switch (type) {
      case 'year':
        const numberYear = moment(date, 'YYYY-MM-DD').format('YYYY');
        return numberYear;
      case 'month':
        const numberMonth = moment(date, 'YYYY-MM-DD').format('MMM');
        return numberMonth.toUpperCase();
      case 'day':
        const numberDay = moment(date, 'YYYY-MM-DD').format('DD');
        return numberDay;
      default:
        break;
    }
  }
  const handleLoadMore = () => {
    if(!ListMarketComm.isFull && !ListMarketComm.isLoad )
    dispatch({
      type: GET_LIST_MARKET_COMM_REQUEST,
      payload: {
        page: ListMarketComm.page + 1,
        country: ListMarketComm.country,
        filter: ListMarketComm.filter,
        user_country: ListMarketComm.user_country
      }
    })
  }
  const updateIsPaid = (status) => {
    setIsPaid(status)
  }
  return (
    <>
      <div className="category-and-countries">
        <Grid container>
          <Grid item xs={12} className="category">
            {ListCategory.map((item,key) => {
              return(
                <Button
                  className={`card btn-select-category ${selectedIndexCategory == key ? 'active' : ''}` }
                  key={key}
                  onClick={() => handleChoseCategory(key, item)}
                >
                  {item.title}
                </Button>
              );
            })}
          </Grid>
          <Grid item xs={12} className="countries">
            {ListCountries.map((item, key) => {
              return (
                <Button
                  className={`btn-select-countries ${selectedIndexCountries == key ? 'active' : ''}` }
                  key={key}
                  onClick={()=> handleChoseCountries(key, item)}
                >
                  {item.name}
                </Button>
              )
            })}
          </Grid>
        </Grid>
      </div>
      <div className="market-comm">
        <Grid container>
          <Grid item lg={9} sm={8} xs={12} className="defaut-height mb-w-100">
            <List component="nav">
              <InfiniteScroll
                pageStart={0}
                loadMore={handleLoadMore}
                hasMore={true || false}
                loader={ ListMarketComm.isLoad ? <div className="loader mobile-center-loader" key={0}><CircularProgress className="load-more" /></div> : null}
              >
                {ListMarketComm.data.map((item,key) => {
                  return (
                    <ListItem
                      key={key}
                      button
                      selected={selectedIndexMarket === key}
                      onClick={() => handleChoseMarket(key, item)}
                      className="mb-pd-20"
                    >
                      <div>
                        <ListItemText className="date-comm">
                          {getInfoDate('day', item.updated_at)}
                          <br/>
                          {getInfoDate('month', item.updated_at)}
                        </ListItemText>
                      </div>
                      <div>
                        <Link className="link-market" to='#'>{item.message}</Link>
                        <ListItemText className="text-sub-market">
                          {getInfoDate('year', item.updated_at)} {item.product.title}, {item.country.name}
                        </ListItemText>
                      </div>
                    </ListItem>
                  )
                })}
              </InfiniteScroll>
            </List>
          </Grid>
          <Grid item lg={3} sm={4} xs={12} className="select-analysis">
            <Analysis
              idSector={sectorId}
              idCountry={countriesId}
            />
            {LoadingStatus ? 
              <LoadingAnalysis/>
            :
              null
            }
          </Grid>
        </Grid>
        {loadingApp ?  <Loading></Loading> : null}
        {isPaid ?  <SubscribePopup changeHandler={updateIsPaid}/> : null}

        
      </div>
    </>
  );
}
export default LayoutMarketComm;