import React from 'react';

const SVG = ({
  style = {},
  fill = '#000',
  width = '100%',
  height = '100%',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      d="M12.9575263,3.37377959 C13.2946594,3.55767033 13.5717225,3.83473343 13.7556132,4.17186647 L21.674161,18.6892041 C22.2030865,19.6589007 21.8457708,20.873774 20.8760741,21.4026995 C20.5822969,21.5629416 20.2530078,21.6469083 19.9183699,21.6469083 L4.08127431,21.6469083 C2.97670481,21.6469083 2.08127431,20.7514778 2.08127431,19.6469083 C2.08127431,19.3122703 2.16524105,18.9829813 2.32548316,18.6892041 L10.2440309,4.17186647 C10.7729564,3.2021698 11.9878297,2.84485413 12.9575263,3.37377959 Z M12.0894065,16.4734103 C11.5699075,16.4734103 11.1487705,16.8945473 11.1487705,17.4140463 C11.1487705,17.9335452 11.5699075,18.3546823 12.0894065,18.3546823 C12.6089054,18.3546823 13.0300425,17.9335452 13.0300425,17.4140463 C13.0300425,16.8945473 12.6089054,16.4734103 12.0894065,16.4734103 Z M12.0894065,9.41864015 C11.5699075,9.41864015 11.1487705,9.83977724 11.1487705,10.3592762 L11.1487705,10.3592762 L11.1487705,14.1218202 C11.1487705,14.6413191 11.5699075,15.0624562 12.0894065,15.0624562 C12.6089054,15.0624562 13.0300425,14.6413191 13.0300425,14.1218202 L13.0300425,14.1218202 L13.0300425,10.3592762 C13.0300425,9.83977724 12.6089054,9.41864015 12.0894065,9.41864015 Z"
    />
  </svg>
);

export default SVG;
