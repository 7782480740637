import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  ZoomAndPan,
  SplineSeries,
  Tooltip
} from '@devexpress/dx-react-chart-material-ui';
import { scaleTime } from 'd3-scale';
import { ArgumentScale } from '@devexpress/dx-react-chart';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { EventTracker } from '@devexpress/dx-react-chart';
import Modal from '@material-ui/core/Modal';
import { Grid } from '@material-ui/core';
import zoomOut from 'asset/images/zoomOut.jpg'
import zoom from 'asset/images/zoom.jpg'
// import { connect } from "react-redux";
import { DateRangePicker } from 'rsuite';
import ModalForm from '../PopperFormSelect';
const chartRootStyle = { marginRight: '20px' };
const inputsContainerStyle = { justifyContent: 'center' };
const ChartRoot = props => (
  <Chart.Root {...props} style={chartRootStyle} />
);


 class chart extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      dataChart : [],
      viewPort : undefined,
      isShow : false,
      TimeStart:this.props.timeStart
    };
    this.submit = e => this.setState({
      [e.target.id]: e.target.checked,
    });
  }
  
  renderInput(id, label) {

    const { [id]: checked } = this.state;
    return (
      <FormControlLabel
        control={(
          <Checkbox
            id={id}
            checked={checked}
            onChange={this.submit}
            value="checkedB"
            color="primary"
          />
        )}
        label={label}
      />
    );
  }
    viewPortChange =(e)=>{
    if(moment(e.argumentEnd).isValid() && moment(e.argumentStart).isValid()){
      if(!this.state.viewPort) {
        return   this.setState({viewPort :  e})
      }
      if(moment(e.argumentEnd).diff(moment(e.argumentStart)) >  moment(this.state.viewPort.argumentEnd).diff(moment(this.state.viewPort.argumentEnd))) {
        this.setState({viewPort :  e})
      }
      else {
        if( moment(e.argumentEnd).diff(moment(e.argumentStart), 'days') <= 2) return
        this.setState({viewPort :  e})
      }
    }
  }
  
  zoomIn = (e) => {
   
    if(!this.state.dataChart.length) return
    if(!this.state.viewPort) {
     
      let  argumentStart = moment(this.state.dataChart[0].date).add(3, 'days');
      let  argumentEnd =  moment(this.state.dataChart[this.state.dataChart.length-1].date).subtract(3, 'days');
      let dayDiff =  argumentEnd.diff(argumentStart, 'days');
      if(dayDiff <= 3) return
        this.setState({viewPort : {
          argumentStart : argumentStart,
          argumentEnd : argumentEnd
        }})
    }
    else{
      let argumentStart =  moment(this.state.viewPort.argumentStart)
      let argumentEnd = moment(this.state.viewPort.argumentEnd) 
      let dayDiff =  argumentEnd.diff(argumentStart, 'days');
      if(dayDiff <= 3) return
      argumentStart =  argumentStart.add(3, 'days');
      argumentEnd =  argumentEnd.subtract(3, 'days');
      this.setState({
        viewPort : {
          argumentStart: argumentStart,
          argumentEnd: argumentEnd
        }
      })
    }
  }
   zoomOUT =() => {
    if(!this.state.viewPort) return
    let argumentStartqk = new  moment(this.state.viewPort.argumentStart)
    let argumentEndqk = new moment(this.state.viewPort.argumentEnd) 
    let argumentStart =  argumentStartqk.clone().subtract(3, 'days');
    let argumentEnd =  argumentEndqk.clone().add(3, 'days');
    if(argumentStart.diff(moment(this.state.dataChart[0].date), 'days')){
      this.setState({
        viewPort : {
          argumentStart: argumentStart,
          argumentEnd: argumentEnd
        }
      })
    }   
    
  }

   handleOpen = () => {
    if(this.state.dataChart.length)
      this.setState({isShow: true })
  };

   handleClose = () => {
    this.setState({isShow : false })
  };
  UNSAFE_componentWillReceiveProps(nexProps) {
    if(nexProps.chartData != this.props.chartData){
      this.setState({TimeStart: this.props.timeStart})
      let dataServe = [];
      if(this.props.chosenCity && this.props.chosenProvince) {
        nexProps.chartData.map((item,key)=>{
          let itemChart = {
            country: item.country.price,
            date: new moment(item.date, "YYYY-MM-DD")
          };
          if(key == 0 && !item.city)
          {
             itemChart.city = null;
          }
          else{
             itemChart.city = item.city ? item.city.price : dataServe[key-1].city;
          }

          if(key == 0 && !item.region)
          {
             itemChart.region = null;
          }
          else{
              itemChart.region = item.region ? item.region.price : dataServe[key-1].region;
          }
         
         
          dataServe.push(itemChart);
          return itemChart
         })
      }
      else
      if(this.props.chosenCity && !this.props.chosenProvince) {
        nexProps.chartData.map((item,key)=>{
          let itemChart = {
            country: item.country.price,
            date: new moment(item.date, "YYYY-MM-DD"),
          
          };
           if(key == 0 && !item.city)
          {
             itemChart.city = null;
          }
          else{
             itemChart.city = item.city ? item.city.price : dataServe[key-1].city;
          }
          dataServe.push(itemChart);
          return itemChart
         })
      }
      else if(!this.props.chosenCity && this.props.chosenProvince) {
        nexProps.chartData.map((item,key)=>{
          let itemChart = {
            country: item.country.price,
            date: new moment(item.date, "YYYY-MM-DD"),
           
          };
           if(key == 0 && !item.region)
          {
             itemChart.region = null;
          }
          else{
              itemChart.region = item.region ? item.region.price : dataServe[key-1].region;
          }
         
          dataServe.push(itemChart);
          return itemChart
         })
      }
      else {
        nexProps.chartData.map((item,key)=>{
          let itemChart = {
            country: item.country.price,
            date: new moment(item.date, "YYYY-MM-DD"),
            
          };
          dataServe.push(itemChart);
          return itemChart
         })
      }
       this.setState({dataChart : dataServe})
    }
   
  };
  changeDate = (e) =>{
    this.props.changeDate(e)
    const startDate = moment(e[0]).format('YYYY-MM-DD');
    const endDate = moment(e[1]).format('YYYY-MM-DD');

    // setTimeStart(startDate,endDate);
    this.setState({TimeStart : e})
    this.props.dispatch({
      type: 'GET_DATA_CHART_REQUEST',
      pms :{
        country: this.props.sectorData.chosenCountry.id,
        region: (this.props.sectorData.chosenProvince && this.props.sectorData.chosenProvince.id) ? this.props.sectorData.chosenProvince.id : '',
        city: (this.props.sectorData.chosenCity && this.props.sectorData.chosenCity.id) ? this.props.sectorData.chosenCity.id : '',
        product: this.props.sectorData.chosenProduct.id,
        sub_category_1: (this.props.sectorData.chosenTier2 && this.props.sectorData.chosenTier2.id) ? this.props.sectorData.chosenTier2.id : '',
        sub_category_2: (this.props.sectorData.chosenTier3 && this.props.sectorData.chosenTier3.id) ? this.props.sectorData.chosenTier3.id : '',
        stats_id: this.props.sectorData.chosenStat.id,
        stats_type: this.props.sectorData.chosenStat.type,
        start_date: startDate,
        end_date: endDate
      }
    });
  };
  TooltipContent= (e)=> {
    // console.log(this.state.dataChart);
    // //console.log(this.state.dataChart[e.targetItem.point])
    // console.log(this.props.chartData)
    // return <div>1111</div>
  }
  render() {
    return (
      <div className="rounded_chart">
      {this.state.dataChart.length ? 
      <Chart height={400} data={this.state.dataChart} >
          <ArgumentScale factory={scaleTime} />
          <ArgumentAxis showGrid={true}/>
          <ValueAxis />
          <SplineSeries valueField="city" color={"#F9D649"} argumentField="date" />
          <SplineSeries valueField="region" color={"#00E6AC"} argumentField="date" />
          <SplineSeries valueField="country" color={"#558ED5"}  argumentField="date" />
          {this.state.dataChart.length > 0 && (
            <ZoomAndPan
           viewport={this.state.viewPort}
            onViewportChange={this.viewPortChange}
          />
          )}
          <EventTracker />
          <Tooltip />
        </Chart>  : 
        <div style={{height:400, justifyContent :'center',alignItems: 'center', display:'flex'}}>  <span>No data. Please try another date range.</span></div> }
           
        <Grid className="noteChart" container>
            <Grid container item md={2}>
              <Grid  item  className="squareCountry"></Grid>
              <Grid className="Country" item md={8}>Country</Grid>
            </Grid>

            <Grid container item md={2}>
              <Grid  item className="squareProvince"></Grid>
              <Grid className="Province" item md={8}> Province</Grid>
            </Grid>

            <Grid container item md={2}>
              <Grid item className="squareCity"></Grid>
              <Grid className="City" item md={8}>City</Grid>
            </Grid>
        </Grid>
        <Grid className="btnChartData" container item md={6} xs={12}>
                <Grid container className="plus_and_min">
                  <span className="line"></span>
                  <Grid className="plus" onClick={() => this.zoomIn()} item md={6} xs={6}>+</Grid>
                  <Grid className="mins" onClick={() => this.zoomOUT()} item md={6} xs={6}>-</Grid>
                </Grid>  
                <Grid container className="zoomm" onClick={() => this.handleOpen()}>
                  <img src={zoom} alt="" />
                </Grid>  
            </Grid>
        <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.isShow}
        onClose={this.handleClose}
        style={{backgroundColor:"#fff"}}
        >
        <div className="rounded_chart" style={{backgroundColor:"#FFF"}}>
          <Grid className="container">
            <Grid className="infoVitamin" container item xs={12}>
              <Grid container item xs={12} className="modalAndVitamin">
                <Grid item md={7} xs={12} className="title"><p className="vitamin">{this.props.sectorData && this.props.sectorData.chosenTier2 ? this.props.sectorData.chosenTier2.title : '-'} ,{this.props.sectorData && this.props.sectorData.chosenTier3 ? this.props.sectorData.chosenTier3.title : '-'}</p></Grid>
                <Grid item md={5} xs={12} className="modalProduct"><ModalForm name={'Filter'} chosenTier3={this.props.sectorData.chosenTier3} chosenCountry={this.props.sectorData}/></Grid>
              </Grid>
              <Grid className="currency"> <span className="gmb">{this.props.sectorData && this.props.sectorData.avgPrice ? this.props.sectorData.avgPrice.currency : '-'} </span><span className="average"> {this.props.sectorData && this.props.sectorData.avgPrice && this.props.sectorData.avgPrice.start_date ? moment(this.props.sectorData.avgPrice.start_date).format('DD-MMM-YYYY'): '-'} - {this.props.sectorData && this.props.sectorData.avgPrice && this.props.sectorData.avgPrice.end_date ? moment(this.props.sectorData.avgPrice.end_date).format('DD-MMM-YYYY'): ''} Average</span></Grid>
            </Grid>
            <Grid className="number" container item xs={12}>
              <Grid className="numberLeft" item  md={5} xs={12}>{this.props.sectorData && this.props.sectorData.avgPrice ? this.props.sectorData.avgPrice.price : '-'} {this.props.sectorData && this.props.sectorData.avgPrice && this.props.sectorData.avgPrice.price_variation =="decrease" ? <label className="fluctuations decrease"></label>: <label className="fluctuations increase"></label>}</Grid>
              <Grid className="numberRight" item md={7} xs={12}>
                <Grid >
                  <span className="previous">Previous</span>
                  <span>{this.props.sectorData && this.props.sectorData.avgPrice && this.props.sectorData.avgPrice.previous_price ? this.props.sectorData.avgPrice.previous_price : ''}</span>{this.props.sectorData && this.props.sectorData.avgPrice && this.props.sectorData.avgPrice.previous_price_variation =="decrease" ? <label className="fluctuations decrease"></label> : <label className="fluctuations increase"></label>}
                </Grid>
                <Grid>
                  <span className="fluc">Fluctuation</span>
                  <span>{this.props.sectorData && this.props.sectorData.avgPrice && this.props.sectorData.avgPrice.fluctuation ? this.props.sectorData.avgPrice.fluctuation : ''}</span>{this.props.sectorData && this.props.sectorData.avgPrice && this.props.sectorData.avgPrice.fluctuation_variation =="decrease" ? <label className="fluctuations decrease"></label> : <label className="fluctuations increase"></label>}
                </Grid>
              </Grid>
            </Grid>
            <Grid className="exportPrice">
                <Grid className="time">
                {this.props.sectorData && this.props.sectorData.commentReport ? (this.props.sectorData.commentReport[0] ? moment( this.props.sectorData.commentReport[0].created_at).format('DD-MMM') : '-')  : '-'}
                </Grid> 
                <Grid className="country">
                {this.props.sectorData && this.props.sectorData.commentReport ?  (this.props.sectorData.commentReport[0] ? this.props.sectorData.commentReport[0].title : '-') : '-'}
                {/* China Calcium Pantothenate: Market under pressure (week ended Jan 24, 2020) */}
                </Grid>
                <Grid className="export"> 
                {this.props.sectorData && this.props.sectorData.chosenCountry && this.props.sectorData.chosenCountry.name ? this.props.sectorData.chosenCountry.name : ''}, {this.props.sectorData && this.props.sectorData.chosenTier2 && this.props.sectorData.chosenTier2.title ? this.props.sectorData.chosenTier2.title : ''}
                {/* 2019 Vitamin A, China, Expore Price */}
                </Grid>
            </Grid>
            <Grid className="datetime" container >
              <Grid item md={6} xs={12}>
                <DateRangePicker
                  disabled={this.props.sectorData.chosenProduct && this.props.sectorData.chosenProduct.id ? false : true}
                  value={this.state.TimeStart}
                  onChange= { this.changeDate}
                  format="DD-MMM-YYYY"
                  locale={{
                    sunday: 'Su',
                    monday: 'Mo',
                    tuesday: 'Tu',
                    wednesday: 'We',
                    thursday: 'Th',
                    friday: 'Fr',
                    saturday: 'Sa',
                    ok: 'OK',
                    today: 'Today',
                    yesterday: 'Yesterday',
                    last7Days: 'Last 7 days'
                  }}
                  ranges={[]}
               
                />
              </Grid>
              <Grid className="btnChart" container item md={6} xs={12}>
                  <Grid container className="plus_and_min">
                    <span className="line"></span>
                    <Grid className="plus" onClick={() => this.zoomIn()} item md={6} xs={6}>+</Grid>
                    <Grid className="mins" onClick={() => this.zoomOUT()}item md={6} xs={6}>-</Grid>
                  </Grid>  
                  <Grid container className="zoomm" onClick={() => this.handleClose()}>
                    <img src={zoomOut} alt="" />
                  </Grid>  
              </Grid>
            </Grid>
            {this.state.dataChart.length ? 
            <Chart data={this.state.dataChart} >
              <ArgumentScale factory={scaleTime} />
              <ArgumentAxis showGrid={true}/>
              <ValueAxis />
              <SplineSeries valueField="city" color={"#F9D649"} argumentField="date" />
              <SplineSeries valueField="region" color={"#00E6AC"} argumentField="date" />
              <SplineSeries valueField="country" color={"#558ED5"}  argumentField="date" />
              {this.state.dataChart.length > 0 && (
                <ZoomAndPan
              viewport={this.state.viewPort}
                onViewportChange={this.viewPortChange}
              />
              )}
              <EventTracker />
              <Tooltip />
            </Chart> :
            <div style={{height:500, justifyContent :'center',alignItems: 'center', display:'flex'}}>  <span>No data. Please try another date range.</span></div>}
            <Grid className="noteChart" container>
                <Grid container item md={1}>
                  <Grid  item  className="squareCountry"></Grid>
                  <Grid className="Country" item md={8}>Country</Grid>
                </Grid>

                <Grid container item md={1}>
                  <Grid  item className="squareProvince"></Grid>
                  <Grid className="Province" item md={8}> Province</Grid>
                </Grid>

                <Grid container item md={1}>
                  <Grid item className="squareCity"></Grid>
                  <Grid className="City" item md={8}>City</Grid>
                </Grid>
            </Grid>

          </Grid>
          
        </div>
      </Modal>
      </div>
       
      // </Paper>
    );
  }
}
// export default connect()(chart);
export default chart;