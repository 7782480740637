import React , {useEffect} from 'react';
import {
  Grid,
  Divider,
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { routes } from 'routers/routes';
import {
  GET_LIST_COMMENTARY_HOME_REQUEST
} from 'redux/reducers/DataHome/ActionType';
import { 
  UPDATE_SECTOR
} from 'redux/reducers/SectorDetail/actionTypes'
import moment from 'moment';


const LatestCommentaries = React.memo(() => {
  let history = useHistory();
  const dataCommentary = useSelector(state => state.DataHome.data);
  const isLogin = useSelector(state => state.User.islogin);
  const dispatch = useDispatch();
  const redirectAfterClick = () => {
    if(isLogin){
      history.push(routes.marketForecast)
    }else{
      history.push(routes.marketComm)
    }
  }
  useEffect(() => {
      dispatch({ 
        type: GET_LIST_COMMENTARY_HOME_REQUEST
      })
  }, [dispatch]);

  const goToProduct = (item) => {
    document.cookie = `sector_id=${item.sector_id}`;
    document.cookie = `country_id=${item.country_id}`;
    document.cookie = `product_id=${item.product_id}`;
    document.cookie = `stat_id=${item.market_data.default_field}`;
    document.cookie = `tier2_id=${item.market_data.sub_category_1_id}`;
    document.cookie = `tier3_id=${item.market_data.sub_category_2_id}`;

    let arrStr = item.market_data.name.split(",");

    let arrTemp = arrStr.slice();
    arrTemp.shift();
    arrTemp.pop();

    dispatch({
      type:UPDATE_SECTOR,
  
      chosenSector    : item.sector,
      chosenProduct   : item.product,
      sectorProducts  : null,
      tier2List       : null,
      chosenTier2     : {id: item.sub_category_1_id, title: arrTemp.join(", ")},
      tier3List       : null,
      chosenTier3     : {id: item.sub_category_2_id, title: arrStr[arrStr.length - 1]},
      //*stats
  
      statList    : [],
      chosenStat  : {id: item.market_data.default_field, name: 'Loading...'},
      //*country
  
      locationList    : [],
      chosenCountry   : {id: item.country_id, name: 'Loading...'},
      provinceList    : [],
      chosenProvince  : null,
      CityList        : [],
      chosenCity      : null
    });
    history.push(routes.prodetail);
  }

  return (
    <div className="latest-commentaries">
      <Divider className="border" />
      <p className="title-latest">Latest Commentaries</p>
      {dataCommentary.map((itemCommentary, key) => {
        return (
          <React.Fragment key={itemCommentary.id}>
            { key > 0 && (
              <Divider className="border"/>
            )}
            <Grid container className="title">
              <Grid itemxs={12} className="titleBrand">
                {itemCommentary.title}
              </Grid>
            </Grid>
            <Grid container className="content-commentary" >
              {/*<Grid container item lg={12} md={6} xs={12} >*/}
                {itemCommentary.data.map(latestPrice => {
                  const date = new moment(latestPrice.created_at, 'YYYY-MM-DD').format('LL');
                  return(
                    <Grid className="swap-content" key={latestPrice.id}>
                      <Grid item lg={3} md={12} xs={12} className="name-product">
                        {`
                          ${latestPrice.product.title} ,
                          ${latestPrice.country.name}
                        `}
                      </Grid>
                      <Grid item lg={6} md={12} xs={12} className="title-commentary">
                        {latestPrice.message}
                      </Grid>
                      <Grid item lg={3} md={12} xs={12} className="date-commentary">
                        {date}
                      </Grid>
                    </Grid>
                  )
                })}
              {/*</Grid>*/}
            </Grid>
          </React.Fragment>
        )
      })}
      {/*dataCommentary.map((itemCommentary, key) => {
        return (
          <React.Fragment key={itemCommentary.id}>
            { key > 0 && (
              <Divider className="border"/>
            )}
            <Grid container className="title">
              <Grid item lg={6} md={6} xs={12} className="titleBrand">{itemCommentary.title}</Grid>
              <Grid container item lg={6} md={6} xs={12} className="titleProduct">
                <Grid item lg={8} md={8} xs={12}>Products</Grid>
                <Grid container item lg={4} md={4} xs={12} className="titleTime">
                  <Grid item xs={10}>
                    <Grid>Price</Grid>
                  </Grid>
                  <Grid item xs={2}>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <ul className="production">
                {itemCommentary.data.map(latestPrice => {
                  const date = new moment(latestPrice.created_at, 'YYYY-MM-DD').format('LL');
                  return (
                    <li key={latestPrice.id}>
                      <Grid container className="vitaminType">
                        <Grid item lg={6} md={6} xs={12} className="resultProduct">
                          <Grid className="info">
                            <span className="infoVitamin">
                              {`
                                ${latestPrice.product.title} ,
                                ${latestPrice.country.name}
                              `}
                            </span>
                            <span className="time">  {date}</span>
                          </Grid>
                          <Grid className="infoPlace">
                            {latestPrice.message}
                          </Grid>
                          <span className="infoVitaminNone">
                            {`
                              ${latestPrice.product.title} ,
                              ${latestPrice.country.name}
                            `}
                          </span>
                          <Divider className="borderNone"/>
                        </Grid>
                        {latestPrice.market_data ? (
                          <Grid container item  md={6} xs={12} className="product">
                            <Grid item lg={8} md={8} xs={8} className="infoProduct">
                              <Grid className="proVitamin" onClick={() => goToProduct(latestPrice)}>
                                {
                                  latestPrice.market_data.name
                                  .replace(latestPrice.market_data.name.split(",")[0] + ",", "")
                                  .replace("," + latestPrice.market_data.name.split(",")[latestPrice.market_data.name.split(",").length - 1], "")
                                }
                              </Grid>
                              <Grid className="export">
                                {
                                  latestPrice.market_data.name.split(",")[latestPrice.market_data.name.split(",").length - 1]
                                }
                              </Grid>
                            </Grid>
                            <Grid container item lg={4} md={4} xs={4} className="number">
                              <Grid item xs={10} className="numberBox">
                                <span className="priceAndCurrency">
                                  {latestPrice.market_data.price} {latestPrice.market_data.currency}
                                </span>
                                <Grid className="dateMarketData">
                                  {new moment(latestPrice.market_data.end_date,'YYYY-MM-DD').format("DD-MMM")}
                                </Grid>
                              </Grid>
                              <Grid item xs={2} className="numberBox">
                                {latestPrice.market_data.variation === 'increase' ? (
                                    <span className="increaseIcon"></span>
                                  ) : (
                                    <span className="decreaseIcon"></span>
                                  )}
                              </Grid>
                            </Grid>
                            <Divider className="borderNone"/>
                          </Grid>
                        ) : (
                          (
                            !latestPrice.is_paid && (
                              <Grid container item  md={6} xs={12} className="product">
                                <Grid container item lg={12} md={12} xs={12} className="number padding0">
                                  <Grid item xs>
                                    <Grid className="viewPrice"> Subscribe to view prices</Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          )
                        )}
                      </Grid>
                    </li>
                  )
                })}
              </ul>
            </Grid>
          </React.Fragment>
        )
      })*/}
      <Grid className="btn">
        <Button onClick={redirectAfterClick} className="btnViewAll">View All Commentaries</Button>
      </Grid>
    </div>
   );
})
export default LatestCommentaries;