import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routes } from './routes';
import { useSelector } from "react-redux";
const PublicRouteters = ({breadcrumb, component, isLoggedIn, ...rest }) => {
    const Component = component;  
    const redirectPath = useSelector(state => state.MarketForecast.redirectPath);
    return (
        <Route
          {...rest}
          render={props =>
            !isLoggedIn ? (
              <Component breadcrumb={breadcrumb} {...props}/>
            ) 
            : redirectPath ? (
              <Redirect to={redirectPath} />
            )
            : (
              <Redirect to={routes.marketForecast} />
            )
          }
        />
    );
};

export default PublicRouteters;