import React, {useEffect} from 'react';
import SignUpPage from '../../components/SignUp'
const SignUp  = () => {
  useEffect(() => {
    document.title = 'Sign Up SpoutINFO'
  });
    return (
      <div>
        <SignUpPage/>
      </div>
    );
  }
  export default SignUp;