import React, { useEffect } from 'react';
import { Grid,Button} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import InputPhone from '../InputPhone'
import ErrorMessage from 'components/ErrorMessage';
import {useForm} from 'react-hook-form';
import {
  Link,
  useHistory
} from "react-router-dom";
import { USER_FORGET_PASSWORD_DEFAULT_REQUEST } from 'redux/reducers/User/actionTypes';
import { useSelector, useDispatch } from "react-redux";
import Web_logo_1 from 'asset/img/Web-logo-1.png';
import Web_logo_2 from 'asset/img/Web-logo-2.png';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { routes } from 'routers/routes';

const ForgetPasswordDefault = () => {
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    clearError,
  } = useForm();
  const dispatch = useDispatch();
  const user = useSelector(state => state.User);
  let history = useHistory();
  const onChangePhone = (e) => {
    switch (e.name) {
      case 'phone_number':
        setValue(e.name, e.value.replace(/^0*/, ''));
        clearError(e.name);
        break;
      case 'country_code':
        setValue(e.name, e.value.replace('+', ''));
        clearError(e.name);
        break;
    }
  }
  useEffect(()=>{
    if(user.error_user_forget_default.isError)
    {
        Swal.fire({
          title: 'Error!',
          text: user.error_user_forget_default.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    }
    // if(user.is_forget){
    //   history.push('/forget-password/submited')
    // }
  },[user]);
  const onSubmit = async (data,e) =>{
    e.preventDefault(); 
    dispatch({
      type: USER_FORGET_PASSWORD_DEFAULT_REQUEST,
      data: data,
    })
  } 
  const redirectDasboard = () => {
    history.push('/');
  }
  const redirecteFeedlink = () => {
    window.open(
      'http://www.efeedlink.com/',
      '_blank'
    );
  }
  return (
   
        <Grid className="container_fp_default" container >
            <Grid className="form" item lg={4} md={6} xs={12}>
                <Grid className="contentLeft" item lg={8} md={8} xs={12}>
                  <Grid className="account-box-logo" item lg={12} md={12} xs={12}>
                    <div className="account-logo account-logo-efeed" onClick={redirecteFeedlink}>
                      <img src={Web_logo_1} width="116" height="34"/>
                    </div>
                    <div className="account-logo account-logo-spout" onClick={redirectDasboard}>
                      <img src={Web_logo_2} width="177" height="36"/>
                    </div>
                  </Grid>
                  <Grid className="formforget_default" item lg={12} md={12} xs={12}>
                    <p>Forgot Password?</p>
                    <p className="noted">Enter your phone number and receive instructions to reset your password</p>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Phone Number
                        </InputLabel>
                        <input
                          type="string"
                          name="phone_number"
                          hidden
                          ref={register({required:true})}
                        />
                       <input
                          type="string"
                          name="country_code"
                          hidden
                          ref={register({required:true})}
                        />
                       <InputPhone 
                         label={'Phone Number'}
                         name="phone_number"
                         nameOption="country_code"
                         placeholderNationalNumber={'phone number'}
                         onChangeFunction={onChangePhone}
                         validate={register({
                          required:true,
                          pattern: /^\d+$/,
                        })}
                        errors={Boolean(
                          (errors.country_code ) ||
                            (errors.phone_number)
                        )}
                        />
                        {errors.country_code &&
                          (errors.country_code ).type ===
                            'required' && (
                            <ErrorMessage>Enter a valid telephone no</ErrorMessage>
                          )}
                        {errors.phone_number &&
                          (errors.phone_number ).type ===
                            'required' && (
                            <ErrorMessage>Phone number is required</ErrorMessage>
                          )}
                        {errors.phone_number &&
                          (errors.phone_number ).type ===
                            'pattern' && (
                            <ErrorMessage>Enter a valid phone number</ErrorMessage>
                          )}
                    <Button className="btn" type="submit">
                      Submit
                    </Button>
                    </form>
                  </Grid>
                  <Grid className="linkSignup" item lg={12} md={12} xs={12}>
                    <Grid><span>Have an account?</span><Link to={routes.login}> Login here</Link></Grid>
                    <Grid><span>Haven't had an account yet?</span><Link to={routes.signup}> Sign up here</Link></Grid>
                  </Grid>
                  <Grid className="Copyright" item lg={12} md={12} xs={12}>
                    <p>Copyright ©2020 SpoutINFO. All rights reserved.</p>
                  </Grid>
                </Grid>
            </Grid>
            <Grid className="imageLogin" item lg={8} md={6} xs>
                <Grid className="content" item lg={12} md={12} xs={12} sm={12}>
                      <Grid className="lable_1">A MARKET DATA PLATFORM FOR</Grid>
                      <Grid className="lable_2">THE FEED & LIVESTOCK SECTORS</Grid>
                      <Grid className="lable_3">Feed Additives | Livestock | Feed Grains</Grid>
                  </Grid>
            </Grid>
        </Grid>
        
  );
}
export default ForgetPasswordDefault; 