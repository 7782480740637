import React from 'react';
import styled from 'styled-components';
import className from 'classnames';

const StyledIcon = styled.i`
  position: absolute;
  top: 50%;
  right: 2px;
  padding: 0 10px;
  transform: translate(0px, -50%);
  cursor: pointer;
  font-size: 22px;
  color: ${props => props.theme.icon};
  transition: transform 0.3s ease-out;
  &:hover {
    transform: scale(1.2) translate(0px, -45%);
  }
`;

const EyeIcon = React.memo((props) => (
  <StyledIcon
    className={className(
      'la',
      { 'la-eye': props.isEyeClose },
      { 'la-eye-slash': !props.isEyeClose },
      props.className
    )}
    onClick={() => props.clickHandler()}
  />
));

export default EyeIcon;
