import React,{useEffect, useRef,useState} from "react";
import Grid from '@material-ui/core/Grid';
import Select from '../SelectTag'
import ErrorMessage from '../ErrorMessage';
import {useForm} from 'react-hook-form';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import Analysis from '../AnalysisProductDetail';
import FormSelect from '../FormSelectProductDetail';
import ProductInfo from '../ProductionInfo';
import InfiniteScroll from 'react-infinite-scroller';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import moment, { now } from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import ModalForm from '../PopperFormSelect'
import ModalProduct from '../ModalProduct'
import Chart from '../Chart'
import { useSelector, useDispatch } from "react-redux";
import Loading from 'components/loading'
import { GET_LIST_SECTOR_DETAIL_REQUEST } from 'redux/reducers/SectorDetail/actionTypes';
import { GET_LIST_COUNTRIES_REQUEST } from 'redux/reducers/Countries/ActionType';
import { GET_LIST_MARKET_COMM_REQUEST,CHECK_DATA_HIDDEN } from 'redux/reducers/MarketComm/ActionType';
import { GET_LIST_DATA_HOME_REQUEST } from 'redux/reducers/Data/ActionType';
import SubscribePopup from 'components/SubscribePopup';
import { SET_STATUS_LOADING_MARKET_FORECAST, SET_TITLE_MARKET_FORECAST } from 'redux/reducers/MarketForecast/ActionType';
import { 
  GET_DATA_AVG_PRICE_REQUEST, 
  GET_DATA_PRICE_HISTORY_REQUEST,
  GET_DATA_COMMENT_REQUEST,
  LOADING
} from 'redux/reducers/SectorDetail/actionTypes'
import { 
  GET_DATA_CHART_REQUEST
} from 'redux/reducers/Chart/actionTypes';
import zoom from 'asset/images/zoom.jpg'
import List from '@material-ui/core/List';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { routes } from 'routers/routes';
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ScaleLoader";


 const ProductDetail = () => {
  const chartRef = useRef();
  const sector = useSelector(state => state.Product);
  const chart = useSelector(state => state.Chart);
  const ListCountries = useSelector(state => state.Countries.countries);
  const ListMarketComm = useSelector(state => state.MarketComm.marketComm);
  const checkListMarketComm = useSelector(state => state.MarketComm.ishide);
  const dispatch = useDispatch();
  let history = useHistory();
  const [timeStart,setTimeStart] = useState([new Date(), new Date()]);
  const [isPaid, setIsPaid] = React.useState(false); 
  const isLoginUser = useSelector(state => state.User.islogin);
  const useLoginStorage = useSelector(state => state.User.user_storage);
  const useLoginProfile = useSelector(state => state.User.user_login);
  useEffect(() => {
    // if(isLoginUser && useLoginStorage && useLoginStorage.subscription_plan_total == 0  || isLoginUser && useLoginProfile && useLoginProfile.subscription_plan_total == 0) {
    //   Swal.fire({
    //     title: 'You have not subscribed yet.',
    //     text: "You will be redirected to SpoutINFO subscription page",
    //     icon: 'warning',
    //     showCancelButton: false,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'OK'
    //   }).then((result) => {
    //     history.push("/subscribe");
    //   })
    // }
    // else {
      dispatch({
        type:CHECK_DATA_HIDDEN,
        payload:false,
      })
      dispatch({
        type: GET_LIST_SECTOR_DETAIL_REQUEST,
      });
      
      dispatch({
        type: GET_LIST_COUNTRIES_REQUEST,
      });
    // }
    
}, []);
const getAvgPrice = () =>{
  dispatch({
    type: GET_DATA_AVG_PRICE_REQUEST,
    pms :{
      country: sector.chosenCountry.id,
      region: (sector.chosenProvince && sector.chosenProvince.id) ? sector.chosenProvince.id : '',
      city: (sector.chosenCity && sector.chosenCity.id) ? sector.chosenCity.id : '',
      product: sector.chosenProduct.id,
      sub_category_1: (sector.chosenTier2 && sector.chosenTier2.id) ? sector.chosenTier2.id : '',
      sub_category_2: (sector.chosenTier3 && sector.chosenTier3.id) ? sector.chosenTier3.id : '',
      stats_id: sector.chosenStat.id,
      stats_type: sector.chosenStat.type
    }
  });
}
const getHistory = () =>{
  dispatch({
    type: GET_DATA_PRICE_HISTORY_REQUEST,
    pms :{
      country: sector.chosenCountry.id,
      region: (sector.chosenProvince && sector.chosenProvince.id) ? sector.chosenProvince.id : '',
      city: (sector.chosenCity && sector.chosenCity.id) ? sector.chosenCity.id : '',
      product: sector.chosenProduct.id,
      sub_category_1: (sector.chosenTier2 && sector.chosenTier2.id) ? sector.chosenTier2.id : '',
      sub_category_2: (sector.chosenTier3 && sector.chosenTier3.id) ? sector.chosenTier3.id : '',
      stats_id: sector.chosenStat.id,
      stats_type: sector.chosenStat.type,
      start_date: moment().subtract(1, 'year').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      show_all: 'no'
    }
  });
}
const getChartData = () =>{
  dispatch({
    type: GET_DATA_CHART_REQUEST,
    pms :{
      country: sector.chosenCountry.id,
      region: (sector.chosenProvince && sector.chosenProvince.id) ? sector.chosenProvince.id : '',
      city: (sector.chosenCity && sector.chosenCity.id) ? sector.chosenCity.id : '',
      product: sector.chosenProduct.id,
      sub_category_1: (sector.chosenTier2 && sector.chosenTier2.id) ? sector.chosenTier2.id : '',
      sub_category_2: (sector.chosenTier3 && sector.chosenTier3.id) ? sector.chosenTier3.id : '',
      stats_id: sector.chosenStat.id,
      stats_type: sector.chosenStat.type,
      start_date: moment().subtract(1, 'year').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD')
    }
  });
  loading();
}
const getFileReport = () =>{
  dispatch({
    type: GET_DATA_COMMENT_REQUEST,
    data :{
      country_id: sector.chosenCountry.id,
      product_id: sector.chosenProduct.id
    }
  });
}
const getAnalistProduct = () =>{
  dispatch({
    type: GET_LIST_DATA_HOME_REQUEST,
    payload: {
      sector: sector.chosenSector.id,
      country : sector.chosenCountry.id,
      position: 0,
      user_country: "all"
    }
  });
}
const getMarketCom = () =>{
      dispatch({
        type:CHECK_DATA_HIDDEN,
        payload:true,
      })
      dispatch({
        type: GET_LIST_MARKET_COMM_REQUEST,
        payload: {
          page: 1,
          country: sector.chosenCountry.id,
          filter: sector.chosenSector.id,
          user_country: 'all'
        }
      });
}
const loading = () => {
  dispatch({
    type:LOADING,
    loading : true,
  })
}

  useEffect(()=>{
    if(sector.chosenSector && sector.chosenProduct && sector.chosenTier2 && sector.chosenTier3 && sector.chosenCountry)
    {
      loading();
      getAvgPrice();
     // getHistory();
      getChartData();
      getFileReport();
      getAnalistProduct();
     // getMarketCom();
    }
   
  },[])
   const {
  register,
  errors,
  handleSubmit,
  setValue,
  clearError,
  setError,
} = useForm();
const [date,setDate] = React.useState([new Date()])
const [anchorEl, setAnchorEl] = React.useState(null);
const [selectedIndexMarket, setIndexIndexMarket] = React.useState();
function onChange(value, dateString) {

}
const getCookie = (cname) => {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
} 
const handleLoadMore = () => {
  if(!ListMarketComm.isFull && !ListMarketComm.isLoad && checkListMarketComm )
  dispatch({
    type: GET_LIST_MARKET_COMM_REQUEST,
    payload: {
      page: ListMarketComm.page + 1,
      country: ListMarketComm.country,
      filter: ListMarketComm.filter,
      user_country: ListMarketComm.user_country
    }
  })
}
const handleChoseMarket = (index, item) => {
  setIndexIndexMarket(index);
  if(item.is_paid){
    document.cookie = `sector_id=${item.sector_id}`;
    document.cookie = `country_id=${item.country_id}`;
    document.cookie = `product_id=${item.product_id}`;
    dispatch({
      type: SET_STATUS_LOADING_MARKET_FORECAST,
      showLoading : true
    });
    dispatch({
      type: SET_TITLE_MARKET_FORECAST,
      payload : item.message
    });
      history.push(`/market-forecast-detail/${item.sector_id}/${item.country_id}/${item.product_id}`); 
  }
  else{
    setIsPaid(true);
   //history.push(routes.subscribe);
  }
}
function onOk(value) {
 
}
const getDataMarketCom = (e) =>{

      dispatch({
        type: GET_LIST_MARKET_COMM_REQUEST,
        payload: {
          page: 1,
          country: e.id,
          filter: sector.chosenSector.id,
          user_country: 'all'
        }
    });
}
const getDataMarketCountyrAll = (e) =>{

  dispatch({
    type: GET_LIST_MARKET_COMM_REQUEST,
    payload: {
      page: 1,
      country: e,
      filter: sector.chosenSector.id,
      user_country: 'all'
    }
});
}
const getInfoDate = (type ,date) => {
  switch (type) {
    case 'year':
      const numberYear = moment(date, 'YYYY-MM-DD').format('YYYY');
      return numberYear;
    case 'month':
      const numberMonth = moment(date, 'YYYY-MM-DD').format('MMM');
      return numberMonth.toUpperCase();
    case 'day':
      const numberDay = moment(date, 'YYYY-MM-DD').format('DD');
      return numberDay;
    default:
      break;
  }
}
const handleClick = event => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};
const changeDate = (e) =>{
  const startDate = moment(e[0]).format('YYYY-MM-DD');
  const endDate = moment(e[1]).format('YYYY-MM-DD');
  setTimeStart(e);
  dispatch({
    type: GET_DATA_CHART_REQUEST,
    pms :{
      country: sector.chosenCountry.id,
      region: (sector.chosenProvince && sector.chosenProvince.id) ? sector.chosenProvince.id : '',
      city: (sector.chosenCity && sector.chosenCity.id) ? sector.chosenCity.id : '',
      product: sector.chosenProduct.id,
      sub_category_1: (sector.chosenTier2 && sector.chosenTier2.id) ? sector.chosenTier2.id : '',
      sub_category_2: (sector.chosenTier3 && sector.chosenTier3.id) ? sector.chosenTier3.id : '',
      stats_id: sector.chosenStat.id,
      stats_type: sector.chosenStat.type,
      start_date: startDate,
      end_date: endDate
    }
  });
  
}
const updateIsPaid = (status) => {
  setIsPaid(status)
}
const open = Boolean(anchorEl);
const id = open ? 'simple-country' : undefined;
  return (
    <div className="prodcut-detail">
     <Grid container spacing={1} >
     {/**/}
       {sector.isLoading ? <Loading/>: ''}
       {/* <Loading/> */}
        <Grid className="formSelect rootProduct" item xs={2}>
        <div className="select">
          <FormSelect/>
        </div>
        <div className="modelSelect">
          <ModalForm />
        </div>
        
        </Grid>
        <Grid item xs={7} className="content rootProduct">
          <Grid className="infoVitamin" container item xs={12}>
            <Grid container item xs={12} className="modalAndVitamin">
              <Grid item md={6} xs={12} className="title"><p className="vitamin">{sector.chosenTier2 ? sector.chosenTier2.title : '-'} ,{sector.chosenTier3 ? sector.chosenTier3.title : '-'}</p></Grid>
              <Grid item md={6} xs={12} className="modalProduct"><ModalProduct name={'Product'}/><ModalForm name={'Filter'}/></Grid>
            </Grid>
            <Grid className="currency"> <span className="gmb"> </span><span className="average"> {sector.avgPrice && sector.avgPrice.start_date ? moment(sector.avgPrice.start_date).format('DD-MMM-YYYY'): '-'} - {sector.avgPrice && sector.avgPrice.end_date ? moment(sector.avgPrice.end_date).format('DD-MMM-YYYY'): ''} Average</span></Grid>
          </Grid>
          <Grid className="number" container item xs={12}>
            <Grid className="numberLeft" item  md={5} xs={12}> {sector.avgPrice ? sector.avgPrice.currency : '-'} {sector.avgPrice ? sector.avgPrice.price : '-'}  <label className={`fluctuations ${sector.avgPrice && sector.avgPrice.price_variation ? sector.avgPrice.price_variation : ''}`}></label></Grid>
            <Grid className="numberRight" item md={7} xs={12}>
              <Grid >
                <span className="previous">Previous</span>
                <span>{sector.avgPrice && sector.avgPrice.previous_price ? sector.avgPrice.previous_price : ''}</span><label className={`fluctuations ${sector.avgPrice && sector.avgPrice.previous_price_variation ? sector.avgPrice.previous_price_variation : ''}`}></label>
              </Grid>
              <Grid>
                <span className="fluc">Fluctuation</span>
                <span>{sector.avgPrice && sector.avgPrice.fluctuation ? sector.avgPrice.fluctuation : ''}</span><label className={`fluctuations ${sector.avgPrice && sector.avgPrice.fluctuation_variation ? sector.avgPrice.fluctuation_variation : ''}`}></label>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="exportPrice">
              <Grid className="time">
               {sector.commentReport  ? (sector.commentReport[0] ? moment( sector.commentReport[0].created_at).format('DD-MMM') : '-') : '-'}
              </Grid> 
              <Grid className="country">
              {sector.commentReport ?(sector.commentReport[0] ? sector.commentReport[0].title : '-') : '-'}
              {/* China Calcium Pantothenate: Market under pressure (week ended Jan 24, 2020) */}
              </Grid>
              <Grid className="export"> 
              {sector.chosenCountry && sector.chosenCountry.name ? sector.chosenCountry.name : ''}, {sector.chosenTier2 && sector.chosenTier2.title ? sector.chosenTier2.title : ''}
              {/* 2019 Vitamin A, China, Expore Price */}
              </Grid>
          </Grid>
          <Grid className="datetime" container >
            <Grid item md={6} xs={12}>
                <DateRangePicker
                disabled={sector.chosenProduct && sector.chosenProduct.id ? false : true}
                className="picker"
                 value={timeStart}
                onChange= { changeDate}
                format="DD-MMM-YYYY"
                locale={{
                  sunday: 'Su',
                  monday: 'Mo',
                  tuesday: 'Tu',
                  wednesday: 'We',
                  thursday: 'Th',
                  friday: 'Fr',
                  saturday: 'Sa',
                  ok: 'OK',
                  today: 'Today',
                  yesterday: 'Yesterday',
                  last7Days: 'Last 7 days'
                }}
                ranges={[]}
                disabledDate={(date) => {
                  if (date > moment().format('DD-MMM-YYYY')) {
                    return true;
                  }
                }}
              />
            </Grid>
            <Grid className="btnChart" container item md={6} xs={12}>
                <Grid container className="plus_and_min">
                  <span className="line"></span>
                  <Grid className="plus" onClick={() => chartRef.current.zoomIn()} item md={6} xs={6}>+</Grid>
                  <Grid className="mins" onClick={() => chartRef.current.zoomOUT()}item md={6} xs={6}>-</Grid>
                </Grid>  
                <Grid container className="zoomm" onClick={() =>chartRef.current && chartRef.current.handleOpen()}>
                  <img src={zoom} alt="" />
                </Grid>  
            </Grid>
          </Grid>
          <Grid className="chart" container item xs={12}>
          {/* <button onClick={() => chartRef.current.zoomIn()}>Click</button> */}

            <Chart dispatch={dispatch} sectorData={sector} changeDate={changeDate} timeStart={timeStart} ref={chartRef} chosenProvince={sector.chosenProvince} chosenCity={sector.chosenCity} chartData={chart.data}/>
            {/* <Grid className="noteChart">
              
            </Grid> */}
          </Grid>
        </Grid>
        <Grid className="analysis rootProduct" container item xs={3}>
         <Analysis/>
       
        </Grid>
      </Grid>

      <Grid className="marketCom" container spacing={1}>
        <Grid className="marketCom_child_1" item xs={2}></Grid>
        <Grid className="marketInfo" item xs={7} >
         <Grid className="marketTitle" container item xs={12}>
            <Grid item xs={6} className="title">Market Commentaries</Grid>
            <Grid item xs={6} className="country">
                  <Button aria-describedby={id} variant="contained" className="selectCountry" onClick={handleClick}>
                    <div className="icons-flag">
                      {/* <img className="icon-item" src={WordIcon} /> */}
                    </div>
                    All countries
                  </Button>
                  <Popover
                  className="popover"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <List component="nav" >
                    <ListItem button>
                              <ListItemText onClick={ () => getDataMarketCountyrAll('all')}>
                                All
                              </ListItemText>
                          </ListItem>
                    {ListCountries.map((item,key)=>{
                        return(
                          <ListItem key={key} button onClick={ () => getDataMarketCom(item)} >
                              <ListItemText >
                                {item.name}
                              </ListItemText>
                          </ListItem>
                        )
                      })}
                     
                    </List>
                  </Popover>
            </Grid>
         </Grid>
         <Grid item xs={12}>
              {/* <InfiniteScroll
                pageStart={0}
                loadMore={handleLoadMore}
                hasMore={true || false}
                loader={ ListMarketComm.isLoad && checkListMarketComm ? <div className="loader mobile-center-loader" key={0}><CircularProgress className="load-more" /></div> : null}
              > */}
                {checkListMarketComm && ListMarketComm.data ? ListMarketComm.data.map((item,key) => {
                  return (
                    <ListItem
                      key={key}
                      button
                      selected={selectedIndexMarket === key}
                      onClick={() => handleChoseMarket(key, item)}
                      className="mb-pd-20"
                    >
                      <div>
                        <ListItemText className="date-comm">
                          {getInfoDate('day', item.updated_at)}
                          <br/>
                          {getInfoDate('month', item.updated_at)}
                        </ListItemText>
                      </div>
                      <div>
                        <Link className="link-market" to='#'>{item.message}</Link>
                        <ListItemText className="text-sub-market">
                          {getInfoDate('year', item.updated_at)} {item.product.title}, {item.country.name}
                        </ListItemText>
                      </div>
                    </ListItem>
                  )
                }):''}
                 <Grid onClick={getMarketCom} className={`showMarketComm ${checkListMarketComm ? 'enable' : ''}`}> Show market commentaries </Grid>
                 <div className="load_ding_commentaries">
                  <ClipLoader
                      size={15}
                      color={"#558ED5"}
                      loading={ListMarketComm.isLoad}
                    />
                 </div>
                 <Grid onClick={handleLoadMore} className={`showMarketComm ${checkListMarketComm && !ListMarketComm.isFull ? '' : 'enable'}`}> Load more </Grid>
              {/* </InfiniteScroll> */}
          </Grid>  
              
        </Grid>
        <Grid className="marketCom_child_3" item xs={3}></Grid>
        
      </Grid>
      <Grid  className="table" container spacing={1}>
        <Grid className="table_grid_1" item xs={2}></Grid>
        <Grid className="table_grid_2" item xs={7} >
          <span>*All figures are in RMB</span>
          <p className="history">Historical Data</p>
          <ProductInfo/>
        </Grid>
        <Grid className="table_grid_3" item xs={3}></Grid>
      </Grid>
      {isPaid ?  <SubscribePopup changeHandler={updateIsPaid}/> : null}
    </div>
  );
}
export default ProductDetail;
