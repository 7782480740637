import React, {useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { Link } from 'react-router-dom';
import Select from 'components/SelectTag';
import { useSelector, useDispatch } from "react-redux";
import { GET_LIST_COUNTRIES_REQUEST } from 'redux/reducers/Countries/ActionType';
import { GET_LIST_CATEGORY_REQUEST } from 'redux/reducers/Category/ActionType';
import { GET_LIST_MARKET_COMM_REQUEST } from 'redux/reducers/MarketComm/ActionType';
import moment from 'moment';
import { routes } from 'routers/routes';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom";

const MarketCommentary = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const ListCountries = useSelector(state => state.Countries.countries);
  const ListCategory = useSelector(state => state.Category.category);
  const ListMarketComm = useSelector(state => state.MarketComm.marketComm);
  useEffect(() => {
      dispatch({
        type: GET_LIST_COUNTRIES_REQUEST,
      });
      dispatch({
        type: GET_LIST_CATEGORY_REQUEST,
      });
      dispatch({
        type: GET_LIST_MARKET_COMM_REQUEST,
        payload: {
          page: ListMarketComm.page + 1,
          country: ListMarketComm.country,
          filter: ListMarketComm.filter,
          user_country: ListMarketComm.user_country
        }
      });
  }, []);

  const [selectedIndexCountries, setIndexCountries] = React.useState(0);
  const [selectedIndexCategory, setIndexCategory] = React.useState(0);
  const [selectedIndexMarket, setIndexIndexMarket] = React.useState();
  const handleChoseCategory = (event, index, item) => {
    dispatch({
      type: GET_LIST_MARKET_COMM_REQUEST,
      payload: {
        page: 1,
        country: ListMarketComm.country,
        filter: item.id,
        user_country: ListMarketComm.user_country
      }
    });
    setIndexCategory(index);
  };
  const handleChoseCategoryMobile = (item) => {
    dispatch({
      type: GET_LIST_MARKET_COMM_REQUEST,
      payload: {
        page: 1,
        country: ListMarketComm.country,
        filter: item.id,
        user_country: ListMarketComm.user_country
      }
    });
  };
  const handleChoseCountries = (event, index, item) => {
    dispatch({
      type: GET_LIST_MARKET_COMM_REQUEST,
      payload: {
        page: 1,
        country: item.id,
        filter: ListMarketComm.filter,
        user_country: ListMarketComm.user_country
      }
    });
    setIndexCountries(index);
  };
  const handleChoseCountriesMobile = (item) => {
    dispatch({
      type: GET_LIST_MARKET_COMM_REQUEST,
      payload: {
        page: 1,
        country: item.id,
        filter: ListMarketComm.filter,
        user_country: ListMarketComm.user_country
      }
    });
  };
  const handleChoseMarket = (event, index) => {
    history.push(routes.login);
    setIndexIndexMarket(index);
  };
  const getInfoDate = (type ,date) => {
    switch (type) {
      case 'year':
        const numberYear = moment(date, 'YYYY-MM-DD').format('YYYY');
        return numberYear;
      case 'month':
        const numberMonth = moment(date, 'YYYY-MM-DD').format('MMM');
        return numberMonth.toUpperCase();
      case 'day':
        const numberDay = moment(date, 'YYYY-MM-DD').format('DD');
        return numberDay;
      default:
        break;
    }
  }
  const handleLoadMore = () => {
    if(!ListMarketComm.isFull && !ListMarketComm.isLoad )
    dispatch({
      type: GET_LIST_MARKET_COMM_REQUEST,
      payload: {
        page: ListMarketComm.page + 1,
        country: ListMarketComm.country,
        filter: ListMarketComm.filter,
        user_country: ListMarketComm.user_country
      }
    })
  }
  return (
    <div className="market-comm">
      <Grid container spacing={3}>
        <Grid item xs={3} className="hide-filter-mobile">
          <List subheader={<ListSubheader className="subHeaderMarketComm">Category</ListSubheader>} component="nav">
          {ListCategory.map((item,key)=>{
              return(
                <ListItem
                  key={key}
                  button
                  selected={selectedIndexCategory === key}
                  onClick={event => handleChoseCategory(event, key, item)}
                  >
                    <ListItemText>
                      {item.title}
                    </ListItemText>
                </ListItem>
              );
            })}
          </List>
          <List subheader={<ListSubheader className="subHeaderMarketComm">Countries</ListSubheader>} component="nav">
            {ListCountries.map((item, key) => {
                return (
                  <ListItem
                    key={key}
                    button
                    selected={selectedIndexCountries === key}
                    onClick={event => handleChoseCountries(event, key, item)}
                  >
                    <ListItemIcon> {item.icon_flag ?  <img className="img-20-15" src={item.icon_flag}/> : null}</ListItemIcon> 
                    <ListItemText>{item.name}</ListItemText>
                  </ListItem>
                )
              }
            )}
          </List>
        </Grid>
        <Grid item xs={9} className="grid-comm-mobile">
          <List subheader={<ListSubheader className="marketCommTitle">Market Commentary</ListSubheader>} component="nav">
            <ListItem className="select-filter-mobile">
              <div className="select-category">
                <Select
                  required
                  name={'roomNoFormat'}
                  label={'Category'}
                  dataOptions={ListCategory}
                  optionLabel={'title'}
                  onChangeFunction={handleChoseCategoryMobile}
                />
              </div>
              <div className="select-contries">
                <Select
                  required
                  name={'roomNoFormat'}
                  label={'Country'}
                  dataOptions={ListCountries}
                  onChangeFunction={handleChoseCountriesMobile}
                />
              </div>
            </ListItem>
            {!ListMarketComm.data.length && !ListMarketComm.isLoad ?
              (<ListItem className="text-no-data-2">
                <Grid className="no-data">
                  <ListItemText>No commentary for this category</ListItemText>
                </Grid>
              </ListItem>)
              : null
            }
            <InfiniteScroll
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={true || false}
              loader={ ListMarketComm.isLoad ? <div className="loader mobile-center-loader" key={0}><CircularProgress className="load-more" /></div> : null}
            >
              {ListMarketComm.data.map((item,key) => {
                return (
                  <ListItem
                    key={key}
                    button
                    selected={selectedIndexMarket === key}
                    onClick={event => handleChoseMarket(event, key)}
                    className="mb-pd-20"
                  >
                    <div>
                      <ListItemText className="date-comm">
                        {getInfoDate('day', item.updated_at)}
                        <br/>
                        {getInfoDate('month', item.updated_at)}
                      </ListItemText>
                    </div>
                    <div>
                      <Link className="link-market" to='#'>{item.message}</Link>
                      <ListItemText className="text-sub-market">
                        {getInfoDate('year', item.updated_at)} {item.product.title}, {item.country.name}
                      </ListItemText>
                    </div>
                  </ListItem>
                )
              })}
            </InfiniteScroll>
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
export default MarketCommentary;
