import axios from 'services/configApi';

export const sign_up = async (data) => {
  try {
    var formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    }); 
    const res = await axios.post('api/en/data-search/auth/sign-up',formData);

    return res.data;
  
  } catch (error) {
  
    return Promise.reject(error);
  
  }
};
