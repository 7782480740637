import React from 'react';
import styled from 'styled-components';
import { Error } from 'DesignSystem/Icons';
import { useTheme } from '@material-ui/core/styles';

const StyledIcon = styled.i`
  position: absolute;
  right: 2px;
  transform: translate(0px, -50%);
  top: 50%;
  padding: 0 10px;
  font-size: 22px;
  color: #db4437;
`;


const WarningIcon = React.memo((props) => {
  const theme = useTheme();
  return (
    <StyledIcon className={props.className}>
      <Error fill="#d1375b" width="26" height="20" />
    </StyledIcon>
  );
});

export default WarningIcon;
