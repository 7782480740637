import React , {useEffect} from 'react';
import ForgetPassword from '../../components/ForgetPassword'
const FGDefault  = () => {
  useEffect(() => {
    document.title = 'Forget Password SpoutINFO'
  });
    return (
      <div>
        <ForgetPassword/>
      </div>
    );
  }
  export default FGDefault;