export const GET_LIST_MARKET_FORECAST_REDUCER = 'GET_LIST_MARKET_FORECAST_REDUCER';
export const GET_LIST_MARKET_FORECAST_REQUEST = 'GET_LIST_MARKET_FORECAST_REQUEST';

export const GET_LIST_MARKET_FORECAST_FAIL_REDUCER = 'GET_LIST_MARKET_FORECAST_FAIL_REDUCER';
export const GET_LIST_MARKET_FORECAST_FAIL_REQUEST = 'GET_LIST_MARKET_FORECAST_FAIL_REQUEST';

export const SET_PRODUCT_AND_COUNTRY_REDUCER = 'SET_PRODUCT_AND_COUNTRY';
export const SET_PRODUCT_AND_COUNTRY_REQUEST = 'SET_PRODUCT_AND_COUNTRY_REQUEST';

export const SET_STATUS_LOADING_MARKET_FORECAST = 'SET_STATUS_LOADING_MARKET_FORECAST';

export const SET_REDIRECT_MARKET_FORECAST = 'SET_REDIRECT_MARKET_FORECAST'

export const SET_TITLE_MARKET_FORECAST = 'SET_TITLE_MARKET_FORECAST'