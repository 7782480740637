import React,{useState, useEffect} from 'react';
import { Grid,Button} from '@material-ui/core';
import BootstrapButton from './Button/index'
import Input from '../Input'
import InputPhone from './InputPhone'
import ErrorMessage from 'components/ErrorMessage';
import Web_logo_1 from 'asset/img/Web-logo-1.png';
import Web_logo_2 from 'asset/img/Web-logo-2.png';
import {useForm} from 'react-hook-form';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { USER_SIGN_UP_REQUEST,USER_UPDATE_STATUS_RESET } from 'redux/reducers/User/actionTypes';
import { routes } from 'routers/routes';
const ForgetPasswordSubmited = () => {

  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.User);
  
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    getValues,
    clearError,
    setError,
  } = useForm();
 
  useEffect(() => {
    if( typeof user.error_sign_up != "undefined"  )
    {
      if(user.error_sign_up.isError)
      {
        Swal.fire({
          title: 'Error!',
          text: user.error_sign_up.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        }).then(
          function (isConfirm) {
            if (isConfirm) {
               dispatch({
                  type: USER_UPDATE_STATUS_RESET,
                })
            }
          },
          
        );

      }
        
    }
    if(user.islogin)
    {
      Swal.fire({
        title: 'SUCCESSFUL!',
        text: "Your account has been created",
        icon: 'success',
        confirmButtonText: "Confirm",
        }
      ).then(
        function (isConfirm) {
          if (isConfirm) {
            history.push("/");
          }
        },
        
      );
    }
  },[user.error_sign_up]);

  const onChangePhone = e => {
    switch (e.name) {
      case 'phone_number':
        setValue(e.name, e.value.replace(/^0*/, ''));
        clearError(e.name);
        break;
      case 'country_code':
        setValue(e.name, e.value.replace('+', ''));
        clearError(e.name);
        break;
    }
  };
  const onSubmit = async (data,e) =>{
    e.preventDefault(); 
    dispatch({
      type:USER_SIGN_UP_REQUEST,
      data:data,
    })
  } 
  const redirectDasboard = () => {
    history.push('/');
  }
  const redirecteFeedlink = () => {
    window.open(
      'http://www.efeedlink.com/',
      '_blank'
    );
  }

  return (
  
        <Grid className="container" container >
            <Grid className="form" item lg={4} md={6} xs={12}>
                <Grid className="contentLeft" item lg={8} md={8} xs={12}>
                  <Grid className="account-box-logo"  item lg={12} md={12} xs={12}>
                    <div className="account-logo account-logo-efeed" onClick={redirecteFeedlink}>
                      <img src={Web_logo_1} width="116" height="34"/>
                    </div>
                    <div className="account-logo account-logo-spout" onClick={redirectDasboard}>
                      <img src={Web_logo_2} width="177" height="36"/>
                    </div>
                  </Grid>
                  <Grid className="formSignUp" item lg={12} md={12} xs={12}>
                    <p>Sign up for a trial account</p>
                    <form  onSubmit={handleSubmit(onSubmit)} >
                    <Input
                      label={'Full Name*'}
                      name={'fullname'}
                      type={'string'}
                      fullWidth
                      required
                      placeholder={'e.g. John Smith'}
                      error={errors.fullname }
                      validate={register({
                        required: true,
                      })}
                    />
                      {errors.fullname &&
                      (errors.fullname ).type === 'required' && (
                        <ErrorMessage>Please enter your full name.</ErrorMessage>
                      )} 

                        <Input
                      label={'Email*'}
                      name={'email'}
                      type={'email'}
                      fullWidth
                      required
                      placeholder={'e.g. john@smith.com'}
                      error={errors.email }
                      validate={register({
                        required: true,
                      })}
                    />
                      {errors.email &&
                      (errors.email ).type === 'required' && (
                        <ErrorMessage>Please enter your email.</ErrorMessage>
                      )}
                      <input
                          type="string"
                          name="phone_number"
                          hidden
                          ref={register({required:true})}
                        />
                       <input
                          type="string"
                          name="country_code"
                          hidden
                          ref={register({required:true})}
                        />
                       <InputPhone 
                         label={'Phone Number*'}
                         name="phone_number"
                         nameOption="country_code"
                         onChangeFunction={onChangePhone}
                         validate={register({
                          required:true,
                          pattern: /^\d+$/,
                        })}
                        errors={Boolean(
                          (errors.country_code ) ||
                            (errors.phone_number)
                        )}
                        />
                        {errors.country_code &&
                          (errors.country_code ).type ===
                            'required' && (
                            <ErrorMessage>Enter a valid telephone no</ErrorMessage>
                          )}
                        {errors.phone_number &&
                          (errors.phone_number ).type ===
                            'required' && (
                            <ErrorMessage>Phone number is required</ErrorMessage>
                          )}
                        {errors.phone_number &&
                          (errors.phone_number ).type ===
                            'pattern' && (
                            <ErrorMessage>Enter a valid phone number</ErrorMessage>
                          )}
                        <Input
                      label={'Company Name'}
                      name={'company'}
                      type={'string'}
                      fullWidth
                      required
                      placeholder={'Optional'}
                    />
                        <Input
                      label={'Password*'}
                      name={'password'}
                      type={'password'}
                      fullWidth
                      required
                      classInputContainer={"sign_up-password"}
                      placeholder={'Password'}
                      error={errors.password }
                      validate={register({
                        minLength: 6,
                        required: true,
                        pattern:'(?=.*\d)(?=.*[a-z]).{6,}'
                      })}
                      changeHandler={() => {
                        if (getValues().password === getValues().password_confirmation) {
                          clearError('confirmPassword');
                        } else if (getValues().password_confirmation !== '') {
                          setError('confirmPassword', 'validate');
                        }
                      }}
                      
                    />
                    <small className="sign_up--note">Requirements: 6 to 20 characters + 1 alphabet + 1 number</small>
                   
                      {errors.password &&
                      (errors.password ).type === 'required' && (
                        <ErrorMessage>Please enter your password.</ErrorMessage>
                      )}
                      {errors.password &&
                        (errors.password ).type === 'minLength' && (
                          <ErrorMessage>
                              Password must be at least 6 characters with a number, a lowercase letter.
                          </ErrorMessage>
                        )}
                      {errors.password &&
                        (errors.password ).type === 'pattern' && (
                          <ErrorMessage>
                              Password must be at least 6 characters with a number, an uppercase letter and a lowercase letter.
                          </ErrorMessage>
                        )}
                    <Input
                      label={'Confirm Password*'}
                      name={'password_confirmation'}
                      type={'password'}
                      fullWidth
                      required
                      placeholder={'Optional'}
                      error={errors.password_confirmation }
                      validate={register({
                        required: true,
                        validate: (value) => getValues().password === value,
                      })}
                    />
                      {errors.password_confirmation &&
                      (errors.password_confirmation ).type === 'required' && (
                        <ErrorMessage>Please enter your confirm password.</ErrorMessage>
                      )}
                      {errors.password_confirmation &&
                      (errors.password_confirmation).type ===
                        'validate' && (
                        <ErrorMessage>Password does not match.</ErrorMessage>
                      )}
                      <Button className="btn" type="submit" >
                        Sign up
                      </Button>
                      </form>
                      <Grid className="linkSignup">
                    <Grid><span>Already have an account?</span><Link to={routes.login}> Login here</Link></Grid>
                  </Grid>
                  </Grid>
                 
                  <Grid className="Copyright">
                    <p>Copyright ©2020 SpoutINFO. All rights reserved.</p>
                  </Grid>
                </Grid>
            </Grid>
            <Grid className="imageLogin" item lg={8} md={6} xs>
              <Grid className="content" item lg={12} md={12} xs={12} sm={12}>
                    <Grid className="lable_1">A MARKET DATA PLATFORM FOR</Grid>
                    <Grid className="lable_2">THE FEED & LIVESTOCK SECTORS</Grid>
                    <Grid className="lable_3">Feed Additives | Livestock | Feed Grains</Grid>
                </Grid>
            </Grid>
        </Grid>
     
  );
}
export default ForgetPasswordSubmited; 