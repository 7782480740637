export const GET_LIST_USER_REDUCER = 'GET_LIST_USER_REDUCER';
export const GET_LIST_USER_REQUEST = 'GET_LIST_USER_REQUEST';

export const USER_LOGIN_REDUCER = 'USER_LOGIN_REDUCER';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';

export const USER_SIGN_UP_REDUCER = 'USER_SIGN_UP_REDUCER';
export const USER_SIGN_UP_FAIL = 'USER_SIGN_UP_FAIL';
export const USER_SIGN_UP_REQUEST = 'USER_SIGN_UP_REQUEST';

export const USER_FORGET_PASSWORD_DEFAULT = 'USER_FORGET_PASSWORD_DEFAULT';
export const USER_FORGET_PASSWORD_DEFAULT_FAIL = 'USER_FORGET_PASSWORD_DEFAULT_FAIL';
export const USER_FORGET_PASSWORD_DEFAULT_REQUEST = 'USER_FORGET_PASSWORD_DEFAULT_REQUEST';

export const USER_FORGET_PASSWORD_SUBMITED = 'USER_FORGET_PASSWORD_SUBMITED';
export const USER_FORGET_PASSWORD_SUBMITED_FAIL = 'USER_FORGET_PASSWORD_SUBMITED_FAIL';
export const USER_FORGET_PASSWORD_SUBMITED_REQUEST = 'USER_FORGET_PASSWORD_SUBMITED_REQUEST';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_REDUCER = 'GET_PROFILE_REDUCER';

export const USER_LOGOUT_REQUEST = 'GET_PROFILE_REDUCER';
export const USER_LOGOUT_REDUCER = 'USER_LOGOUT_REDUCER';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_REDUCER = 'USER_UPDATE_PROFILE_REDUCER';
export const USER_UPDATE_PROFILE_FAIL= 'USER_UPDATE_PROFILE_FAIL';
export const USER_ISUPDATE_PROFILE = 'USER_ISUPDATE_PROFILE';

export const USER_UPDATE_PROFILE_SENDOTP = 'USER_UPDATE_PROFILE_SENDOTP';

export const USER_UPDATE_STATUS_RESET = 'USER_UPDATE_STATUS_RESET';

export const USER_RESET_PASSWORD_REDUCER = 'USER_RESET_PASSWORD_REDUCER';
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL';
export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';

export const USER_RESEND_RESET_PASSWORD_REQUEST = 'USER_RESEND_RESET_PASSWORD_REQUEST';
export const USER_RESEND_RESET_PASSWORD_REDUCER = 'USER_RESEND_RESET_PASSWORD_REDUCER';
export const USER_RESEND_RESET_PASSWORD_FAIL = 'USER_RESEND_RESET_PASSWORD_FAIL';

export const TOKEN_EXPIRED_REQUEST  = 'TOKEN_EXPIRED_REQUEST';
export const TOKEN_EXPIRED_REDUCER  = 'TOKEN_EXPIRED_REDUCER';

export const USER_LOCAL_STORAGE_REDUCER = 'USER_LOCAL_STORAGE_REDUCER';


