import styled from 'styled-components';

const Label = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.71;
  color: #000000;
`;

export default Label;
