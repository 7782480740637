import React, { useEffect } from 'react';
import { useSelector} from "react-redux";
import { Grid } from '@material-ui/core';
import ArrowLeft from 'asset/images/chevron_left_24px.png'
import ArrowRight from 'asset/images/chevron_right_24px.png'
import InputSearch from './InputSearch';
const HeaderBackgound = () => {
  const isLoginUser = useSelector(state => state.User.islogin);
  const useLoginStorage = useSelector(state => state.User.user_storage);
  const useLoginProfile = useSelector(state => state.User.user_login);
  const [isSearch, setIsSearch] = React.useState(false);

  useEffect(() => {
    if(isLoginUser && useLoginStorage && useLoginStorage.subscription_plan_total > 0  || isLoginUser && useLoginProfile && useLoginProfile.subscription_plan_total > 0) {
      setIsSearch(true);
    }
    else {
      setIsSearch(false);
    }
  }, [isLoginUser, useLoginStorage, useLoginProfile])

  return (
    <div>
      <Grid className="infoApp" container >
        <Grid item xs={10}>Download the SpoutINFO Mobile App for fast and easy access to market data in the feed & livestock sectors.</Grid>
        <Grid  item xs={1}><img className="arrowLeft" src={ArrowLeft} alt=""/><img className="arrowRight" src={ArrowRight} alt=""/></Grid>
      </Grid>
      <Grid className="bgPage" container>
        <Grid className={isSearch ? "bg-header bg-header-login" : "bg-header" } container item lg={12} md={12} xs={12} sm={12}>
              <Grid className="content" item lg={12} md={12} xs={12} sm={12}>
                  <Grid className="lable_1">A MARKET DATA PLATFORM FOR</Grid>
                  <Grid className="lable_2">THE FEED & LIVESTOCK SECTORS</Grid>
                  <Grid className="lable_3">Feed Additives | Livestock | Feed Grains</Grid>
              </Grid>
              {isSearch ? <InputSearch/> : null}
          </Grid>
      </Grid>
    </div>
  );
}
export default HeaderBackgound;