import axios from 'services/configApi';
const locale = 'en';

export const getListCategoryAPI = async () => {
  try {
    //delete axios.defaults.headers.common["USER-TOKEN"];
      const result = await fetch(`${process.env.REACT_APP_DOMAIN_API}/api/${locale}/data-search/sector`, {
        crossDomain:true,
        method: 'GET',
        headers: {'Content-Type':'application/json'},
      });
      const dataResponse = await result.json();
      if(dataResponse.error){
        return Promise.reject();
      }
      return dataResponse
  } catch (error) {
    return Promise.reject(error);
  }
};
