import React,{useEffect} from 'react';
import { Grid ,Button} from '@material-ui/core';
import Input from '../Input'
import ErrorMessage from 'components/ErrorMessage';
import {useForm} from 'react-hook-form';
import { useSelector, useDispatch } from "react-redux";
import { USER_RESET_PASSWORD_REQUEST } from 'redux/reducers/User/actionTypes';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Web_logo_1 from 'asset/img/Web-logo-1.png';
import Web_logo_2 from 'asset/img/Web-logo-2.png';
import { routes } from 'routers/routes';
import {
  useHistory
} from "react-router-dom";

const Login = () => {
  const history = useHistory();
  const user = useSelector(state => state.User);
  const dispatch = useDispatch();
  const {
    register,
    errors,
    handleSubmit,
    getValues,
  } = useForm();
 useEffect(() =>{
    if(user.error_user_reset_password.isError)
    {
        Swal.fire({
          title: 'Error!',
          text: user.error_user_reset_password.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    }
    if(user.resetPassword)
    {
      Swal.fire({
        title: 'SUCCESSFUL!',
        text: user.user_reset_password.data,
        icon: 'success',
        confirmButtonText: "Confirm",
        }
      ).then(
        function (isConfirm) {
          if (isConfirm) {
            history.push(routes.login);
          }
        },
        
      );
    }
  },[user])


  const onSubmit = async (data,e) =>{
      let info = {
        country_code: user.info_number.country_code,
        phone_number: user.info_number.phone_number,
        code   : user.user_forger_submit.code,
        password: data.new_password,
        password_confirmation: data.password_confirmation
      }
      e.preventDefault();
      dispatch({
        type: USER_RESET_PASSWORD_REQUEST,
        data:info,
      });
     
    } 
   
  const redirectDasboard = () => {
    history.push('/');
  }
  const sendMail = () => {
    const link = document.createElement('a');
    link.href = `mailto:inquiry@spoutinfo.com?subject=SpoutINFO - Individual Subscription`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const redirecteFeedlink = () => {
    window.open(
      'http://www.efeedlink.com/',
      '_blank'
    );
  }
  return (
        <Grid className="container_login" container >
            <Grid className="form" item lg={4} md={6} xs={12}>
                <Grid className="contentLeft" item lg={8} md={8} xs={12}>
                  <Grid className="account-box-logo" item lg={12} md={12} xs={12}>
                    <div className="account-logo account-logo-efeed" onClick={redirecteFeedlink}>
                      <img src={Web_logo_1} width="116" height="34"/>
                    </div>
                    <div className="account-logo account-logo-spout" onClick={redirectDasboard}>
                      <img src={Web_logo_2} width="177" height="36"/>
                    </div>
                  </Grid>
                  <Grid className="formLogin" item lg={12} md={12} xs={12}>
                    <p>Reset Password</p>

                    <form onSubmit={handleSubmit(onSubmit)} >
                    <Input
                      label={'New Password'}
                      name={'new_password'}
                      type={'password'}
                      fullWidth
                      required
                      placeholder={'password'}
                      error={errors.email }
                      validate={register({
                        required: true,
                      })}
                    />
                      {errors.email &&
                      (errors.email ).type === 'required' && (
                        <ErrorMessage>Please enter your email.</ErrorMessage>
                      )} 
                      <Input
                      label={'Confirm Password'}
                      name={'password_confirmation'}
                      type={'password'}
                      fullWidth
                      required
                      placeholder={'Password Confirmation'}
                      error={errors.password_confirmation }
                      validate={register({
                        required: true,
                        validate: (value) => getValues().new_password === value,
                      })}
                    />
                      {errors.password_confirmation &&
                      (errors.password_confirmation ).type === 'required' && (
                        <ErrorMessage>Please enter your confirm password.</ErrorMessage>
                      )}
                      {errors.password_confirmation &&
                      (errors.password_confirmation).type ===
                        'validate' && (
                        <ErrorMessage>Password does not match.</ErrorMessage>
                      )}
                        <Button className="btn" type="submit" >
                          Reset
                        </Button>
                    </form>
                    <Grid className="linkReset">
                    <Grid><span>Please contact us as</span><span className="email" onClick={sendMail} > inquiry@spoutinfo.com</span></Grid>
                    <Grid><span>if you need further assistance.</span></Grid>
                  </Grid>
                  </Grid>
                
                  <Grid className="CopyrightLogin">
                    <p>Copyright ©2020 SpoutINFO. All rights reserved.</p>
                  </Grid>
                </Grid>
            </Grid>
            <Grid className="imageLogin" item lg={8} md={6} xs>
                <Grid className="content" item lg={12} md={12} xs={12} sm={12}>
                    <Grid className="lable_1">A MARKET DATA PLATFORM FOR</Grid>
                    <Grid className="lable_2">THE FEED & LIVESTOCK SECTORS</Grid>
                    <Grid className="lable_3">Feed Additives | Livestock | Feed Grains</Grid>
                </Grid>
            </Grid>
        </Grid>
  );
}
export default Login; 