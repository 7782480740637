import React from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";


const Cookie = () =>{
    const [acceptCookieState, setAcceptCookie] = React.useState(false)
    const getCookie = (cname) => {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
    return "";
  }
  const acceptCookie = () =>{
    document.cookie = "accept_cookie=yes";
    setAcceptCookie(true)

  }

  if(getCookie('accept_cookie')=="yes"|| acceptCookieState == true ) return null
  return(
    <div className="wrap-cookie">
      <Grid container className="bg-color">
        <Grid item md={8} xs={12} className="content-text-cookie">
        We use cookies to understand how you use our website and to improve your browsing experience. This includes personalizing content. By continuing to use our website, you accept our use of cookies, updated Privacy Statement and Terms of Use.
        </Grid>
        <Grid item md={4} xs={12} className="box-btn-cookie">
          <Button
            onClick={acceptCookie}
            className="btn-use-cookie"
          >
            Accept
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}
export default Cookie;