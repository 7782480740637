import React, {useEffect} from 'react';
import MarketDataDetail from '../../components/MarketDataDetail';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
const MarketData  = (props) => {
	useEffect(() => {
    	document.title = 'Market Data SpoutINFO'
  	});
  	return (
	    <div>
	      <Header breadcrumb={props.breadcrumb}/>
	      <MarketDataDetail />
	      <Footer/>
	    </div>
  );
}
export default MarketData;