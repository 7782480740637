export const customStyles = (theme) => ({
  menu: (styles) => ({
    ...styles,
    backgroundColor: 'white',
  }),
  control: (provided, state) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 8,
    minHeight: '40px',
    border: 'none',
    backgroundColor: 'transparent',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    backgroundColor: 'white',
    color: '#424243',
    '&:hover': {
      backgroundColor: 'rgba(36, 36, 37, 0.04)',
      color: '#424243',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    fontSize: 14,
    color: '#686869',
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 14,
    color: '#242425',
    lineHeight: 1.71,
  }),
  input: (styles) => ({
    ...styles,
    fontSize: 14,
  }),
});
