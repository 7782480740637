import React, {useEffect} from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LayoutSubscribe from 'components/LayoutSubscribe';
import BgHeader from '../../components/HeaderBackgound';

const Subscribe  = (props) => {
  useEffect(() => {
    document.title = 'Subscribe SpoutINFO'
  });
  return (
    <div>
      <Header breadcrumb={props.breadcrumb}/>
      <BgHeader isSearch={false}/>
      <LayoutSubscribe/>
      <Footer/>
    </div>
  );
}
export default Subscribe;