import React,{useEffect} from 'react';
import { Grid ,Button} from '@material-ui/core';
import Input from '../Input'
import ErrorMessage from 'components/ErrorMessage';
import Web_logo_1 from 'asset/img/Web-logo-1.png';
import Web_logo_2 from 'asset/img/Web-logo-2.png';
import {useForm} from 'react-hook-form';
import { useSelector, useDispatch } from "react-redux";
import { USER_LOGIN_REQUEST,USER_UPDATE_STATUS_RESET } from 'redux/reducers/User/actionTypes';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { routes } from 'routers/routes';
import 'sweetalert2/src/sweetalert2.scss'
import {
  BrowserRouter as Router,
  Link,
  useRouteMatch,
  useHistory
} from "react-router-dom";

const Login = () => {
  const history = useHistory();
  const user = useSelector(state => state.User);
  const dispatch = useDispatch();
  const {
    register,
    errors,
    handleSubmit,
    setValue,
    clearError,
    setError,
  } = useForm();

  useEffect(() => {
    setValue('email', '')
    setValue('password', '')
  }, [])
  useEffect(() =>{
    if(user.error_login.isError)
    {
        Swal.fire({
          text: user.error_login.message == "The email or password you enter is invalid" ? "The email or password you entered is invalid" : user.error_login.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        }).then(
          function (isConfirm) {
            if (isConfirm) {
               dispatch({
                  type: USER_UPDATE_STATUS_RESET,
                })
            }
          },
          
        );
    }
  },[user])
  const onSubmit = async (data,e) =>{
    e.preventDefault();
    dispatch({
      type: USER_LOGIN_REQUEST,
      data:data,
    });  
  } 
  const redirectDasboard = () => {
    history.push('/');
  }
  const redirecteFeedlink = () => {
    window.open(
      'http://www.efeedlink.com/',
      '_blank'
    );
  }

  return (
        <Grid className="container_login" container >
            <Grid className="form" item lg={4} md={6} xs={12}>
                <Grid className="contentLeft" item lg={8} md={8} xs={12}>
                  <Grid className="account-box-logo"  item lg={12} md={12} xs={12}>
                    <div className="account-logo account-logo-efeed" onClick={redirecteFeedlink}>
                      <img src={Web_logo_1} width="116" height="34"/>
                    </div>
                    <div className="account-logo account-logo-spout" onClick={redirectDasboard}>
                      <img src={Web_logo_2} width="177" height="36"/>
                    </div>
                  </Grid>
                  <Grid className="formLogin" item lg={12} md={12} xs={12}>
                    <p>Login to my account</p>

                    <form onSubmit={handleSubmit(onSubmit)} >
                    <Input
                      label={'Email'}
                      name={'email'}
                      type={'email'}
                      fullWidth
                      required
                      placeholder={'email address'}
                      error={errors.email }
                      validate={register({
                        required: true,
                      })}
                    />
                      {errors.email &&
                      (errors.email ).type === 'required' && (
                        <ErrorMessage>Please enter your email.</ErrorMessage>
                      )} 
                      <Input
                      label={'Password'}
                      name={'password'}
                      type={'password'}
                      fullWidth
                      required
                      placeholder={'Password'}
                      error={errors.password }
                      validate={register({
                        required: true,
                        minLength:6,
                        pattern:'(?=.*\d)(?=.*[a-z]).{6,}'
                      })}
                    />
                      {errors.password &&
                      (errors.password ).type === 'required' && (
                        <ErrorMessage>Please enter your password.</ErrorMessage>
                      )} 
                      {errors.password &&
                      (errors.password ).type === 'minLength' && (
                        <ErrorMessage>Password must be at least 6 characters.</ErrorMessage>
                      )} 
                      {errors.password &&
                        (errors.password ).type === 'pattern' && (
                          <ErrorMessage>
                              Password must be at least 6 characters with a number, a lowercase letter.
                          </ErrorMessage>
                      )}
                        <Button className="btn" type="submit" >
                          Login
                        </Button>
                    </form>
                    <Grid className="linkLogin">
                    <Grid><span>Forgot password?</span><Link to={routes.forgetPassword}> Reset here</Link></Grid>
                    <Grid><span>Don’t have an account?</span><Link to={routes.signup}> Sign up here</Link></Grid>
                  </Grid>
                  </Grid>
                
                  <Grid className="CopyrightLogin">
                    <p>Copyright ©2020 SpoutINFO. All rights reserved.</p>
                  </Grid>
                </Grid>
            </Grid>
            <Grid className="imageLogin" item lg={8} md={6} xs>
                <Grid className="content" item lg={12} md={12} xs={12} sm={12}>
                    <Grid className="lable_1">A MARKET DATA PLATFORM FOR</Grid>
                    <Grid className="lable_2">THE FEED & LIVESTOCK SECTORS</Grid>
                    <Grid className="lable_3">Feed Additives | Livestock | Feed Grains</Grid>
                </Grid>
            </Grid>
        </Grid>
  );
}
export default Login; 