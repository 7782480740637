export const customStyles = (
  customControl,
  customOption,
) => ({
  menu: (styles) => ({
    ...styles,
    backgroundColor: '#FFFFFF',
  }),
  control: (provided, state) =>
    Object.assign(
      {
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 8,
        minHeight: '40px',
        borderColor: '#bfbfc2',
        backgroundColor: '#FFFFFF',
      },
      customControl
    ),
  option: (provided, state) =>
    Object.assign(
      {
        ...provided,
        fontSize: 14,
        backgroundColor: '#FFFFFF',
        color: '#424243',
        '&:hover': {
          backgroundColor: 'rgba(36, 36, 37, 0.04)',
          color: '#424243',
        },
      },
      customOption
    ),
  placeholder: (styles) => ({
    ...styles,
    fontSize: 14,
    color: '#686869',
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.71,
    color: '#424243',
  }),
  input: (styles) => ({
    ...styles,
    fontSize: 14,
  }),
});
