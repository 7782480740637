import React,{useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Select from 'components/SelectTag';
import {countriesCode} from 'common/countriesCode'
import { 
   USER_LOGOUT_REQUEST,
   USER_UPDATE_PROFILE_REQUEST, 
   USER_UPDATE_PROFILE_FAIL,
   USER_ISUPDATE_PROFILE } from 'redux/reducers/User/actionTypes';
import {useForm} from 'react-hook-form';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
}));
const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.User.user_login);
  const isUpdateProfile = useSelector(state => state.User.isUpdateProfile);
  const error = useSelector(state => state.User.error_user_update_profile);
  const [countryCode,setCountryCode] = useState(null);
  const [avatar, setAvatar] = useState()
  const [imgeUpload,setImageUpload] = useState("")

  const { handleSubmit, register, errors, setValue } = useForm();
  useEffect(() => {
    if(Object.keys(user).length){
      setValue("fullname", user.fullname);
      setValue("email", user.email);
      setValue("phone_number", user.phone_number);
      setValue("company", user.users_subscriber_profiles.company);
      setCountryCode(countriesCode.find(element => element.dial_code == `+${user.country_code}`))
      setAvatar(`${process.env.REACT_APP_DOMAIN_API}/avatars/${user.avatar}`)
    }
    if(isUpdateProfile) {
      Swal.fire({
        title: 'SUCCESSFUL',
        text: 'User profile has been updated successfully',
        icon: 'success',
        confirmButtonText: "Confirm",
        }
      ).then(
        function (isConfirm) {
          if (isConfirm) {
            setValue("password", '');
            setImageUpload("");
            dispatch({
              type: USER_ISUPDATE_PROFILE,
              isUpdateProfile : false
            });
          }
        },
        
      );
    }
  },[user,isUpdateProfile]);
  
  useEffect(()=>{
    if(error.isError){
      Swal.fire({
        title: 'ERROR',
        text: error.message,
        icon: 'error',
        confirmButtonText: "Confirm",
        }
      ).then(
        function (isConfirm) {
          if (isConfirm) {
            dispatch({
              type: USER_UPDATE_PROFILE_FAIL,
              error: {
                isError: false,
                message: '',
              }
            });
          }
        },
        
      );
    }

  },error)
  const onSubmit = values => {
    var formData = new FormData()    
    formData.append('email',values.email)
    formData.append('phone_number',values.phone_number)
    formData.append('country_code',countryCode.dial_code.replace('+',''))
    formData.append('company',values.company)
    formData.append('password',values.password)
    formData.append('photo',imgeUpload)
    formData.append('fullname',values.fullname)
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
      payload : formData
    });
  };
  const handleLogout = () => {
    dispatch({
      type: USER_LOGOUT_REQUEST
    });
  }
  const handleChanCountryCode = (value) => {
    setCountryCode(value)
  }
  const selectAvatar = (img) => {
    if(img.target.files.length){
      setAvatar(URL.createObjectURL(img.target.files[0]))
      setImageUpload(img.target.files[0])
    }
  }
  return (
    <Paper className={`paper-profile ${classes.paper}`}>
      <div className="image-profile">
      <input
            accept="image/*"
            style={{display : 'none'}}
            id="contained-button-file"
            type="file"
            onChange={selectAvatar}
      />
      <label htmlFor="contained-button-file">
      <Avatar alt=" " src={avatar} className={user.avatar ? 'avatar-exists' : null}>{user.avatar ? null : <img src="/camera.png" className="icon-camera" width="21" height="17"/>}</Avatar>
      </label>
        <span className="image-note">Profile photo</span>
      </div>
      <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          error={errors.fullname ? true : false}
          label="Full Name*"
          style={{ margin: 8 }}
          placeholder="Full Name"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({
            required: 'Required'
          })}
          name="fullname"
          helperText={errors.fullname &&
            errors.fullname.type === "required" && "Full name is required"}
        />
        <TextField
          error={errors.email ? true : false}
          label="Email*"
          style={{ margin: 8 }}
          placeholder="Email"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({
            required: 'Required',
            pattern : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i
          })}
          name="email"
          helperText={errors.email &&
            errors.email.type === "required" ? "Email is required" : errors.email && errors.email.type === "pattern" ? "Invalid email format" : ""}
        />
        <div className="user-phone-number">
          
         <Select
            label={'Phone Number*'}
            dataOptions={countriesCode}
            value={countryCode}
            optionLabel={'dial_code'}
            inputContainerClass={"form-select-input"}
            onChangeFunction={handleChanCountryCode}
          />
          <TextField
            error={errors.phone_number ? true : false}
            name="phone_number"
            className="text-phone"
            style={{ margin: 8 }}
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            inputRef={register({
              required: 'Required',
              minLength : 5
            })}
            helperText={errors.phone_number &&
            errors.phone_number.type === "required" ? "Phone number is required" : errors.phone_number && errors.phone_number.type === "minLength" ? "The phone number may not be less than 7 digits" : ""}
          />
        </div>
        <TextField
          label="Company Name"
          style={{ margin: 8 }}
          placeholder="Company"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register}
          name="company"
        />
        <TextField
         error={errors.password ? true : false}
          label="Update Password"
          type={'password'}
          style={{ margin: 8 }}
          placeholder="Password"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          inputRef={register({
            pattern : /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{5,20}/
          })}
          name="password"
          helperText={errors.password &&
          errors.password.type === "pattern" && "Your password must be 6 to 20 characters and contain at least 1 alphabet and 1 number" }
        />
        <Button type="submit" className="btn-user-profile" variant="contained" fullWidth>Update Profile</Button>
      </form>
      <p className="user-logout" onClick={handleLogout}>Logout</p>
    </Paper>
  );
}
export default Profile; 