export const GET_LIST_SECTOR_DETAIL_REDUCER = 'GET_LIST_SECTOR_DETAIL_REDUCER';
export const GET_LIST_SECTOR_DETAIL_REQUEST = 'GET_LIST_SECTOR_DETAIL_REQUEST';

export const GET_STATS_REDUCER = 'GET_STATS_REDUCER';
export const GET_STATS_REQUEST = 'GET_STATS_REQUEST';

export const GET_LOCATION_REDUCER = 'GET_LOCATION_REDUCER';
export const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';

export const UPDATE_SECTOR = 'UPDATE_SECTOR';

export const LOADING = 'LOADING';

export const GET_DATA_AVG_PRICE_REDUCER = 'GET_DATA_AVG_PRICE_REDUCER';
export const GET_DATA_AVG_PRICE_REQUEST = 'GET_DATA_AVG_PRICE_REQUEST';

export const GET_DATA_PRICE_HISTORY_REDUCER = 'GET_DATA_PRICE_HISTORY_REDUCER';
export const GET_DATA_PRICE_HISTORY_REQUEST = 'GET_DATA_PRICE_HISTORY_REQUEST';
export const UPDATE_DATA_PRICE_HISTORY = 'UPDATE_DATA_PRICE_HISTORY';

export const GET_DATA_FILE_REPORT_REDUCER = 'GET_DATA_FILE_REPORT_REDUCER';
export const GET_DATA_FILE_REPORT_REQUEST = 'GET_DATA_FILE_REPORT_REQUEST';

export const GET_DATA_COMMENT_REDUCER = 'GET_DATA_COMMENT_REDUCER';
export const GET_DATA_COMMENT_REQUEST = 'GET_DATA_COMMENT_REQUEST';