import React, { useState, useEffect } from 'react';
import { customStyles } from './components/customStyles';
import Select from 'react-select';
import { useTheme } from '@material-ui/core/styles';
import styles from './styles';
import {countriesCode} from 'common/countriesCode'
import InputBase from '@material-ui/core/InputBase'
import Lable from '../../Input/components/Label'
const options = countriesCode.map((countryCode) => ({
  value: countryCode.dial_code,
  label: countryCode.dial_code,
}));

const InputPhone = React.memo((props) => {
  const theme = useTheme();
  const classes = styles();
  let {
    required,
    label,
    errors,
    name,
    value,
    nameOption,
    valueCountryPrefix,
    validate,
    placeholderNationalNumber,
    placeholderCountryPrefix,
    onChangeFunction,
    disabled,
    selectId,
    inputId,
  } = props;

  const countryPrefix = options.filter(
    (option) => option.value === valueCountryPrefix
  )[0];

  const [stateValue, setStateValue] = useState(countryPrefix);

  const onChange = val => {
    if (typeof onChangeFunction !== 'function') return null;
    setStateValue(val);
    onChangeFunction({ name: nameOption, value: val.value });
  };

  const onBlurPhoneInput = val => {
    if (typeof onChangeFunction !== 'function') return null;
    onChangeFunction({ name: name, value: val.target.value });
  };

  useEffect(() => {
    setStateValue(countryPrefix);
  }, [countryPrefix]);

  return (
    <React.Fragment>
      <Lable>
        {label}
      </Lable>
      <div
        className={
          errors ? classes.inputContainerError : classes.inputContainer
        }
      >
        <Select
          name={nameOption}
          onChange={onChange}
          styles={customStyles(theme)}
          placeholder={placeholderCountryPrefix}
          options={options}
          value={stateValue}
          isSearchable={true}
          className={classes.selectContainer}
          components={{
            IndicatorSeparator: () => null,
          }}
          isClearable={false}
        />
        <InputBase
          name={name}
          type="text"
          placeholder={placeholderNationalNumber}
          defaultValue={value}
          onBlur={onBlurPhoneInput}
          inputRef={validate}
          disabled={disabled}
          className="custom-input-phone"
          classes={{
            root: classes.StyledInput,
            disabled: classes.disabled,
          }}
        />
        {errors && <i />}
      </div>
    </React.Fragment>
  );
});

export default InputPhone;
