import React, {useEffect} from 'react';
import SubscriptionPlan from '../../components/SubscriptionPlan';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
const Subscription  = (props) => {
	useEffect(() => {
    	document.title = 'Subscription Plan SpoutINFO'
  	});
  	return (
	    <div>
	      <Header breadcrumb={props.breadcrumb}/>
	      <SubscriptionPlan />
	      <Footer/>
	    </div>
  );
}
export default Subscription;