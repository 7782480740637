import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles((theme) =>
  createStyles({
    disabled: {
      backgroundColor: 'rgb(248,248,248)',
      color: '#1b1e24!important',
    },
    tranformIcon: {
      top: '50%',
      transform: 'translate(0px, -50%)',
      position: 'absolute',
      height: '24px',
      paddingLeft: '8px',
    },
    tranformIconLeft: {
      top: '50%',
      transform: 'translate(0px, -50%)',
      position: 'absolute',
      height: '24px',
      left: '16px',
    },
    hasIconLeft: {
      paddingLeft: '48px',
    },
    tranformIconHasHit: {
      top: '26% !important',
    },
    bold: {
      fontWeight: 500,
    },
  })
);

export default styles;
