import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { routes } from './routes';
import { useSelector, useDispatch } from "react-redux";
import {SET_REDIRECT_MARKET_FORECAST } from '../redux/reducers/MarketForecast/ActionType'
const PrivateRoute = ({breadcrumb, component, isLoggedIn, ...rest }) => {
    const dispatch = useDispatch();
    const Component = component; 
    var redirect_page = rest.computedMatch.path.split("/");
    if(redirect_page[1]==='market-forecast-detail' && !isLoggedIn){
      dispatch({
        type: SET_REDIRECT_MARKET_FORECAST,
        payload: rest.location.pathname
      });
    }
    return (
        <Route
          {...rest}
          render={props =>
            isLoggedIn ? (
              <Component breadcrumb={breadcrumb} {...props}/>
            ) 
            : (
              <Redirect to={routes.login} />
            )
          }
        />
    );
};

export default PrivateRoute;