import React, {useEffect} from 'react';
import LayoutMarketComm from '../../components/LayoutMarketComm';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
const MarketForecast  = (props) => {
  useEffect(() => {
    document.title = 'Market Forecast SpoutINFO'
  });
  return (
    <div>
      <Header breadcrumb={props.breadcrumb}/>
      <LayoutMarketComm />
      <Footer/>
    </div>
  );
}
export default MarketForecast;