import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';

const StyledInput = styled(InputBase)`
  padding: 11px 44px 11px 14px;
  background:white;
  @media only screen and (max-width: 768px) {
    padding-right: 14px;
    width:100%;
  }
  border: 1px solid #bfbfc2;
  border-radius: 4px;
  & > textarea {
    height: 77px !important;
    overflow-y: scroll !important;
    font-size: 14px;
    padding: 14px 44px 14px 14px;
    color: #424243;
  }
  & > input {
    @media only screen and (max-width: 768px) {
      padding-right: 14px;
      width:100%;
      height: 1.2875em !important;
    }
    padding: 0;
    font-size: 14px;
    color: #424243;
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-size: 14px;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      font-size: 14px;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      font-size: 14px;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      font-size: 14px;
    }
  }
`;

export default StyledInput;
