export const routes  = {
  dashboard: '/',
  about : '/about',
  marketComm: '/market-commentary',
  subscribe: '/subscribe',
  term: '/terms-of-use',
  privacy: '/privacy-statement',
  prodetail: '/subscribed-product',
  login: '/sign-in',
  signup: '/sign-up',
  forgetPassword: '/reset-password',
  marketData: '/market-data',
  marketForecast: '/market-forecast',
  userProfile: '/user-profile',
  paymentMethod: '/payment-method',
  marketForecastDetail: '/market-forecast-detail/:sectorId/:countryId/:productId/:reportId',
  MarketForecastDetailNo : '/market-forecast-detail/:sectorId/:countryId/:productId',
  subscriptionPlan: '/subscription-plan',
};
