import React,{useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {  Grid } from '@material-ui/core';
import { useSelector,useDispatch } from "react-redux";
import moment from 'moment';
import {  
  GET_DATA_PRICE_HISTORY_REQUEST,
} from 'redux/reducers/SectorDetail/actionTypes'

const useStyles = makeStyles({
  root: {
    width: '100%',
    boxShadow:'0 0 0 0'
  },
  container: {
    maxHeight: 440,
  },
  row:{
      fontWeight:'bold'
  },
  footer:{
    textAlign:'center',
    padding:'15px',
    background: '#F9F9F9',
    borderRadius:'5px',
    width: '100%',
    cursor:'pointer',
    position: 'sticky',
  }
});

const StickyHeadTable = () => {
    const dispatch = useDispatch();
    const [period,setPeriod] = useState('Period');
    const [isShow,setIsShow] = useState(false);
    const [country,setCountry] = useState('');
    const [region,setRegion] = useState('Guangdong');
    const [city,setCity] = useState('Guangdong City');
    //chosenCountry={sector.chosenCountry} chosenProvince={sector.chosenProvince} chosenCity={sector.chosenCity}
    const sector = useSelector(state => state.Product);
    const classes = useStyles();
    useEffect(() => {
     if(sector.chosenCountry) {
      setCountry(sector.chosenCountry.name)
     }
     else setCountry('')
     if(sector.chosenProvince){
      setRegion(sector.chosenProvince.name)
     }
     else setRegion('')
     if(sector.chosenCity){
      setCity(sector.chosenCity.name)
     }
     else setCity('')
    },sector);
    const columns = [
        { id: 'start_date', label: period, minWidth: 370 },
        { id: 'country', label: country, minWidth: 100 },
        {
          id: 'region',
          label: region,
          minWidth: 100,
          align: 'right',
          format: value => value.toLocaleString(),
        },
        {
          id: 'city',
          label: city,
          minWidth: 100,
          align: 'right',
          format: value => value.toFixed(2),
        },
      ];
      
      function createData(per, coun_try, re_gion, ci_ty) {
        return { per, coun_try, re_gion, ci_ty };
      }
      const getHistory = () =>{
        setIsShow(true);
        dispatch({
          type: GET_DATA_PRICE_HISTORY_REQUEST,
          pms :{
            country: sector.chosenCountry.id,
            region: (sector.chosenProvince && sector.chosenProvince.id) ? sector.chosenProvince.id : '',
            city: (sector.chosenCity && sector.chosenCity.id) ? sector.chosenCity.id : '',
            product: sector.chosenProduct.id,
            sub_category_1: (sector.chosenTier2 && sector.chosenTier2.id) ? sector.chosenTier2.id : '',
            sub_category_2: (sector.chosenTier3 && sector.chosenTier3.id) ? sector.chosenTier3.id : '',
            stats_id: sector.chosenStat.id,
            stats_type: sector.chosenStat.type,
            start_date: moment().subtract(1, 'year').format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
            show_all: 'yes'
          }
        });
      }
      
  return (
    <Paper className={classes.root}>
      {
        isShow ? 
          <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow >
                {columns.map((column,i) => (
                  <TableCell key={i} className={classes.row}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sector.historyPrice && sector.historyPrice.price_history.map((row,k) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={k}>
                    {columns.map((column,key) => {
                      const value = row[column.id];
                      if(column.id ==  'start_date') return <TableCell key={key} align={column.align}> {moment(row.start_date,'YYYY-MM-DD').format('DD MMM YYYY')} - {moment(row.end_date,'YYYY-MM-DD').format('DD MMM YYYY')} </TableCell>
                    
                      if(!value) return <TableCell key={key} align={column.align}>-</TableCell>
                      return (
                        <TableCell key={key} align={column.align}>
                        <Grid container>
                            <Grid item md={6} xs={6}> {value.price ?  value.price  :   '-'}</Grid>
                            <Grid item md={6} xs={6} ><Grid className={`fluctuations ${value.variation}`}></Grid> </Grid>
                        </Grid>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            {/* <TableFooter> */}
              
          {/* </TableFooter> */}
          </Table>
        
        </TableContainer> : ''
      }
      
      <Grid onClick={getHistory} className={classes.footer}>Show all data</Grid>
     
    </Paper>
  );
}
export default StickyHeadTable;