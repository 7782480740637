import axios from 'services/configApi';
const locale = 'en';

export const getProfileUser = async () => {
  try {
    const res = await axios(`api/en/data-search/profile`, { 
      method: "GET",
      headers: {
        Accept: "application/json"
      },
    })  
  return res.data;

  } catch (error) {
    return Promise.reject(error);
  }
};
