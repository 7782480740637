import React, {useEffect} from 'react';
import LayoutMarketForecast from '../../components/LayoutMarketForecast';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
const MarketForecast  = () => {
  useEffect(() => {
    document.title = 'Market Forecast SpoutINFO'
  });
  return (
    <div>
      <Header/>
      <LayoutMarketForecast />
      <Footer/>
    </div>
  );
}
export default MarketForecast;