import React, { useEffect } from 'react';
import Slider from "react-slick";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { GET_LIST_MARKET_DATA_PUBLIC_REQUEST } from 'redux/reducers/Data/ActionType';
import { 
  UPDATE_SECTOR
} from 'redux/reducers/SectorDetail/actionTypes'

const MarketData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sector = useSelector(state => state.Product);
  const isLoginUser = useSelector(state => state.User.islogin);
  const useLoginStorage = useSelector(state => state.User.user_storage);
  const useLoginProfile = useSelector(state => state.User.user_login);
  const MarketData = useSelector(state => state.Data.markerDataPublic);
  useEffect(() => {
      dispatch({
        type: GET_LIST_MARKET_DATA_PUBLIC_REQUEST,
        payload: {
          sector: 29,
          country : 32,
          position: 0,
          user_country: "all"
        }
      });
  }, []);

  const subString = (string, position) => {
    let arrString = string.split(",");
    let str = '';
    switch(position) {
      case "middle":
        arrString.shift();
        arrString.pop();
        str = arrString.join(", ");
        break;
      case "end":
        str = arrString[arrString.length - 1];
        break;
      default:
        str = arrString[0];
    }
    return str
  };

  const redirectPage = (item) => {

    if(!isLoginUser || isLoginUser && useLoginStorage && useLoginStorage.subscription_plan_total == 0  || isLoginUser && useLoginProfile && useLoginProfile.subscription_plan_total == 0) {
      return history.push("/subscribe");
    }

    document.cookie = `sector_id=${item.sector_id}`;
    document.cookie = `country_id=${item.country_id}`;
    document.cookie = `product_id=${item.product_id}`;
    document.cookie = `stat_id=${item.default_field}`;
    document.cookie = `tier2_id=${item.sub_category_1_id}`;
    document.cookie = `tier3_id=${item.sub_category_2_id}`;

    dispatch({
      type:UPDATE_SECTOR, 
  
      chosenSector    : sector.sectorDetail.sectors.filter(sector => sector.id == item.sector_id)[0],
      chosenProduct   : {id: item.sub_category_1_id, title: subString(item.name, "head")},
      sectorProducts  : null,
      tier2List       : null,
      chosenTier2     : {id: item.sub_category_1_id, title: subString(item.name, "middle")},
      tier3List       : null,
      chosenTier3     : {id: item.sub_category_2_id, title: subString(item.name, "end")},
      //*stats
  
      statList    : [],
      chosenStat  : {id: item.default_field, name: 'Loading...'},
      //*country
  
      locationList    : [],
      chosenCountry   : {id: item.country_id, name: 'Loading...'},
      provinceList    : [],
      chosenProvince  : null,
      CityList        : [],
      chosenCity      : null
    });

    return history.push("/subscribed-product");
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
    ],
  };
  return ( 
    <div className = "market-data" >
      <Grid container >
        <Grid item xs={12}>
          <div className="wrap-market">
            <div className="link-market">Market Data - SpoutINFO</div>
            <Slider {...settings}>
              {MarketData.products.map((item, key) => {
                return(
                  <div className="slide-item" key={key} onClick={() => redirectPage(item)}>
                    <Paper className="title-market">{subString(item.name, "head")}</Paper>
                    <Paper elevation={key}>
                      {subString(item.name, "middle")}
                    </Paper>
                    <Paper elevation={key}>
                     China, {subString(item.name, "end")}
                    </Paper>
                    <Paper className="cost">
                      {item.currency}
                      {item.variation === 'increase' ? <Paper className="increase">{item.price}</Paper> : <Paper className="decrease">{item.price}</Paper>} 
                    </Paper>
                  </div>
                );
              })}
            </Slider>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
export default MarketData;