import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
const GotoSubscribe = () => {
  let history = useHistory();
  
  function handleClick() {
    history.push("/subscribe");
  }  
  return (
    <div className="layout">
        <Grid className="subscribeQuestion">
        Leverage on SpoutINFO market information
        </Grid>
        <Grid className="subscribeQuestion">
        for smart business decision making
        </Grid>
        <Grid className="subscribe viewContent">
        Subscribe and get immediate access to the latest market prices and commentaries
        </Grid>
        <Grid className="linkSubscribe">
          <Button className="subscribeButtonLink" onClick={handleClick}>Subscribe Now!</Button>
        </Grid>
    </div>
   );
}
export default GotoSubscribe