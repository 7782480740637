import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import {isEmpty} from "lodash";
import moment, { now } from 'moment'
import { 
  UPDATE_SECTOR
} from 'redux/reducers/SectorDetail/actionTypes'
import {
  GET_DATA_AVG_PRICE_REQUEST, 
  LOADING
} from 'redux/reducers/SectorDetail/actionTypes'
import { 
  GET_DATA_CHART_REQUEST
} from 'redux/reducers/Chart/actionTypes';
const handleChoseTransactLocalPrice = (item) => {

};

const cutString = (item) => {
  let array = item.name.split(',');
  array.splice(0,1);
  array.splice(array.length-1,1);
  return array.toString();
}

const Analysis  = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sector = useSelector(state => state.Product);
  const MarketData = useSelector(state => state.Data.markerDataHome);

  const subString = (string, position) => {
    let arrString = string.split(",");
    let str = '';
    switch(position) {
      case "middle":
        arrString.shift();
        arrString.pop();
        str = arrString.join(", ");
        break;
      case "end":
        str = arrString[arrString.length - 1];
        break;
      default:
        str = arrString[0];
    }
    return str
  };
  const loading = () => {
    dispatch({
      type:LOADING,
      loading : true,
    })
  }
  const redirectPage = (item) => {
    //loading();
    // if(!isLoginUser || isLoginUser && useLoginStorage && useLoginStorage.subscription_plan_total == 0  || isLoginUser && useLoginProfile && useLoginProfile.subscription_plan_total == 0) {
    //   return history.push("/subscribe");
    // }
  
    document.cookie = `sector_id=${item.sector_id}`;
    document.cookie = `country_id=${item.country_id}`;
    document.cookie = `product_id=${item.product_id}`;
    document.cookie = `stat_id=${item.default_field}`;
    document.cookie = `tier2_id=${item.sub_category_1_id}`;
    document.cookie = `tier3_id=${item.sub_category_2_id}`;
  
    dispatch({
      type:UPDATE_SECTOR, 
  
      chosenSector    : sector.sectorDetail.sectors.filter(sector => sector.id == item.sector_id)[0],
      chosenProduct   : {id: item.sub_category_1_id, title: subString(item.name, "head")},
      sectorProducts  : null,
      tier2List       : null,
      chosenTier2     : {id: item.sub_category_1_id, title: subString(item.name, "middle")},
      tier3List       : null,
      chosenTier3     : {id: item.sub_category_2_id, title: subString(item.name, "end")},
      //*stats
  
      statList    : [],
      chosenStat  : {id: item.default_field, name: 'Loading...'},
      //*country
  
      locationList    : [],
      chosenCountry   : {id: item.country_id, name: 'Loading...'},
      provinceList    : [],
      chosenProvince  : null,
      CityList        : [],
      chosenCity      : null
    });
    
    history.push("/subscribed-product");

    dispatch({
      type: GET_DATA_AVG_PRICE_REQUEST,
      pms :{
        country: item.country_id,
        region: (sector.chosenProvince && sector.chosenProvince.id) ? sector.chosenProvince.id : '',
        city:  (sector.chosenCity && sector.chosenCity.id) ? sector.chosenCity.id : '',
        product: item.product_id,
        sub_category_1: item.sub_category_1_id,
        sub_category_2: item.sub_category_2_id,
        stats_id:  item.default_field,
        stats_type: sector.chosenStat.type
      }
    });
    
    dispatch({
      type: GET_DATA_CHART_REQUEST,
      pms :{
        country: item.country_id,
        region:  (sector.chosenProvince && sector.chosenProvince.id) ? sector.chosenProvince.id : '',
        city:  (sector.chosenCity && sector.chosenCity.id) ? sector.chosenCity.id : '',
        product:  item.product_id,
        sub_category_1: item.sub_category_1_id,
        sub_category_2: item.sub_category_2_id,
        stats_id:  item.default_field,
        stats_type: sector.chosenStat.type,
        start_date: moment().subtract(1, 'year').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD')
      }
    });
    loading();
  }
  
  return(
    <div className="wrap-analysis-product">
      <Grid container>
        <Grid item xs={12}>

        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <div className="wrap-title-analysis">
                <div className="title-analysis flex-left">
                  Products
                </div>
                <div className="title-analysis flex-right">
                  Price (USD)
                </div>
              </div>
              {MarketData.products.map((item, key) => {
                 return(
                  <div className="name-and-fluc" key={key}>
                    <div className="flex-left" onClick={() => redirectPage(item)}>
                      <div className="link-analysis">
                        {/* Vitamin A */}
                        {cutString(item)}
                      </div>
                      <div>
                        {/* 1,000 IU/mg, pellet */}
                        {item.name.split(",")[0]}
                      </div>
                    </div>
                    <div className="flex-right">
                      <label className="price">
                      {/* {item.currency} */}
                      </label>
                      <label className={`fluctuations ${item.variation}`}> 
                        {item.price}
                      </label>
                    </div>
                  </div>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default Analysis;