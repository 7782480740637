export const GET_CARD_REDUCER 		  = 'GET_CARD_REDUCER';
export const SET_CARD_REQUEST 		  = 'SET_CARD_REQUEST';

export const SET_CARD_TOKEN_REQUEST   = 'SET_CARD_TOKEN_REQUEST';
export const CREATE_CARD_FAIL 		  = 'CREATE_CARD_FAIL';
export const GET_LIST_CARD_REDUCER 	  = 'SET_CARD_TOKEN_REDUCER';

export const GET_LIST_CARD_REQUEST 	  = 'GET_LIST_CARD_REQUEST';

export const SET_DEFAULT_CARD_REQUEST = 'SET_DEFAULT_CARD_REQUEST';
export const DEFAULT_CARD_FAIL 		  = 'DEFAULT_CARD_FAIL';

export const REMOVE_CARD_REQUEST 	  = 'REMOVE_CARD_REQUEST';
export const REMOVE_CARD_FAIL		  = 'REMOVE_CARD_FAIL';