export const GET_LIST_DATA_HOME_REDUCER = 'GET_LIST_DATA_HOME_REDUCER';
export const GET_LIST_DATA_HOME_REQUEST = 'GET_LIST_DATA_HOME_REQUEST';
export const GET_LIST_MARKET_DATA_REDUCER = 'GET_LIST_MARKET_DATA_REDUCER';
export const GET_LIST_MARKET_DATA_REQUEST = 'GET_LIST_MARKET_DATA_REQUEST';
export const UPDATE_IS_LOAD_MORE_MARKET_DATA = 'UPDATE_IS_LOAD_MORE_MARKET_DATA';

export const GET_LIST_DATA_ANALYSIS_REDUCER = 'GET_LIST_DATA_ANALYSIS_REDUCER';
export const GET_LIST_DATA_ANALYSIS_REQUEST = 'GET_LIST_DATA_ANALYSIS_REQUEST';

export const SET_STATUS_LOADING = "SET_STATUS_LOADING";

export const GET_LIST_MARKET_DATA_PUBLIC_REDUCER = 'GET_LIST_MARKET_DATA_PUBLIC_REDUCER';
export const GET_LIST_MARKET_DATA_PUBLIC_REQUEST = 'GET_LIST_MARKET_DATA_PUBLIC_REQUEST';

export const UPDATE_DATA_REDUCER = 'UPDATE_DATA_REDUCER';