import React from 'react';
import { Grid } from '@material-ui/core';
import efeed from 'asset/images/efeedLink.png'
import spoutinfo from 'asset/images/spoutinfo.png'
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
const Footer = () => {
  const sendMail = () => {
    window.open('http://www.efeedlink.com/contact/enquiry.html', '_blank');
  }
  return (
    <div className="footer" spacing={1}>
      <Grid container spacing={1}>
        <Grid className="spounLogo" container item xl={12} md={12} xs={12} sm={12}>
          <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
          </Grid>

          <Grid xl={6} lg={6} md={6} sm={12} xs={12} item>
            <List component="nav" className="contact">
              <ListItem>
                <ListItemText onClick={sendMail} primary="Contact Us" />
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Link to="/about">About SpoutINFO</Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Link to="/terms-of-use">Terms of Use</Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Link to="/privacy-statement">Privacy Statement</Link>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid> 

        <Grid className="spounLogo footer-content" container item xl={12} md={12} xs={12} sm={12}>
          <Grid xl={6} lg={6} md={6} sm={12} xs={12} className="logoFooter" item>
            <div className="logoFooter-box">
              <div>
                <a className="logo" href="http://www.efeedlink.com" target="_blank" rel="noopener noreferrer"><img src={efeed} alt="" with="145" height="42"/></a>
              </div>
              <div>
                <Link to="/" className="logo">
                  <img src={spoutinfo} alt="" with="206" height="42"/>
                </Link>
              </div>
            </div>
          </Grid>
          <Grid xl={6} lg={6} md={6} sm={12} xs={12} item className="footer-content-right">
            <div className="content">
              <span className="Copyright">Copyright ©2020 SpoutINFO. All rights reserved.</span>
              <p className="text-foot">We use cookies to understand how you use our website and to improve your browsing experience. This includes personalizing content and advertising. By continuing to use our website, you accept our use of cookies, updated <Link to="/privacy-statement">Privacy Statement</Link> and <Link to="/terms-of-use">Terms of Use</Link>.</p>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default Footer;