import axios from 'services/configApi';
const locale = 'en';

export const getListDataPublic = async (data) => {
  try {
  // delete axios.defaults.headers.common["USER-TOKEN"];  
    // const result = await axios(`api/${locale}/data-search/latest-price`, { 
    //     method: "POST",
    //     data : data,
    //     headers: {
    //       Accept: "application/json",
    //       "USER-TOKEN" : ""
    //     },
    //   })
    // return result.data;

    const result = await fetch(`${process.env.REACT_APP_DOMAIN_API}/api/${locale}/data-search/latest-price`, {
      crossDomain:true,
      method: 'POST',
      headers: {'Content-Type':'application/json'},
      body : JSON.stringify(data)
    });
    const dataResponse = await result.json();
    if(dataResponse.error){
      return Promise.reject(dataResponse);
    }
    return dataResponse
    
  } catch (error) {
    return Promise.reject(error);
  }
};
