import React, { memo, useEffect } from 'react';
import Slider from "react-slick";
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Select from 'components/SelectTag';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InfiniteScroll from 'react-infinite-scroller';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Paper from "@material-ui/core/Paper";
import { Link,useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { GET_LIST_CATEGORY_MARKET_DATA_REQUEST } from 'redux/reducers/Category/ActionType';
import { GET_LIST_COUNTRIES_MARKET_DATA_REQUEST } from 'redux/reducers/Countries/ActionType';
import {
  GET_LIST_MARKET_DATA_REQUEST,
  UPDATE_DATA_REDUCER
 } from 'redux/reducers/Data/ActionType';
import { 
  UPDATE_SECTOR
} from 'redux/reducers/SectorDetail/actionTypes'
import { routes } from 'routers/routes';

const MarketData = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ListCategory = useSelector(state => state.Category.categoryMarketData);
  const ListCountries = useSelector(state => state.Countries.countriesMarketData);
  const ListMarketData = useSelector(state => state.Data.markerData);

  const isLoginUser = useSelector(state => state.User.islogin);
  const useLoginStorage = useSelector(state => state.User.user_storage);
  const useLoginProfile = useSelector(state => state.User.user_login);

  useEffect(() => {
    if(isLoginUser && useLoginStorage && useLoginStorage.subscription_plan_total == 0  || isLoginUser && useLoginProfile && useLoginProfile.subscription_plan_total == 0) {
      Swal.fire({
        title: 'You have not subscribed yet.',
        text: "You will be redirected to SpoutINFO subscription page",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      }).then((result) => {
        history.push("/subscribe");
      })
    }
    else {
      dispatch({
        type: UPDATE_DATA_REDUCER,
      });
      dispatch({
        type: GET_LIST_CATEGORY_MARKET_DATA_REQUEST,
      });
    }
  }, []);

  useEffect(() => {
    if(search) {
      handleSearchText(search);
    }
    else {
      setPoducts(ListMarketData.products);
    }
  }, [ListMarketData]);

  const [products, setPoducts] = React.useState(ListMarketData.products);
  const [search, setSearch] = React.useState('');
  const [selectedIndexCategory, setIndexCategory] = React.useState(0);
  const [selectedIndexCountry, setIndexCountry] = React.useState(0);
  const [category, setCategory] = React.useState('');
  const handleChoseCategory = (event, key, item) => {
    dispatch({
      type: GET_LIST_COUNTRIES_MARKET_DATA_REQUEST,
      sector: item.id
    });
    setIndexCategory(key);
  };

  const handleChoseCountry = (event, key, item) => {
    dispatch({
      type: GET_LIST_MARKET_DATA_REQUEST,
      payload: {
        sector: ListMarketData.filter.sector,
        country : item.id,
        position: 0,
        user_country: "all"
      }
    });
    setIndexCountry(key);
  };

  const handleLoadMore = () => {
    if(!ListMarketData.isFull && !ListMarketData.isLoad && !ListMarketData.isLoadFirst) {
      dispatch({
        type: GET_LIST_MARKET_DATA_REQUEST,
        payload: {
          sector: ListMarketData.filter.sector,
          country : ListMarketData.filter.country,
          position: ListMarketData.position,
          user_country: "all"
        }
      })
    }
    
  }

  const handleChoseCategoryMobile = (item) => {
    dispatch({
      type: GET_LIST_COUNTRIES_MARKET_DATA_REQUEST,
      sector: item.id
    });
  };

  const handleChoseCountryMobile = (item) => {
    dispatch({
      type: GET_LIST_MARKET_DATA_REQUEST,
      payload: {
        sector: ListMarketData.filter.sector,
        country : item.id,
        position: 0,
        user_country: "all"
      }
    });
  };

  const handleSearchText = (value) => {
    let list = ListMarketData.products;
    list = list.filter(item => {
      if (item.name.toUpperCase().indexOf(value.toUpperCase()) > -1) {
        return item;
      }
    })
    setSearch(value);
    setPoducts(list);
  };

  const getInfoDate = (type ,date) => {
    if(!date) return;
    switch (type) {
      case 'year':
        const numberYear = moment(date, 'YYYY-MM-DD').year();
        return numberYear;
      case 'month':
        const numberMonth = moment(date, 'YYYY-MM-DD').format('MMM');
        return numberMonth.toUpperCase();
      case 'day':
        const numberDay = moment(date, 'YYYY-MM-DD').format('DD MMM');
        return numberDay;
      default:
        break;
    }
  }
  const goToProduct = (item) => {
    document.cookie = `sector_id=${item.sector_id}`;
    document.cookie = `country_id=${item.country_id}`;
    document.cookie = `product_id=${item.product_id}`;
    document.cookie = `stat_id=${item.default_field}`;
    document.cookie = `tier2_id=${item.sub_category_1_id}`;
    document.cookie = `tier3_id=${item.sub_category_2_id}`;

    let arrStr = item.name.split(",");

    let arrTemp = arrStr.slice();
    arrTemp.shift();
    arrTemp.pop();

    dispatch({
      type:UPDATE_SECTOR,
  
      chosenSector    : ListCategory.filter(sector => sector.id == item.sector_id)[0],
      chosenProduct   : {id: item.product_id, title: arrStr[0]},
      sectorProducts  : null,
      tier2List       : null,
      chosenTier2     : {id: item.sub_category_1_id, title: arrTemp.join(", ")},
      tier3List       : null,
      chosenTier3     : {id: item.sub_category_2_id, title: arrStr[arrStr.length - 1]},
      //*stats
  
      statList    : [],
      chosenStat  : {id: item.default_field, name: 'Loading...'},
      //*country
  
      locationList    : [],
      chosenCountry   : {id: item.country_id, name: 'Loading...'},
      provinceList    : [],
      chosenProvince  : null,
      CityList        : [],
      chosenCity      : null
    });
    history.push(routes.prodetail);
  }

  return (
    <div className="market-data-detail">
      <Grid container spacing={3}>
        <Grid item xs={3} md={3} lg={3} className="hide-filter-mobile">
          <List subheader={<ListSubheader className="subHeaderMarketComm">Category</ListSubheader>} component="nav">
            {ListCategory.map((item,key)=>{
              return(
                <ListItem
                  key={key}
                  button
                  selected={selectedIndexCategory == key}
                  onClick={event => handleChoseCategory(event, key, item)}
                  >
                    <ListItemText>
                      {item.title}
                    </ListItemText>
                </ListItem>
              );
            })}
          </List>
          <List subheader={<ListSubheader className="subHeaderMarketComm">Countries</ListSubheader>} component="nav">
            {ListCountries.map((item, key) => {
                return (
                  <ListItem
                    key={key}
                    button
                    selected={selectedIndexCountry == key}
                    onClick={event => handleChoseCountry(event, key, item)}
                  >
                    <ListItemIcon><img className="img-20-15" src={item.icon_flag}/></ListItemIcon>
                    <ListItemText>{item.name}</ListItemText>
                  </ListItem>
                )
              }
            )}
          </List>
        </Grid>
        <Grid item xs={12} md={12} lg={9} className="grid-comm-mobile market-data">
          <List subheader={<ListSubheader className="marketCommTitle">
            <span>Market Data</span>
            <div className="search-data">
              <input type="text" className="search-input" placeholder="Search" onChange={e => handleSearchText(e.target.value)}/>
              <SearchIcon id="icon-search"></SearchIcon>
            </div>
          </ListSubheader>} component="nav">
            <ListItem className="filter-md">
              <div className="box-search">
                <InputLabel shrink htmlFor="age-native-label-placeholder">Category</InputLabel>
                <Select
                  name={'roomNoFormat'}
                  dataOptions={ListCategory}
                  optionLabel={'title'}
                  onChangeFunction={handleChoseCategoryMobile}
                />
              </div>
              <div className="box-search">
                <InputLabel shrink htmlFor="age-native-label-placeholder">Country</InputLabel>
                <Select
                  name={'roomNoFormat'}
                  dataOptions={ListCountries}
                  optionLabel={'name'}
                  onChangeFunction={handleChoseCountryMobile}
                />
              </div>
            </ListItem>
            {!products.length && ListMarketData.isFull ?
              (<ListItem className="text-no-data">
                <Grid className="no-data">
                  <ListItemText>No data for this category</ListItemText>
                </Grid>
              </ListItem>)
              : null
            }
            
            <InfiniteScroll
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={true || false}
              loader={ ListMarketData.isLoad ? <div className="loader mobile-center-loader" key={0}><CircularProgress className="load-more" /></div> : null}
            >
              {products.map((item, key) => {
                  return (
                    <ListItem
                      key={key}
                      button
                      onClick={() => goToProduct(item)}
                    >
                      <div className="box box-date">
                        <ListItemText className="date-comm">
                          {getInfoDate('day', item.start_date)}{ item.end_date ? ' - ' + getInfoDate('day', item.end_date) : ''}
                        </ListItemText>
                      </div>
                      <div className="box box-text">
                        <ListItemText className="text-sub-market">
                          <strong className="sector">{item.name.split(",")[0]}</strong>
                          <span className="content">{item.name.replace(item.name.split(",")[0] + ",", "")}</span>
                        </ListItemText>
                      </div>
                      <div className="box box-price">
                        <strong>{item.currency} {item.price}
                        {item.variation === 'increase' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon className="decrease"/>} 
                        </strong>
                      </div>
                    </ListItem>
                  )
                }
              )}
            </InfiniteScroll>
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
export default MarketData;