import React, {useEffect} from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import BgHeader from 'components/HeaderBackgound';
import MarketData from 'components/MarketData';
import LatestCommentaries from 'components/LatestCommentaries';
import GotoSubscribe from 'components/GotoSubscribe';

const Home  = (props) => {
  useEffect(() => {
    document.title = 'SpoutINFO'
  });
  return (
    <div>
      <Header breadcrumb={props.breadcrumb}/>
      <BgHeader/>
      <MarketData/>
      <LatestCommentaries/>
      <GotoSubscribe/>
      <Footer/>
    </div>
  );
}
export default Home;