import React, {useEffect, useState} from 'react';
import { Grid } from '@material-ui/core';
import Select from 'components/SelectTag';
import { useSelector, useDispatch } from "react-redux";
import {
  GET_LIST_DATA_ANALYSIS_REQUEST,
} from 'redux/reducers/Data/ActionType';
import { 
  UPDATE_SECTOR,
  GET_STATS_REQUEST
} from 'redux/reducers/SectorDetail/actionTypes';
import { routes } from 'routers/routes';
import { useHistory } from 'react-router-dom';

const Analysis  = (props) => {
  let {
    idSector,
    idCountry
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [ tier, setTier] = useState([]);
  const [dataTier, setDataTier] = useState([]);
  const [isClick, setIsClick] = React.useState(false);

  const ListCategory = useSelector(state => state.Category.category);
  const ListCategoryMarketForecast = useSelector(state => state.Category.categoryMarketForecast);
  const ListCountries = useSelector(state => state.Countries.countriesMarketForecast);
  const AnalysisData = useSelector(state => state.Data.analysisData);
  const chosenStat = useSelector(state => state.Product.chosenStat);
  const LoadingStatus = useSelector(state => state.Data.isLoading);
  useEffect(() => {
    if(idCountry && idSector){
      dispatch({
        type: GET_LIST_DATA_ANALYSIS_REQUEST,
        data: {
          sector: idSector,
          country: idCountry,
          position: 0,
          user_country: "all"
        }
      })
    }
  }, [idSector, idCountry]);

  useEffect(() => {
    if( Object.keys(AnalysisData).length){
      let newData = [];
      Object.keys(AnalysisData).map((item, key)=>{
        if(key == 0)
          setDataTier(AnalysisData[item])
        newData.push({
          type : item,
          key : item
        })
      })
      setTier(newData)
    }
    else{
      setTier([]);
      setDataTier([]);
    }
  }, [AnalysisData]);
  const goToChart = (item) => {
    setIsClick(true);
    document.cookie = `sector_id=${item.sector_id}`;
    document.cookie = `country_id=${item.country_id}`;
    document.cookie = `product_id=${item.product_id}`;
    document.cookie = `stat_id=${item.default_field}`;
    document.cookie = `tier2_id=${item.sub_category_1_id}`;
    document.cookie = `tier3_id=${item.sub_category_2_id}`;
    let categoryNew = ListCategoryMarketForecast;
    if(!ListCategoryMarketForecast.length){
      categoryNew = ListCategory;
    }
    dispatch({
      type:UPDATE_SECTOR,
  
      chosenSector    : categoryNew.find(sector => sector.id == item.sector_id),
      chosenProduct   : {id: item.product_id, title: item.name.split(",")[0]},
      sectorProducts  : null,
      tier2List       : null,
      chosenTier2     : {id: item.sub_category_1_id, title: splitString(item)},
      tier3List       : null,
      chosenTier3     : {id: item.sub_category_2_id, title: item.name.split(",")[2]},
    //   //*stats
  
      statList    : [],
      chosenStat  : {id: item.default_field, name: 'Loading...'},
      //*country
  
      locationList    : [],
      chosenCountry   : {id: item.country_id, name: 'Loading...'},
      provinceList    : [],
      chosenProvince  : null,
      CityList        : [],
      chosenCity      : null
    });
    dispatch({
      type: GET_STATS_REQUEST,
      pms :{
        product         : item.product_id,
        sub_category_1  : item.sub_category_1_id,
        sub_category_2  : item.sub_category_2_id
      }
    });
  }
  useEffect(() => {
    if(isClick && chosenStat) {
      history.push(routes.prodetail);
    }
  }, [chosenStat]);
  const splitString = (item) => {
    let array = item.name.split(',');
    array.splice(0,1);
    array.splice(array.length-1,1);
    return array.toString();
  }
  const handleChoseTransactLocalPrice = (item) => {
    setDataTier(AnalysisData[item.type]);
  };
  return(
    <div className="wrap-analysis">
      <Grid container>
        <Grid item xs={12}>
          <Select
            required
            name={'roomNoFormat'}
            optionLabel={'type'}
            inputContainerClass={"select-transact-local"}
            dataOptions={tier}
            onChangeFunction={handleChoseTransactLocalPrice}
          />  
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <div className="wrap-title-analysis">
                <div className="title-analysis flex-left">
                  Products
                </div>
                <div className="title-analysis flex-right">
                  Price (USD)
                </div>
              </div>
              {!dataTier.length && !LoadingStatus ?
                (<Grid className="text-no-data" container justify="center" alignItems="center">
                    No data for this analysis
                  </Grid>
                ) 
              : 
                null
              }
              {dataTier && dataTier.map((item, key)  => {
                return (
                  <div key={key} className="name-and-fluc">
                    <div className="flex-left">
                      <div onClick={() => goToChart(item)} className="link-analysis">
                      {splitString(item)}
                      </div>
                      <div>
                      {item.name.split(",")[0]}
                      </div>
                    </div>
                    <div className="flex-right">
                      <label className={`price ${item.variation == 'increase' ? 'increase' : item.variation == 'decrease' ? 'decrease' : null}`}>
                        {item.price}
                      </label>
                    </div>
                  </div>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default Analysis;