import React from 'react';
export default class LoadingAnalysis extends React.Component {
  render() {
    return (
        <div className="wrap-loader">
            <div className="loading-analysis"></div>
            <div className="text-load">Loading</div>
        </div>
    );
  }
}