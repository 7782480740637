import * as React from 'react';
import Modal from '@material-ui/core/Modal';
import Web_logo_1 from 'asset/img/Web-logo-1.png';
import Web_logo_2 from 'asset/img/Web-logo-2.png';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { routes } from 'routers/routes';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
const SubscribePopup = React.memo((props) => {
  let {
   changeHandler
  } = props;
  let history = useHistory();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    changeHandler(false)
  };
  const redirectDasboard = () => {
    history.push('/');
  }
  const redirecteFeedlink = () => {
    window.open(
      'http://www.efeedlink.com/',
      '_blank'
    );
  }
  const redirect = () => {
    setOpen(false);
    changeHandler(false)
    history.push(routes.subscribe)
  }
  return(
    <div>
      <Modal
        open={open}
        //onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 300,
        }}
      >
      <Fade in={open}>
        <div className="paper">
          <div className="wrap-logo">
            <img className="logo-item" src={Web_logo_1} onClick={redirecteFeedlink} />
            <img className="logo-item" src={Web_logo_2} onClick={redirectDasboard} />
          </div>
          <div className="title">Subscribe to more products & get access to this commentary</div>
          <div className="subscription">SpoutINFO, in conjunction with eFeedLink, offers market data information in five categories: Feed Additives, Feed Grains, Livestock, Feed, Macro Ingredients.</div>
          <Button onClick={redirect} className="btn btn-subscribe" >
            Subscribe Now
          </Button>
          <br/>
          <Button onClick={handleClose} className="btn btn-close">
            Close
          </Button>
        </div>
        </Fade>
      </Modal>
    </div>
  )
})
export default SubscribePopup;