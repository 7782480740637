import React from 'react';

const SVG = ({
  style = {},
  fill = '#000',
  width = '100%',
  height = '100%',
  className = '',
}) => (
  <svg
    width={width}
    height={height}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
  >
    <path
      fill={fill}
      d="M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,10.5 C11.4871642,10.5 11.0644928,10.8860402 11.0067277,11.3833789 L11,11.5 L11,15.5 C11,16.0522847 11.4477153,16.5 12,16.5 C12.5128358,16.5 12.9355072,16.1139598 12.9932723,15.6166211 L13,15.5 L13,11.5 C13,10.9477153 12.5522847,10.5 12,10.5 Z M12,7 C11.4477153,7 11,7.44771525 11,8 C11,8.55228475 11.4477153,9 12,9 C12.5522847,9 13,8.55228475 13,8 C13,7.44771525 12.5522847,7 12,7 Z"
    />
  </svg>
);

export default SVG;
