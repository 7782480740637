import React, { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import Grid from "@material-ui/core/Grid";

import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Select from 'components/SelectTag';
import InputLabel from '@material-ui/core/InputLabel';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import { GET_SUBSCRIPTION_PLAN_REQUEST, SET_AUTO_RENEW_SUBSCRIPTION_PLAN_REQUEST } from 'redux/reducers/SubscriptionPlan/ActionType';
import { GET_LIST_COUNTRIES_REQUEST } from 'redux/reducers/Countries/ActionType';

import moment from 'moment'
import { routes } from 'routers/routes';
import { makeStyles } from '@material-ui/core/styles';

import {
  useHistory
} from "react-router-dom";


const SubscriptionPlan = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ListCountries = useSelector(state => state.Countries.countries);
  const SubscriptionData = useSelector(state => state.SubscriptionPlan.subscriptions);
  const AutoRenewFail = useSelector(state => state.SubscriptionPlan.auto_renew_fail);

  const [selectedIndexCountry, setIndexCountry] = React.useState(0);
  const [subscriptions, setSubscriptions] = React.useState([]);
  const [countries, setCountries] = React.useState([]);

  const isLoginUser = useSelector(state => state.User.islogin);
  const useLoginStorage = useSelector(state => state.User.user_storage);
  const useLoginProfile = useSelector(state => state.User.user_login);

  useEffect(() => {
    if(isLoginUser && useLoginStorage && useLoginStorage.subscription_plan_total == 0  || isLoginUser && useLoginProfile && useLoginProfile.subscription_plan_total == 0) {
      Swal.fire({
        title: 'You have not subscribed yet.',
        text: "You will be redirected to SpoutINFO subscription page",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      }).then((result) => {
        history.push("/subscribe");
      })
    }
    else {
      dispatch({
        type: GET_LIST_COUNTRIES_REQUEST,
      });
      dispatch({
        type: GET_SUBSCRIPTION_PLAN_REQUEST,
      });
    }
  }, []);

  useEffect(() => {
    let countries = ListCountries;
    if(ListCountries.length > 0 && ListCountries[0].id != 0) {
      countries.unshift({
        id: 0,
        name: "All",
        icon_flag: '',
        created_at: "",
        updated_at: "",
        country_code: ""
      })
    }
    
    setCountries(countries);
  }, [ListCountries]);

  useEffect(() => {
    setSubscriptions(SubscriptionData);
  }, [SubscriptionData]);

  useEffect(() => {
    if(AutoRenewFail.isError && !AutoRenewFail.isLoad) {
      Swal.fire({
        title: 'Error!',
        text: AutoRenewFail.message,
        icon: 'error',
        confirmButtonText: 'Ok'
      })
    }
    if(!AutoRenewFail.isError && !AutoRenewFail.isLoad) {
      Swal.fire({
        title: 'Success!',
        text: 'Set card as successful default payment.',
        icon: 'success',
        confirmButtonText: 'Ok'
      })
      dispatch({
        type: GET_SUBSCRIPTION_PLAN_REQUEST,
      });
    }
  },[AutoRenewFail]);

  const handleChoseCountry = (event, key, item) => {
    let subscriptions = SubscriptionData;
    if(item.id != 0) {
      subscriptions = subscriptions.filter(itemSub => itemSub.country_id == item.id);
    }
    setSubscriptions(subscriptions);
    setIndexCountry(key);
  };

  const handleChoseCountryMobile = (item) => {
    let subscriptions = SubscriptionData;
    if(item.id != 0) {
      subscriptions = subscriptions.filter(itemSub => itemSub.country_id == item.id);
    }
    setSubscriptions(subscriptions);
  };

  const handleChangeAutoRenew = event => {
    dispatch({
      type: SET_AUTO_RENEW_SUBSCRIPTION_PLAN_REQUEST,
      data: {
        plan_id: event.target.value,
        auto_renew: event.target.checked ? 'yes' : 'no'
      }
    });
  };

  const getInfoDate = (date) => {
    return moment(date, 'YYYY-MM-DD').format('DD MMM YYYY');
  }

  return (
    <div className="market-data-detail subscription-plan">
      <Grid container spacing={3}>
        <Grid item xs={3} md={3} lg={3} className="hide-filter-mobile">
          <List subheader={<ListSubheader className="subHeaderMarketComm">Countries</ListSubheader>} component="nav">
            {countries.map((item, key) => {
                return (
                  <ListItem
                    key={key}
                    button
                    selected={selectedIndexCountry == key}
                    onClick={event => handleChoseCountry(event, key, item)}
                  >
                    <ListItemIcon>
                      {item.icon_flag ? (<img className="img-20-15" src={item.icon_flag}/>) : (<span></span>)}
                    </ListItemIcon>
                    <ListItemText>{item.name}</ListItemText>
                  </ListItem>
                )
              }
            )}
          </List>
        </Grid>
        <Grid item xs={12} md={12} lg={9} className="grid-comm-mobile content-sub">
          <h3 className="title-sub">My Subscriptions</h3>
          <div className="box-search">
            <InputLabel shrink htmlFor="age-native-label-placeholder">Country</InputLabel>
            <Select
              name={'roomNoFormat'}
              dataOptions={ListCountries}
              optionLabel={'name'}
              onChangeFunction={handleChoseCountryMobile}
            />
          </div>
          <TableContainer>
            {subscriptions.map((item, key) => {
              return (
                  <div className="box-table-sub" key={key}>
                    <label className="title-sub-table">{item.country_name}</label>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Sector</TableCell>
                          <TableCell align="left">Start Date</TableCell>
                          <TableCell align="left">End Date</TableCell>
                          <TableCell align="center">Auto Renew</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead> 
                      <TableBody>
                        {item.standard_products.map((row, index) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                <TableCell align="left">{row.sector ? row.sector.title : '--'}</TableCell>
                                <TableCell align="left">{getInfoDate(row.start_date)}</TableCell>
                                <TableCell align="left">{getInfoDate(row.end_date)}</TableCell>
                                <TableCell align="center">
                                  <Checkbox
                                    disabled={row.is_paid ? false : true}
                                    checked={row.auto_renew === 1}
                                    color="default"
                                    value={row.id}
                                    onChange={handleChangeAutoRenew}
                                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <Link className="link-view-data" to={routes.marketData}>View Data</Link>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </div>
                )
              }
            )}
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={12} lg={9} className="grid-comm-mobile list-item-mobile">
          <h3 className="title-sub">My Subscriptions</h3>
          <div className="box-search">
            <InputLabel shrink htmlFor="age-native-label-placeholder">Country</InputLabel>
            <Select
              name={'roomNoFormat'}
              dataOptions={ListCountries}
              optionLabel={'name'}
              onChangeFunction={handleChoseCountryMobile}
            />
          </div>
          {subscriptions.map((item, key) => {
            return (
                <div className="box-table-sub box-table-sub-mobile" key={key}>
                  <label className="title-sub-table">{item.country_name}</label>
                  {item.standard_products.map((row, index) => {
                    return (
                      <ListItem
                        key={row.id}
                        button
                      >
                        <p className="sector"><strong>{row.sector ? row.sector.title : '--'}</strong></p>
                        <p>{getInfoDate(row.start_date)} to {getInfoDate(row.end_date)}</p>
                        <p>
                          <Checkbox
                            disabled={row.is_paid ? false : true}
                            checked={row.auto_renew === 1}
                            color="default"
                            value={row.id}
                            onChange={handleChangeAutoRenew}
                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                          />Auto-renew
                        </p>
                      </ListItem>
                    )
                  })}
                </div>
              )
            }
          )}
        </Grid>
      </Grid>
    </div>
  );
}
export default SubscriptionPlan;