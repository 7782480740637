import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FeedIcon from 'asset/images/feeds.png';
import GlutenIcon from 'asset/images/gluten.png';
import ChickenIcon from 'asset/images/chicken.png';
import MacroIcon from 'asset/images/macro.png';
import Button from '@material-ui/core/Button';

const LayoutSubscribe = () => {
  const sendMail = () => {
    window.open('http://www.efeedlink.com/contact/enquiry.html', '_blank');
  }
  return(
    <React.Fragment>
      <div className="content-subscrise">
        <Grid container>
          <Grid item xs={12}>
            <Paper className="title-subscribe">Subscribe to our data</Paper>
            <Paper className="subtitle">
              SpoutINFO, in conjunction with eFeedLink, offers market data information in five categories: Feed Additives, Feed Grains, Livestock, Feed, Macro Ingredients.
            </Paper>
          </Grid>
          <Grid item xs={12} className="wrap-icons">
            <div className="ellipse">
              <div className="icon-subs">
                <img src={FeedIcon}/>
              </div>
              <Paper className="text-icon">
                Feed Additives
              </Paper>
            </div>
            <div className="ellipse">
              <div className="icon-subs">
                <img src={GlutenIcon}/>
              </div>
              <Paper className="text-icon">
                Feed Grains
              </Paper>
            </div>
            <div className="ellipse">
              <div className="icon-subs">
                <img src={FeedIcon}/>
              </div>
              <Paper className="text-icon">
                Feeds
              </Paper>
            </div>
            <div className="ellipse">
              <div className="icon-subs">
                <img src={ChickenIcon}/>
              </div>
              <Paper className="text-icon">
                Livestock
              </Paper>
            </div>
            <div className="ellipse">
              <div className="icon-subs">
                <img src={MacroIcon}/>
              </div>
              <Paper className="text-icon">
                Macro Ingredients
              </Paper>
            </div>
          </Grid>
          <Grid item xs={12} className="wrap-btn-contact">
            <Button variant="contained" onClick={sendMail} className="contactButton">
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className="wrap-faq">
        <Grid container>
          <Grid item xs={12} className="mb-pd">
            <div className="title-faq">
              FAQ
            </div>
            <div className="faq-item">
              There is a minimum <strong>3-month subscription period</strong> for each country or region
            </div>
            <div className="faq-item">
            Existing subscribers can <strong>add additional countries or regions</strong>  within the same category
            </div>
            <div className="faq-item">
            Subscription rates vary between different <strong> categories, countries and regions.</strong> 
            </div>
            <div className="faq-item">
            An enterprise subscription for five or more users is available. Contact us to learn more.
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
export default LayoutSubscribe;