import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import DataSharing from 'asset/images/data-sharing.png';
import CheckIMG from 'asset/images/check.png';
import ClockIMG from 'asset/images/clock.png';
import AnalystsIMG from 'asset/images/analysts.png';
import MobileAppIMG from 'asset/images/mobile-app.png';
import GooglePlayLogo from 'asset/images/google-play.png';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";


const About = () => {
  let history = useHistory();
  const [showMore, setShowMore] = React.useState(true);

  const TextReadMore = () => {
    return (
      <React.Fragment>
        <div className="description">
          Spout-SHARE’s unique data computing system processes input from multiple sources and filters irrelevant data.
        </div>
        <div className="description">
          Output and market commentary, if any, are assessed by analysts who will accept or reject the information. Relevant market commentary is given additional credit.
        </div>
        <div className="description">
          Data collectors and providers for Spout-SHARE are registered as data surveyors, who have to comply with our Privacy Statement and Terms of Use.
        </div>
      </React.Fragment>
    )
  }
  function handleClick() {
    history.push("/subscribe");
  }  
  return(
    <React.Fragment>
      <div className="about-page">
        <Grid container>
          <Grid item xs={12} className="intro">
            <Grid className="tileAbout">
              About SpoutINFO
            </Grid>
            <Grid className="intro-item">
            SpoutINFO is an innovative market intelligence platform that enhances data collection and provides immediate access to information beneficial for business decision making. It is a service from eFeedLink, the leading market information and content provider for the agribusiness industry.
            </Grid>
            <Grid className="intro-item mrT_30">
            SpoutINFO exploits the power of information sharing and utilizes Big Data in tandem with the analysis of domain experts in their respective fields to provide accurate, timely and independent market information for the industry.
            </Grid>
            <Grid className="intro-item mrT_30">
            ACCURATE | TIMELY | ANALYZED
            </Grid>
          </Grid>
          <Grid item xs={4} className="hide-grid-mb">
            <img src={DataSharing} className="img-data-sharing"/>
          </Grid>
          <Grid item xs={8} className="d-flex mobile-100">
            <div className="wrap-content-1">
              <div className="titile-bold">
                Spout-SHARE
              </div>
              <div className="sub-title">
                Data-sharing Concept and Logic
              </div>
              <div className="description">
              Spout-SHARE is a novel data sharing concept. A data provider can obtain information from another data provider that is registered to share on SpoutINFO. The information is from the same specific or related type of product, for the same or a nearby location.
              </div>
              <div className="description">
              Every Spout-SHARE data provider is thoroughly reviewed for their reliability and consistency in contributing accurate and prompt data. This results in improved market transparency for the industry sector that the data provider is based in.
              </div>
              {/* {showMore ? 
                TextReadMore()
              :
                null
              } */}
              {!showMore ? 
                <div className="description">
                  <Button onClick={ () => setShowMore(true) } className="read-more">Read More</Button>
                </div>
              :
                null
              }
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="wrap-content-2">
              SpoutINFO improves market transparency and generating engagement among industry stakeholders
            </div>
          </Grid>
          <Grid item xs={4} className="res-w-grid">
            <div className="icons-about">
              <img src={CheckIMG}/>
              <div className="des">
                SpoutINFO gathers data from multiple sources in the agri-food value chain, evaluating it via a unique collation tool, producing information that is reliable and undisputable.
              </div>
            </div>
          </Grid>
          <Grid item xs={4} className="res-w-grid">
            <div className="icons-about">
              <img src={ClockIMG}/>
              <div className="des">
              Through the SpoutINFO website or the dedicated mobile app, users can browse a rich source of market information made available through SpoutINFO's unique data information platforms.
              </div>
            </div>
          </Grid>
          <Grid item xs={4} className="res-w-100">
            <div className="icons-about">
              <img src={AnalystsIMG}/>
              <div className="des">
                A team of experts and analysts ensures the uncompromising quality of SpoutINFO's data that is vetted and relevant.
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="wrap-btn-subs">
              <Link className="btn-subs" to="/subscribe">Subscribe</Link>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="about-page-full">
        <Grid container>
          <Grid item xs={6} className="d-flex justy-flex-end mb-w-100 padding-left-right">
            <div className="wrap-content-3">
              <div className="title-app">
                SpoutINFO mobile app
              </div>
              <div className="des">
              Download the SpoutINFO mobile app for fast and easy access to China and key ASEAN's market data
              </div>
              <div className="clear-both"></div>
              <div className="des">
                <a href="https://play.google.com/store/apps/details?id=com.spoutinfo.spoutinfo" target="_blank" rel="noopener noreferrer"><img className="img-app" src={GooglePlayLogo} /></a>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} className="mb-w-100 padding-left-right">
            <div className="wrap-content-4">
              <img className="img-app" src={MobileAppIMG}/>
            </div>
          </Grid>
          <div className="div-color"></div>
        </Grid>
        {/* <Grid item xs={12}>
          <div className="wrap-content-5">
            <div className="title">
              Like what you’re looking at?
            </div>
            <div className="sub-title">Subscribe to get access to market prices and commentaries</div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="wrap-btn-subs">
            <Link className="btn-subs-now" to="/subscribe">Subscribe Now!</Link>
          </div>
        </Grid> */}
        <div className="layout">
          <Grid className="subscribeQuestion">
          Leverage on SpoutINFO market information
          </Grid>
          <Grid className="subscribeQuestion">
          for smart business decision making
          </Grid>
          <Grid className="subscribe viewContent">
          Subscribe and get immediate access to the latest market prices and commentaries
          </Grid>
          <Grid className="linkSubscribe">
            <Button className="subscribeButtonLink" onClick={handleClick}>Subscribe Now!</Button>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  )
}
export default About;