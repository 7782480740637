import React, {
  useEffect,
  memo
} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from 'components/SelectTag';
import SelectTagProduct from 'components/SelectTagProduct';
import {
  useSelector,
  useDispatch
} from "react-redux";
import {
  GET_LIST_COUNTRIES_MARKET_FORECAST_REQUEST
} from 'redux/reducers/Countries/ActionType';
import {
  GET_LIST_CATEGORY_MARKET_FORECAST_REQUEST
} from 'redux/reducers/Category/ActionType';
import {
  getListProductDetailAPI
} from 'services/marketForecast/getSectorDetail';
import {
  GET_LIST_MARKET_FORECAST_REQUEST,
} from 'redux/reducers/MarketForecast/ActionType';
import Analysis from 'components/Analysis';
import ArrowRight from 'asset/images/arrow_right.png';
import ArrowLeft from 'asset/images/arrow_left.png';
import ReactHtmlParser from 'react-html-parser';
import SubscribePopup from 'components/SubscribePopup';
import {
  useHistory,
  useParams
} from "react-router-dom";
import {
  routes
} from "routers/routes";
import LoadingAnalysis from 'components/LoadingAnalysis';
import moment from 'moment';

const LayoutMarketForecast = memo(() => {
      const dispatch = useDispatch();
      let history = useHistory();
      const ListCountries = useSelector(state => state.Countries.countriesMarketForecast);
      const ListCategory = useSelector(state => state.Category.categoryMarketForecast);
      const ListMarketForecast = useSelector(state => state.MarketForecast.marketForecast);
      const LoadingStatus = useSelector(state => state.Data.isLoading);
      const showLoading = useSelector(state => state.MarketForecast.showLoading);
      const { title } = useSelector(state => state.MarketForecast);
      const [dataProductDetail, setDataProductDetail] = React.useState([]);
      const [productId, setProductId] = React.useState(null);
      const [selectCategory, setSelectCategory] = React.useState(0);
      const [selectCountry, setSelectCountry] = React.useState(0);
      const [selectProduct, setSelectProduct] = React.useState(null);
      const [selectedIndexCountries, setIndexCountries] = React.useState(0);
      const [selectedIndexCategory, setIndexCategory] = React.useState(0);
      const [detailMarketForecast, setDetailMartketForecast] = React.useState(null);
      const [itemMarketForecast, setItemMartketForecast] = React.useState(null);
      const [isPaid, setIsPaid] = React.useState(false);
      const [nextWeek, setNextWeek] = React.useState(null);
      const [prevWeek, setPrevWeek] = React.useState(null);
      const [positionWeek, setPositionWeek] = React.useState(0);
      const [positionDate, setPositionDate] = React.useState(0);
      const [producDetail, setProducDetail] = React.useState([]);
      const [productLanguage, setProductLanguage] = React.useState([]);
      const deleteCookie = (cname) => {
        var d = new Date();
        d.setTime(d.getTime() - (1000 * 60 * 60 * 24));
        var expires = "expires=" + d.toGMTString();
        window.document.cookie = cname + "=" + "; " + expires;
      }
      let slug  = useParams();
      useEffect(() => {
         if(slug.sectorId && slug.countryId && slug.productId){
          document.cookie = `sector_id=${slug.sectorId}`;
          document.cookie = `country_id=${slug.countryId}`;
          document.cookie = `product_id=${slug.productId}`;
          dispatch({
            type: GET_LIST_COUNTRIES_MARKET_FORECAST_REQUEST,
          });
          dispatch({
            type: GET_LIST_CATEGORY_MARKET_FORECAST_REQUEST,
          });
         }
         else {
          return history.push(routes.marketForecast);
         }
      },[history])
      useEffect(() => {
        if (ListCategory.length && ListCountries.length) {

          const isCategory = ListCategory.find(element => element.id == slug.sectorId);
          const isCountry =  ListCountries.find(element => element.id == slug.countryId);
          if(isCountry && isCategory){
            setSelectCountry(slug.countryId || ListCountries[0].id);
            setSelectCategory(slug.sectorId || ListCategory[0].id);
          }
          else {
            return history.push(routes.marketForecast);
          }
        }
      }, [ListCategory, ListCountries]);
      useEffect(() => {
        if (producDetail.length) {
          const isProduct =  producDetail.find(element => element.id == slug.productId);
          if(isProduct){
            setProductId(+slug.productId);
          }
          else {
           history.push(`/market-forecast-detail/${slug.sectorId}/${slug.countryId}/${producDetail[0].id}`);
            setProductId(+producDetail[0].id);
          }
         
          //deleteCookie("product_id")
        }
      }, [producDetail])
      useEffect(() => {
        if (selectCountry != 0 && productId)
          dispatch({
            type: GET_LIST_MARKET_FORECAST_REQUEST,
            data: {
              country_id: selectCountry,
              product_id: productId,
            }
          });
      }, [selectCountry, productId]);
      useEffect(() => {
        if (selectProduct) {
          dispatch({
            type: GET_LIST_MARKET_FORECAST_REQUEST,
            data: {
              country_id: selectCountry,
              product_id: selectProduct.id,
            }
          });
        }
      }, [selectProduct]);
      useEffect(() => {
        if (ListMarketForecast.length) {

          let isCheck = false;
          let keyCheck = null;
          let keyRepory = null;
          ListMarketForecast.map((item, key) => {
              item.data.map((data, keyData) => {
                if(data.id == slug.reportId || data.title == title){
                  isCheck = true;
                  keyCheck = key;
                  keyRepory = keyData
                }
              })
          })
          if(isCheck){
            setPositionWeek(keyCheck);
            setPositionDate(keyRepory);
            setDetailMartketForecast(ListMarketForecast[keyCheck]);
              if (ListMarketForecast[keyCheck].data.length) {
                setItemMartketForecast(ListMarketForecast[keyCheck].data[keyRepory])
                cookingData(ListMarketForecast[keyCheck].data[keyRepory])
                history.push(`/market-forecast-detail/${slug.sectorId}/${slug.countryId}/${slug.productId}/${ListMarketForecast[keyCheck].data[keyRepory].id}`);
              } else {
                setItemMartketForecast(null)
                cookingData(null)
              }
              if (ListMarketForecast[keyCheck].data[keyRepory+1]) {
                setPrevWeek(ListMarketForecast[keyCheck].data[keyRepory+1]);
              } else {
                if (ListMarketForecast[keyCheck+1]) {
                  setPrevWeek(ListMarketForecast[keyCheck+1].data[0]);
                } else {
                  setPrevWeek(null);
                }
              }
              if(ListMarketForecast[keyCheck].data[keyRepory-1]){
                setNextWeek(ListMarketForecast[keyCheck].data[keyRepory-1])
              }
              else {
                if(ListMarketForecast[keyCheck-1]){
                  setNextWeek(ListMarketForecast[keyCheck-1].data[ListMarketForecast[keyCheck-1].data.length-1])
                }
                else{
                  setNextWeek(null)
                }
              }
          } 
          else{ 
            if(slug.reportId){
              return history.push(routes.marketForecast); 
            }
            else {
              history.push(`/market-forecast-detail/${slug.sectorId}/${slug.countryId}/${slug.productId}/${ListMarketForecast[0].data[0].id}`);
                setDetailMartketForecast(ListMarketForecast[0]);
                if (ListMarketForecast[0].data.length) {
                  setItemMartketForecast(ListMarketForecast[0].data[0])
                  cookingData(ListMarketForecast[0].data[0])
                } else {
                  setItemMartketForecast(null)
                  cookingData(null)
                }
                if (ListMarketForecast[0].data.length > 1) {
                  setPrevWeek(ListMarketForecast[0].data[1]);
                } else {
                  if (ListMarketForecast[1]) {
                    setPrevWeek(ListMarketForecast[1].data[0]);
                  } else {
                    setPrevWeek(null);
                  }
                }

            }
          }        
          
        } else {
          setProductLanguage([]);
          setDetailMartketForecast(null);
          setPrevWeek(null);
          setNextWeek(null);
          setItemMartketForecast(null)
          cookingData(null)
        }
      }, [ListMarketForecast]);
      useEffect(() => {
        if (selectCategory && selectCountry) {
          setIndexCountries(selectCountry);
          setIndexCategory(selectCategory);
          if(!Object.keys(dataProductDetail).length)
            getListProductDetail()
        }
      }, [selectCategory, selectCountry]);
      const getListProductDetail = async () => {
        let idSector = selectCategory;
        const sectorDetail = await getListProductDetailAPI();
          setDataProductDetail(sectorDetail.data.sector_data);
          if (sectorDetail.data.sector_data[idSector]) {
            const childrenSector = sectorDetail.data.sector_data[idSector].children;
            const isProduct =  childrenSector.find(element => element.id == slug.productId);
            if(isProduct){
              setProducDetail(childrenSector);
            }
            else{
              if(slug.productId){
                setProducDetail(childrenSector);
              }
              else{
                return history.push(routes.marketForecast); 
              }
      
            }
           
          }else {
            //return history.push(routes.subscribe);
            setIsPaid(true); 
          }
   
      }
      const selectSector = (e) => {
        history.push(`/market-forecast-detail/${slug.sectorId}/${slug.countryId}/${e.id}`);
        setSelectProduct(e);
      }
      const handleChoseCategory = async (index, item) => {
        document.cookie = `sector_id=${item.id}`;
        setSelectCategory(item.id);
        setIndexCategory(item.id);
        setProducDetail([])
        history.push(`/market-forecast-detail/${item.id}/${slug.countryId}/${0}`);
        if (Object.keys(dataProductDetail).length && !dataProductDetail[item.id]) {
           //return history.push(routes.subscribe);
           setIsPaid(true);
        }
        if (dataProductDetail[item.id]) {
          const childrenSector = dataProductDetail[item.id].children;
          setProducDetail(childrenSector);
        }
      };
      const handleChoseCountries = async (index, item) => {
        document.cookie = `country_id=${item.id}`;
        setIndexCountries(item.id);
        setSelectCountry(item.id)
        history.push(`/market-forecast-detail/${slug.sectorId}/${item.id}/${slug.productId}`);
        dispatch({
          type: GET_LIST_MARKET_FORECAST_REQUEST,
          data: {
            country_id: item.id,
            product_id: productId,
          }
        });
      };
      const cookingData = (dataProducts) => {
        let productLanguageNew = [];
        if (dataProducts) {
          dataProducts.translations.map(language => {
            if (language.title) {
              if (!productLanguageNew.some(data => data.id == language.locale)) {
                let newLanguage = {
                  id: language.locale
                };
                switch (language.locale) {
                  case "id":
                    newLanguage.name = "Indonesia"
                    break;
                  case "th":
                    newLanguage.name = "ThaiLand"
                    break;
                  case "vi":
                    newLanguage.name = "Vietnamese"
                    break;
                  case "zh":
                    newLanguage.name = "Chinese"
                    break;
                  case "en":
                    newLanguage.name = "English"
                    break;
                }
                productLanguageNew.push(newLanguage)
              }
            }
          })
        }
        setProductLanguage(productLanguageNew);
      }
      const prevDate = () => {
        if (positionWeek == ListMarketForecast.length - 1) {
          return;
        }
        cookingData(ListMarketForecast[positionWeek + 1].data[0])
        setDetailMartketForecast(ListMarketForecast[positionWeek + 1]);
        setItemMartketForecast(ListMarketForecast[positionWeek + 1].data[0])
        history.push(`/market-forecast-detail/${slug.sectorId}/${slug.countryId}/${slug.productId}/${ListMarketForecast[positionWeek + 1].data[0].id}`);
        setPositionDate(0);
        if (ListMarketForecast[positionWeek + 1].data.length > 1) {

          setPrevWeek(ListMarketForecast[positionWeek + 1].data[1])
        } else {
          if (ListMarketForecast[positionWeek + 2]) {
            setPrevWeek(ListMarketForecast[positionWeek + 2].data[0])
          } else
            setPrevWeek(null);
        }
        setNextWeek(ListMarketForecast[positionWeek].data[ListMarketForecast[positionWeek].data.length - 1])
        setPositionWeek(positionWeek + 1);
      }
      const nextDate = () => {
        if (positionWeek == 0) return;
        cookingData(ListMarketForecast[positionWeek - 1].data[0])
        setDetailMartketForecast(ListMarketForecast[positionWeek - 1]);
        setItemMartketForecast(ListMarketForecast[positionWeek - 1].data[ListMarketForecast[positionWeek - 1].data.length - 1])
        history.push(`/market-forecast-detail/${slug.sectorId}/${slug.countryId}/${slug.productId}/${ListMarketForecast[positionWeek - 1].data[ListMarketForecast[positionWeek - 1].data.length - 1].id}`);
        if (ListMarketForecast[positionWeek - 1].data.length > 1) {
          setNextWeek(ListMarketForecast[positionWeek - 1].data[1])
          setPositionDate(ListMarketForecast[positionWeek - 1].data.length - 1);
        } else {
          if (ListMarketForecast[positionWeek - 2]) {
            setNextWeek(ListMarketForecast[positionWeek - 2].data[ListMarketForecast[positionWeek - 2].data.length - 1])
            setPositionDate(ListMarketForecast[positionWeek - 2].data.length - 1);
          } else {
            setPositionDate(0)
            setNextWeek(null)
          }
        }
        setPrevWeek(ListMarketForecast[positionWeek].data[0])
        setPositionWeek(positionWeek - 1);
      }
      const latestData = () => {
        if (ListMarketForecast.length) {
          setPositionWeek(0);
          setPositionDate(0);
          setNextWeek(null);
          
          setDetailMartketForecast(ListMarketForecast[0]);
          if (ListMarketForecast[0].data.length) {
            history.push(`/market-forecast-detail/${slug.sectorId}/${slug.countryId}/${slug.productId}/${ListMarketForecast[0].data[0].id}`);
            setItemMartketForecast(ListMarketForecast[0].data[0])
            cookingData(ListMarketForecast[0].data[0])
          } else {
            setItemMartketForecast(null)
            cookingData(null)
          }
          if (ListMarketForecast[0].data.length > 1) {
            setPrevWeek(ListMarketForecast[0].data[1]);
          } else {
            if (ListMarketForecast[1]) {
              setPrevWeek(ListMarketForecast[1].data[0]);
            } else {
              setPrevWeek(null);
            }
          }
        } else {
         history.push(`/market-forecast-detail/${slug.sectorId}/${slug.countryId}/${slug.productId}`);
          setProductLanguage([]);
          setDetailMartketForecast(null);
          setPrevWeek(null);
          setNextWeek(null);
          setItemMartketForecast(null)
          cookingData(null)
        }
      }
      const getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
      }
      const changeLanguage = (language) => {
        let detailData = Object.assign({}, detailMarketForecast);
        let newData = [];
        ListMarketForecast[positionWeek].data.map(item => {
          item.translations.map(lang => {
            if (lang.locale == language.id && lang.title) {
              item.title = lang.title;
              item.content = lang.content;
              newData.push(item);
            }
          })
        })
        detailData.data = newData;
        setDetailMartketForecast(detailData);
      }
      const prevDateNew = () => {
        history.push(`/market-forecast-detail/${slug.sectorId}/${slug.countryId}/${slug.productId}/${prevWeek.id}`);
        let countReport = ListMarketForecast[positionWeek].data.length - 1;
        if (countReport > positionDate) {
          setItemMartketForecast(ListMarketForecast[positionWeek].data[positionDate + 1])
          setNextWeek(ListMarketForecast[positionWeek].data[positionDate])
          if (countReport == positionDate + 1) {
            if (ListMarketForecast[positionWeek + 1]) {
              setPrevWeek(ListMarketForecast[positionWeek + 1].data[0])
            } else {
              setPrevWeek(null)
            }
          } else
            setPrevWeek(ListMarketForecast[positionWeek].data[positionDate + 2])
          setPositionDate(positionDate + 1);
        } else {
          setPositionDate(0);
          prevDate()
        }
      }
      const nextDateNew = () => {
  
        history.push(`/market-forecast-detail/${slug.sectorId}/${slug.countryId}/${slug.productId}/${nextWeek.id}`);
        let countReport = ListMarketForecast[positionWeek].data.length - 1;
        if (countReport >= positionDate) {
          if (positionDate == 0) {
            nextDate()
          } else {
            setItemMartketForecast(ListMarketForecast[positionWeek].data[positionDate - 1])
            if (ListMarketForecast[positionWeek].data[positionDate - 2]) {
              setNextWeek(ListMarketForecast[positionWeek].data[positionDate - 2])
            } else {
              if (ListMarketForecast[positionWeek - 1]) {
                setNextWeek(ListMarketForecast[positionWeek - 1].data[ListMarketForecast[positionWeek - 1].data.length - 1])
              } else {
                setNextWeek(null)
              }
            }
            setPrevWeek(ListMarketForecast[positionWeek].data[positionDate])
            setPositionDate(positionDate - 1);
          }
        } else {
          nextDate()
        }
      }
      const updateIsPaid = (status) => {
        setIsPaid(status)
      }
  return(
    <>
      <div className="category-and-countries">
        <Grid container>
          <Grid item xs={12} className="category">
            {ListCategory.map((item,key) => {
              return(
                <Button
                  className={`card btn-select-category ${selectedIndexCategory == item.id ? 'active' : ''}` }
                  key={key}
                  onClick={() => handleChoseCategory(key, item)}
                >
                  {item.title}
                </Button>
              );
            })}
          </Grid>
          <Grid item xs={12} className="countries-menu">
            {ListCountries.map((item, key) => {
              return (
                <Button
                  className={`btn-select-countries ${selectedIndexCountries == item.id ? 'active' : ''}` }
                  key={key}
                  onClick={()=> handleChoseCountries(key, item)}
                >
                  {item.name}
                </Button>
              )
            })}
          </Grid>
        </Grid>
      </div>
      <div className="wrap-market-forecast">
        <Grid container>
          <Grid item lg={9} sm={8} className="wrap-content-1">
            <div className="filter">
              <div className="product-select">
                <SelectTagProduct
                  required
                  name={'roomNoFormat'}
                  inputContainerClass={"select-product"}
                  dataOptions={producDetail}
                  optionLabel={'title'}
                  value={productId}
                  onChangeFunction={selectSector}
                />
              </div>
              <div className="date-select">
                <div onClick={prevDate} className="arrow-left">
                  <img src={ArrowLeft}/>
                </div>
                <span className="date-data">{detailMarketForecast ? detailMarketForecast.name : '-'}</span>
                <div onClick={nextDate} className="arrow-right">
                  <img src={ArrowRight}/>
                </div>
              </div>
              <div className="latest">
                <Button onClick={latestData} className="btn-latest">
                  Latest
                </Button>
              </div>
              <div className="language-select">
                <Select
                  required
                  name={'roomNoFormat'}
                  inputContainerClass={"select-language"}
                  optionLabel={'name'}
                  onChangeFunction={changeLanguage}
                  dataOptions={productLanguage}
                />
              </div>
            </div>
            <div className="clear-both"></div>
              {!itemMarketForecast && !showLoading ?
                <Grid className="text-no-data" container justify="center" alignItems="center">
                  No data for this market forecast
                </Grid>
              :
                null
              }
              {showLoading &&
                <LoadingAnalysis/>
              }
              {itemMarketForecast &&
                <Grid className="wrap-data" >
                  <div className="content">
                    <div className="title">
                      {itemMarketForecast.title}
                    </div>
                    <div className="content-data">{ ReactHtmlParser(itemMarketForecast.content) }</div>
                  </div>
                </Grid> 
              }
              <Grid className="nex-prev-mobile">
                { prevWeek || nextWeek ?
                  <Grid className="group-next-prev">
                    {prevWeek ?
                      <Button onClick={prevDateNew} className="btn-prev">
                        Previous ({ prevWeek && moment(prevWeek.created_at, 'YYYY-MM-DD').format('YYYY-MM-DD')})
                        <img className="arrow-left" src={ArrowLeft}/>
                      </Button>
                    :
                    <Button className="btn-prev fuzzy">
                      This report is oldest
                      <img className="arrow-left" src={ArrowLeft}/>
                    </Button>
                    }
                  {nextWeek ? 
                    <Button onClick={nextDateNew} className="btn-next ">
                      Next ({ nextWeek && moment(nextWeek.created_at, 'YYYY-MM-DD').format('YYYY-MM-DD') })
                      <img className="arrow-right" src={ArrowRight}/>
                    </Button>
                  :
                  <Button className="btn-next fuzzy">
                      This report is latest
                      <img className="arrow-right" src={ArrowRight}/>
                    </Button>
                  }
                  </Grid>
                :
                  null
                }
              </Grid>
          </Grid>
          <Grid item lg={3} sm={4} className="wrap-content-2">
            <Analysis
              idSector={selectCategory}
              idCountry={selectCountry}
            />
            {LoadingStatus ? 
              <LoadingAnalysis/>
            :
              null
            }
          </Grid>
          <Grid item lg={9} sm={8} className="wrap-content-3">
            { prevWeek || nextWeek ?
              <Grid className="group-next-prev">
                {prevWeek ?
                  <Button onClick={prevDateNew} className="btn-prev">
                    Previous ({ prevWeek && moment(prevWeek.created_at, 'YYYY-MM-DD').format('YYYY-MM-DD') })
                    <img className="arrow-left" src={ArrowLeft}/>
                  </Button>
                :
                <Button className="btn-prev fuzzy">
                  This report is oldest
                  <img className="arrow-left" src={ArrowLeft}/>
                </Button>
                }
                {nextWeek ?
                  <Button onClick={nextDateNew} className="btn-next ">
                    Next ({ nextWeek && moment(nextWeek.created_at, 'YYYY-MM-DD').format('YYYY-MM-DD') })
                    <img className="arrow-right" src={ArrowRight}/>
                  </Button>
                :
                <Button className="btn-next fuzzy">
                    This report is latest
                    <img className="arrow-right" src={ArrowRight}/>
                  </Button>
                }
              </Grid>
            :
              null
            }
          </Grid>
        </Grid>
        {isPaid ?  <SubscribePopup changeHandler={updateIsPaid}/> : null}
      </div>
    </>
  )
})
export default LayoutMarketForecast;