import React, { useState} from 'react';
import Select from 'react-select';
import styles from './styles';
import { customStyles } from './components/customStyle';
import Label from './components/Label';
import InputContainer from './components/InputContainer';
import { useTheme } from '@material-ui/core/styles';

const SelectTagProduct = (props) => {
  let {
    id,
    label,
    placeholder,
    value,
    dataOptions,
    isSearchable,
    onChangeFunction = () => null,
    name,
    inputContainerClass,
    customControl,
    customOption,
    fullWidth,
    isClearable,
    disabled,
    optionLabel
  } = props;
  customControl = customControl || {};
  customOption = customOption || {};
  const theme = useTheme();
  const classes = styles();
  if(value){
    value = dataOptions.find(item => item.id == value);
  }else{
    value = dataOptions[0];
  }
  const [stateValue, setStateValue] = useState(value);
  const [stateOptions, setStateOptions] = useState(dataOptions);
  const onChange = val => {
    if (typeof onChangeFunction !== 'function') return null;
    setStateValue(val);
    onChangeFunction(val);
  };
  React.useEffect(() => {
    if (name === 'projectName') {
      setStateOptions(dataOptions);
    }
  }, [dataOptions]);
  React.useEffect(() => {
    if(value){
      if(typeof value == "number") {
        value = dataOptions.find(item => item.id == value);
      }
      else 
      {
        value = dataOptions.find(item => item.id == value.id);
      }
    }else{
      value = dataOptions[0];
    }
    setStateValue(value);
  }, [value]);
  React.useEffect(() => {
    setStateOptions(dataOptions);
  }, [dataOptions]);
  return (
    <React.Fragment>
      <Label className={classes.labelSelect}>
        {label}
      </Label>
      <InputContainer
        id={name}
        className={inputContainerClass}
      >
      <Select
        name={name}
        onChange={onChange}
        styles={customStyles(customControl, customOption, theme)}
        placeholder={placeholder}
        options={stateOptions}
        getOptionValue={option => `${option}`}
        value={stateValue || ''}
        fullWidth={fullWidth}
        getOptionLabel={option =>`${option[optionLabel ? optionLabel : 'name']}`}
        isSearchable={isSearchable !== undefined ? isSearchable : true}
        isDisabled={disabled}
        components={{
          IndicatorSeparator: () => null,
        }}
        isClearable={Boolean(isClearable)}
        inputId={id}
      />
      </InputContainer>
    </React.Fragment>
  );
};
export default SelectTagProduct;