import axios from 'services/configApi';

export const forgetPassSubmited = async (data) => {
  try {
    var formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    }); 
    const res = await axios.post('api/en/data-search/auth/forgot-password',formData);
   
    return res.data.data;
    
  } catch (error) {

    return Promise.reject(error);

  }
};
