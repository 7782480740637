import axios from 'services/configApi';
const locale = 'en';

export const updateProfileUser = async (data) => {
  try {
    // var formData = new FormData();
    // Object.keys(data).forEach(key => {
    //   formData.append(key, data[key]);
    // });  
    const res = await axios(`api/${locale}/data-search/profile`,{
      method: "POST",
      data : data,
      headers: {
        Accept: "application/json"
      },
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
