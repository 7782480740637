import React, { memo, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Link } from 'react-router-dom';
import Web_logo_1 from 'asset/img/Web-logo-1.png';
import Web_logo_2 from 'asset/img/Web-logo-2.png';
import Web_logo_1_white from 'asset/img/Web-logo-1-white.png';
import Web_logo_2_white from 'asset/img/Web-logo-2-white.png';
import Flag from 'asset/images/flag.png';
import FlagVN from 'asset/images/vn.png';
import FlagCN from 'asset/images/cn.png';
import FlagPH from 'asset/images/ph.png';
import FlagTH from 'asset/images/th.png';
import FlagID from 'asset/images/id.png';
import FlagMY from 'asset/images/my.png';
import FlagUS from 'asset/images/us.png';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import { routes } from 'routers/routes';
import WordIcon from 'asset/images/lang_defaut.png';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
// import Loading from "components/loading"
import Cookie from "components/Cookie"
import { 
  GET_PROFILE_REQUEST,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_REDUCER,
  USER_UPDATE_PROFILE_SENDOTP } from 'redux/reducers/User/actionTypes';

const Header = memo((props) => {
  let history = useHistory();
  
  const isLogin = useSelector(state => state.User.islogin);
  const tokenExpired = useSelector(state => state.User.tokenExpired);
  const user_login = useSelector(state => state.User.user_login);
   
  const dispatch = useDispatch();
  useEffect(() => {
    if(!Object.keys(user_login).length || typeof user_login.users_subscriber_profiles == "undefined")
      dispatch({
        type: GET_PROFILE_REQUEST
      });
  })
  useEffect(() => {
    if(tokenExpired){
      Swal.fire({
        title: 'ERROR',
        text: "Your account is logged in on another device",
        icon: 'warning',
        confirmButtonText: "OK",
        }
      ).then(
        function (isConfirm) {
          if (isConfirm) {
            dispatch({
              type: USER_LOGOUT_REDUCER
            });
           history.push(routes.login);
          }
        },
        
      );
    }
  },[tokenExpired])
  const [toggleLanguage, setToggleLanguage] = React.useState(false);
  const redirectLogin = () => {
    history.push(routes.login);
  }
  const redirectSubscribe = () => {
    history.push(routes.subscribe);
  }
  const redirectProfile = () => {
    dispatch({
      type: USER_UPDATE_PROFILE_SENDOTP,
      OTP: {
        verify_otp: false,
        country_code: '',
        phone_number: '',
        formData: null
      }
    })
    history.push(routes.userProfile);
  }
  const [selectLanguage, setLanguage] = React.useState('1');
  const handleChange = (event) => {
    setLanguage(event.target.value);
  }
  const toggleLanguageMobile = () => {
    setToggleLanguage(!toggleLanguage);
  }
  const [state, setState] = React.useState({
    toggleMenu: false,
  });
  const toggleMenuMobile = (toggle, open) => event => {
    setState({ ...state, [toggle]: open });
  };
  const handleLogout = () => {
    dispatch({
      type: USER_LOGOUT_REQUEST
    });
  }
  const fullMenu = (side) => (
    <div
      className="fullList"
      role="presentation"
    >
      <Grid container>
        <Grid item xs={12}>
          <Button onClick={toggleMenuMobile(side, false)} className="close-menu">x</Button>
        </Grid>
        <Grid item xs={12} className="text-center logo-menu-mobile">
          <div>
            <img src={Web_logo_1_white}/>
          </div>
          <div className="logo-mobile">
            <img src={Web_logo_2_white}/>
          </div>
        </Grid>
        {isLogin ? 
          (
            <Grid item xs={12} className="text-center">
              <div className="header-link-mobile">
                <Link className={`child-link ${history.location.pathname == routes.dashboard ? 'active' : ''}`} to={routes.dashboard}>Home</Link>
              </div>
              <div className="header-link-mobile">
                <Link className={`child-link ${history.location.pathname == routes.marketData ? 'active' : ''}`} to={routes.marketData}>Market Data</Link>
              </div>
              <div className="header-link-mobile">
                <Link className={`child-link ${history.location.pathname == routes.marketForecast ? 'active' : ''}`} to={routes.marketForecast}>Market Forecast & Analysis</Link>
              </div>
              <div className="header-link-mobile">
                <Link className={`child-link ${history.location.pathname == routes.userProfile ? 'active' : ''}`} to={routes.userProfile}>Account</Link>
              </div>
              <div className="header-link-mobile">
                <Link className={`child-link ${history.location.pathname == routes.subscriptionPlan ? 'active' : ''}`} to={routes.subscriptionPlan}>My Subscriptions</Link>
              </div>
              <div className="header-link-mobile">
                <Link className={`child-link ${history.location.pathname == routes.about ? 'active' : ''}`} to={routes.about}>About Us</Link>
              </div>
              <div className="header-link-mobile">
                <Link className="child-link" to="#" onClick={handleLogout}>Logout</Link>
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} className="text-center">
              <div className="header-link-mobile">
                <Link className={`child-link ${history.location.pathname == routes.dashboard ? 'active' : ''}`} to={routes.dashboard}>Home</Link>
              </div>
              <div className="header-link-mobile">
                <Link className={`child-link ${history.location.pathname == routes.marketComm ? 'active' : ''}`} to={routes.marketComm}>Market Commentaries</Link>
              </div>
              <div className="header-link-mobile">
                <Link className={`child-link ${history.location.pathname == routes.about ? 'active' : ''}`} to={routes.about}>About Us</Link>
              </div>
            </Grid>
          )
        }
        <Grid item xs={12} className="text-center d-select">
          {!isLogin ?
            (<>
              <Button
                variant="contained"
                className="subscribeButtonMobile"
                onClick={() => redirectSubscribe()}
              >
                Subscribe
              </Button>
              <Button
                variant="contained"
                className="loginButtonMobile"
                onClick={() => redirectLogin()}
              >
                Login
              </Button>
            </>)
            : ''
          }
          {/*<div className={`select-lang-mobile ${isLogin ? 'select-lang-mobile-logged' : ''}`}>
            <Button
              variant="contained"
              className="selectLanguage"
              onClick={() => toggleLanguageMobile()}
            >
              <div className="icons-flag">
                <img className="icon-item" src={WordIcon} />
              </div>
              ENG
            </Button>
          </div>*/}
          <div>
            <List
              component="nav"
              className={`list-language-mobile ${toggleLanguage ? 'show' : 'hide' }`}
            >
              <ListItem button className="item-lang-mobile">
                <ListItemIcon>
                  <img className="img-20-15" className="filter-img" src={WordIcon} />
                </ListItemIcon>
                <ListItemText>
                  International (ENG)
                </ListItemText>
              </ListItem>
              <ListItem button className="item-lang-mobile">
                <ListItemIcon>
                  <img className="img-20-15" src={FlagCN}/>
                </ListItemIcon>
                <ListItemText>
                  China
                </ListItemText>
              </ListItem>
              <ListItem button className="item-lang-mobile">
                <ListItemIcon>
                  <img className="img-20-15" src={FlagUS}/>
                </ListItemIcon>
                <ListItemText>
                  USA
                </ListItemText>
              </ListItem>
              <ListItem button className="item-lang-mobile">
                <ListItemIcon>
                  <img className="img-20-15" src={FlagMY}/>
                </ListItemIcon>
                <ListItemText>
                  Malaysia
                </ListItemText>
              </ListItem>
              <ListItem button className="item-lang-mobile">
                <ListItemIcon>
                  <img className="img-20-15" src={FlagTH}/>
                </ListItemIcon>
                <ListItemText>
                  Thailand
                </ListItemText>
              </ListItem>
              <ListItem button className="item-lang-mobile">
                <ListItemIcon>
                  <img className="img-20-15" src={FlagID}/>
                </ListItemIcon>
                <ListItemText>
                  Indonesia
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </Grid>
        <Grid item xs={12}>
          
        </Grid>
      </Grid>
    </div>
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const [setIsShow, setShowLang] = React.useState(null);
  const handleClickMobile = event => {
    setShowLang(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Cookie/>
      <div className="root nav-bar">
        {/* <Loading /> */}
          <AppBar position="static">
            <Toolbar >
              <Grid container>
                <Grid item xl={3} lgd={6} xs className="rps-logo">
                <Typography className="d-f={3} mlex" variant="h6" noWrap>
                  <a className="logo logo1" href="http://www.efeedlink.com" target="_blank" rel="noopener noreferrer"><img src={Web_logo_1} width="116" height="34"/></a>
                  <Link to="/" className="logo logo2">
                    <img src={Web_logo_2} width="160" height="36"/>
                  </Link>
                  </Typography>
                </Grid>
                <Grid item lg={isLogin ? 6 : 4} xs className="flex-menu menu-xs-2">
                  { isLogin ? 
                    <>
                      <Link className="header-link" to={routes.marketForecast}>
                        <div className={`child-link ${history.location.pathname == routes.marketForecast ? 'active' : ''}`}>Market Forecast & Analysis</div>
                      </Link>
                      <Link className="header-link" to={routes.marketData}>
                        <div className={`child-link ${history.location.pathname == routes.marketData ? 'active' : ''}`}>Market Data</div>
                      </Link>
                      <Link className="header-link" to={routes.subscriptionPlan}>
                        <div className={`child-link ${history.location.pathname == routes.subscriptionPlan ? 'active' : ''}`}>My Subscription</div>
                      </Link>
                    </>
                  :
                    <Link className="header-link" to={routes.marketComm}>
                      <div className={`child-link ${history.location.pathname == routes.marketComm ? 'active' : ''}`}>Market Commentary</div>
                    </Link>
                  }
                  <Link className="header-link" to={routes.about}>
                    <div className={`child-link ${history.location.pathname == routes.about ? 'active' : ''}`}>About Us</div>
                  </Link>
                </Grid>
                <Grid item xs className="text-right menu-xs-3">
                  { isLogin ? 
                    <Button
                      variant="contained"
                      className="accountButton mt-4"
                      onClick={redirectProfile}
                    >
                      Account
                    </Button>
                  :
                    <>
                      <Button
                        variant="contained"
                        className="subscribeButton mt-4"
                        onClick={() => redirectSubscribe()}
                      >
                        Subscribe
                      </Button>
                      <Button
                        variant="contained"
                        className="loginButton mt-4"
                        onClick={() => redirectLogin()}
                      >
                        Login
                      </Button>
                    </>
                  }
                  {/*<Button aria-describedby={id} variant="contained" className="selectLanguage" onClick={handleClick}>
                    <div className="icons-flag">
                      <img className="icon-item" src={WordIcon} />
                    </div>
                    ENG
                  </Button>*/}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <List component="nav" className="list-language">
                      <ListItem button>
                        <ListItemIcon>
                          <img src={WordIcon} />
                        </ListItemIcon>
                        <ListItemText>
                          International (ENG)
                        </ListItemText>
                      </ListItem>
                      <ListItem button>
                        <ListItemIcon>
                          <img className="img-20-15" src={FlagCN}/>
                        </ListItemIcon>
                        <ListItemText>
                          China
                        </ListItemText>
                      </ListItem>
                      <ListItem button>
                        <ListItemIcon>
                          <img className="img-20-15" src={FlagUS}/>
                        </ListItemIcon>
                        <ListItemText>
                          USA
                        </ListItemText>
                      </ListItem>
                      <ListItem button>
                        <ListItemIcon>
                          <img className="img-20-15" src={FlagMY}/>
                        </ListItemIcon>
                        <ListItemText>
                          Malaysia
                        </ListItemText>
                      </ListItem>
                      <ListItem button>
                        <ListItemIcon>
                          <img className="img-20-15" src={FlagTH}/>
                        </ListItemIcon>
                        <ListItemText>
                          Thailand
                        </ListItemText>
                      </ListItem>
                      <ListItem button>
                        <ListItemIcon>
                          <img className="img-20-15" src={FlagID}/>
                        </ListItemIcon>
                        <ListItemText>
                          Indonesia
                        </ListItemText>
                      </ListItem>
                    </List>
                  </Popover>
                  <div className="menu-mobile">
                    <Link className="link-home" to="/">{props.breadcrumb}</Link>
                    <MenuIcon onClick={toggleMenuMobile('toggleMenu', true)} className="icons-menu"/>
                  </div>
                </Grid>
              </Grid>
              <Grid item>
                <SwipeableDrawer
                  anchor="top"
                  open={state.toggleMenu}
                  onClose={toggleMenuMobile('toggleMenu', false)}
                  onOpen={toggleMenuMobile('toggleMenu', true)}
                >
                  {fullMenu('toggleMenu')}
                </SwipeableDrawer>
              </Grid>
            </Toolbar>
          </AppBar>
        </div>
    </>
  );
})
export default Header;
  