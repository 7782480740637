import axios from 'services/configApi';
const locale = 'en';

export const getDataPriceHistory = async (prms) => {
  try {
    const result = await axios(`api/${locale}/data-search/subscribed-item/price-history?`, { 
        method: "GET",
        params: prms,
        headers: {
          Accept: "application/json",
        },
      })
    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
