import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { useRouteMatch,useHistory } from 'react-router-dom';
const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid white"
  },
  indicator: {
    backgroundColor: "white"
  }
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    margin: '0 0 0 2%',
    textTransform: "none",
    maxWidth: 'unset',
    display:'block',
    whiteSpace:'nowrap',
    letterSpacing: 0,
    // marginRight: theme.spacing(4),
    lineHeight: "48px",
    fontSize: "35px",
    fontWeight: "300",
    fontFamily: ["Open Sans"].join(","),
    "&:hover": {
      color: "#999999",
      opacity: 1
    },
    "&$selected": {
      color: "black",
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 0
    },
    "&:focus": {
      color: "black"
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
    color: "#999999"
  },
  demo2: {
    backgroundColor: "#2e1534"
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

export default function CustomizedTabs() {
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const match = useRouteMatch();
  useEffect(()=>{
    if(match.path =='/terms-of-use'){
      setValue(0);
    }
    else{
      setValue(1);
    }
  },[match])

  const sendMail = () => {
    const link = document.createElement('a');
    link.href = `mailto:admin@spoutinfo.com?subject=SpoutINFO - Individual Subscription`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const changesTab = (id) => {
    setValue(id);
  }
  const handleChange = (event, newValue) => {
    if(newValue){
      history.push('/privacy-statement')
    }
    else
    {
      history.push('/terms-of-use')
    }
    setValue(newValue);
  };

  return (
    <div className="terms">
      <div className={classes.root}>
        <div className={classes.demo1}>
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
          >
            <AntTab  label="Terms of Use" />
            <AntTab label="Privacy Statement" />
          </AntTabs>
          <Typography />
        </div>
        <TabPanel value={value} index={0}>
          <div className="contentTerm">
            <p>
              This Agreement is between User and SpoutINFO Pte Ltd, a company
              incorporated in Singapore with the registered address at 10 Ubi
              Crescent, #05-87, Ubi Techpark, Singapore 408564. The SpoutINFO
              Websites and APPs are offered to you conditioned on your
              acceptance without modification of the terms, conditions, and
              notices contained herein. Your use of the SpoutINFO Website
              constitutes your agreement to all such terms, conditions, and
              notices. We reserve the right to amend this agreement at our
              discretion and you are responsible for regularly reviewing these
              terms and conditions and additional terms posted on this Website.
              Your continued use of the services provided by the SpoutINFO
              Websites and APPS constitutes your agreement to all such terms,
              conditions, and notices.
            </p>
            <p className="title">
              1. Privacy and Protection of Privacy Privacy
            </p>
            <p>
              Privacy Statement for disclosures relating to the collection and
              use of your information.
            </p>
            <p className="title">2. Scope of Services </p>
            <p>
              2.1 Our services are available only to individuals and entities
              who can form legally binding contracts under the applicable law.
              Without limiting the foregoing, our services are not available to
              minors. If you do not qualify, you shall not use the services
              provided in the Websites and Apps.
            </p>
            <p>2.2 SpoutINFO Pte Ltd. may terminate this agreement at any time without notice for any reason and may immediately issue a warning, temporarily suspend, indefinitely suspend or terminate your use of the services of the Websites and APPS and any other information you place if you breach this Agreement.</p>
            <p className="title">3. Users Obligations</p>
            <p>3.1 You are responsible for paying all applicable taxes and for all hardware, software, service and other costs you incur to buy or sell under this Agreement.</p>
            <p>3.2 You may not use any device, software or routine to interfere or attempt to interfere with the proper working of the sites and Apps. You may not take any action, which imposes an unreasonable or disproportionately large load on our infrastructure. You may not disclose or share your password with any third parties or use your password for any unauthorized purpose. Your Information may not contain any viruses, Trojan horses, worms, time bombs, cancelbots or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information.</p>
            <p>3.3 You shall comply with all applicable laws, statutes, ordinance and regulations regarding your use of our servicez.</p>
            <p className="title">4. User Submissions</p>
            <p>Your information includes any information you provide to us or other users during the use of our services, in any public message area or through any e-mail feature ("Your Information"). You are solely responsible for Your Information, and we act as a passive conduit for your online distribution and publication of Your Information. Your Information:</p>
            <p>(a) shall not be fraudulent or involve the sale of counterfeit or stolen items;</p>
            <p>(b) shall not infringe any third party's copyright, patent, trademark, trade secret, confidential company information or other proprietary rights or rights of publicity or privacy;</p>  
            <p>(c) shall not violate any law, statute, ordinance or regulation (including without limitation those governing export control, consumer protection, unfair competition, anti-discrimination or false advertising);</p>
            <p>(d) shall not be defamatory, trade libelous, unlawfully threatening or unlawfully harassing;</p>
            <p>(e) shall not be politically sensitive information, including but not limited to information that damages State interests or discloses State secrets;</p> 
            <p>(f) shall not be obscene or contain child pornography. Any information, including but not limited to remarks, suggestions, ideas, graphics or other submissions, communicated to SpoutINFO or its affiliates through this site is not confidential. SpoutINFO or its affiliates shall be entitled to reproduce, distribute or use any such information submitted for any purpose without compensation to the person sending the submission. The user acknowledges the originality of any submission communicated to SpoutINFO or its affiliates and accepts responsibility for its accuracy, appropriateness and legality.</p>
            <p className="title">5. Protocol and Regulations of Submitting Commentary in Spout-SHARE Usage</p>
            <p>5.1 Please post your comments in a professional and polite manner. If a comment is deemed as inappropriate, SpoutINFO reserves the right to remove the post without assigning any reason.</p>
            <p>5.1.1 Do not put others down or using offensive language in your comment. This includes any and all words that can cause embarrassment, unwanted attention or creating discomfort to others.</p>
            <p>5.1.2 No flaming of other members or companies. Personal attack is not allowed although constructive criticism, with intention to enhance the level of discussion, is permitted. </p>
            <p>5.1.3 Any user that unduly harasses another user by what appears to be constant attacks and baiting will be banned from making any further comment on the websites and Apps. </p>
            <p>5.2 Swearing and vulgarities are not allowed in comments. If the rule is violated, your comment will be removed immediately. Repeated offense will result in suspension from the use of our websites and Apps. </p>
            <p>5.3 Posting privileges may be revoked if users are found impersonating others to make derogatory comments. </p>
            <p>5.4 Do not post copyrighted information without prior consent of SpoutINFO or the authors of the information. Users will be held solely responsible for posting copyrighted information and SpoutINFO is not responsible for posted information that may violate copyright law. Copyrighted posts will be removed as soon as SpoutINFO is aware of it. </p>
            <p>5.5 SpoutINFO is not responsible for the privacy practices of any user. Any personal information released by users is at their own risk. </p>
            <p>5.6 Comments that promote or advertise (but not limited to) websites, businesses, products et cetera will be removed immediately. Repeated offense will result in suspension from the use of our websites and Apps. </p>
            <p>5.7 SpoutINFO does not endorse or support any of the views or opinions posted on the Spout-SHARE websites and Apps. The views and opinions of its users do not reflect those of SpoutINFO. Similarly, reference to any products or companies does not constitute or imply endorsement, sponsorship or recommendation thereof, or any affiliation therewith, by SpoutINFO. </p>
            <p>5.8 Users who find inappropriate content can contact us immediately by emailing to inquiry@SpoutINFO.com. </p>
            <p className="title">6. Refund Policy You shall have no right to any refund for payment made for any service once this service has been activated. However, in instances where the customer feels that a refund is justified, a written request for refund is to be submitted, bearing the following details: </p>
            <p>- transaction identification number </p>
            <p>- reasons for cancellation or refund </p>
            <p>All requests submitted are subject to approval on a case-by-case basis. </p>
            <p className="title">7. Copyright </p>
            <p>The entire contents of the SpoutINFO websites and APPs are copyrighted under the laws of Singapore and protected by worldwide copyright law and treaty or arrangement provisions. Copyright rights in such contents and the software provided and any and all trademarks owned by, or used in connection with the business of SpoutINFO Pte Ltd., and other terms used throughout the Websites and Apps from time to time are trademarks owned exclusively by, and the designs, photographs, text and compositions featured in the Websites and Apps are the exclusively copyrighted property of SpoutINFO Pte Ltd. Other product names contained in the Websites and Apps may be trademarks or registered trademarks of their respective owners. Images of people or places in the Websites and Apps are either the property of or used with permission by SpoutINFO Pte Ltd. The use or misuse of those images except as expressly authorized is prohibited. Materials from the Websites and Apps may not be copied, distributed or transmitted in any way without the prior written consent of SpoutINFO Pte Ltd. </p>
            <p className="title">8. Disclaimers </p>
            <p>8.1 SpoutINFO Pte Ltd. is not responsible for technical, hardware or software failures of any kind; lost or unavailable network connections; incomplete, garbled or delayed computer transmissions. Under no circumstances shall SpoutINFO Pte Ltd. or its affiliates be liable for any damages or injury that result from the use of the materials on this websites and Apps. </p>
            <p>8.2 SpoutINFO Pte Ltd. may provide links to other sites that are not maintained by SpoutINFO Pte Ltd. SpoutINFO Pte Ltd. does not endorse those sites and is not responsible for the content of such other sites. </p>
            <p>8.3 While the material contained in the Websites and Apps have been checked for accuracy, all material is provided without any express or implied warranties. We cannot and do not guarantee continuous uninterrupted or secure access to our services, and operation of our Websites and Apps may be interfered with by numerous factors outside of our control. Neither SpoutINFO Pte Ltd. nor its affiliates assume responsibility for any errors or omissions in the material. In no event shall SpoutINFO Pte Ltd. or its affiliates be liable for any special, direct, indirect or consequential damages resulting from any use or performance of or content errors or omissions in the material, even if notified in advance of the potential for such damages. All users of the material agree that access to and use of the material is subject to the terms and conditions stated in this legal notice, as well as all applicable laws, and such access and use is at the user's own risk. These terms and conditions are subject to change from time to time without notice by updating or revising this legal notice. </p>
            <p>8.4 WE PROVIDE THE SPOUTINFO PTE LTD WEBSITES, APPS AND OUR SERVICES -"AS IS" AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS OR IMPLIED, WE SPECIFICALLY DISCLAIM THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY OR FITNESS. </p>
            <p>8.5 IN NO EVENT SHALL WE BE LIABLE FOR LOST PROFITS OR ANY SPECIAL, DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES (HOWEVER ARISING, INCLUDING NEGLIGENCE) ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT. </p>
            <p className="title">9. Indemnity </p>
            <p>Because we do not and cannot be involved in user-to-user dealings, in the event that you have a dispute with one or more users, you release SpoutINFO Pte Ltd. and any of its affiliates from claims, demands and damages of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes. </p>
            <p className="title">10. Miscellaneous </p>
            <p>10.1 You and SpoutINFO Pte Ltd. are independent contractors, and no agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is intended or created by this Agreement. </p>
            <p>10.2 This Agreement shall be governed by the laws of the Singapore. All parties submit to jurisdiction in Singapore and further agree that any cause of action arising under this Agreement shall be pought exclusively in a court in Singapore. If any part of these terms and conditions is unlawful, void, or unenforceable, that part will be deemed severable and will not affect the validity and enforceability of the remaining provisions. SpoutINFO Pte Ltd. can revise these terms and conditions at any time by updating this posting. </p>
            <p className="title">©2018, SpoutINFO. All rights reserved. </p>
            <p>SpoutINFO Pte Ltd. and its affiliates enforce their intellectual property rights to the fullest extent of the law. If you have any questions concerning the legal notices stated above, you may contact SpoutINFO Pte Ltd. at</p>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
        <div className="contentTerm">
            <p>
            SpoutINFO holds our customers' trust and privacy in high regard. In order to use SpoutINFO's services, customers need to provide contact and billing information and details concerning their area of interests. The information collected is used to contact customers regarding our services, order status and billing purposes if subscription fees are involved. SpoutINFO will never sell or provide personally identifiable information from spoutinfo.com to its partners or any third party. Providing personal information on our Website will never result in "spamming", which is unsolicited email or contact from parties besides SpoutINFO or parties authorized by SpoutINFO.
            </p>
            <p>SpoutINFO is the sole owner of the information collected on this website and related application services.</p>
            <p className="title">
              Registration
            </p>
            <p>
            In order to use the service of this Website and related application services, a customer must first complete the registration form. During registration a customer is required to give their contact information (such as name and email address). This information is used to contact the customer about the services on our Website and application services for which they have expressed interest.
            </p>
            <p className="title">Order</p>
            <p>
            We request information from the customer on our order form. Here a customer must provide contact information (like name and billing address) and financial information (such as credit card number, expiration date). This information is used for billing purposes and to fill the customer's orders. If we have trouble processing an order, this contact information is used to get in touch with the customer.
            </p>
            <p className="title">Use of Cookies</p>
            <p>We take every precaution to protect our customers' information. When customers submit sensitive information via the Website and application services, your information is protected both online and off-line.</p>
            <p>All of our customers' information, not just the sensitive information mentioned above, is restricted in our offices. Only employees who need the information to perform a specific job (for example, our billing clerk or a customer service representative) are granted access to personally identifiable information. Furthermore, ALL employees are kept up-to-date on our security and privacy practices. Every quarter, as well as any time new policies are added, our employees are notified and/or reminded about the importance we place on privacy and security, and what they can do to ensure our customers' information is protected.</p>
            <p className="title">Links</p>
            <p>This Website and application services contain links to other Websites. Please be aware that SpoutINFO is not responsible for the privacy practices of such other Websites. We encourage our customers to be aware of this when they leave our Website and to read the privacy statements of each and every Website that collects personally identifiable information. This privacy statement applies solely to information collected by this Website and our application services.</p>
            <p className="title">Notification of Changes</p>
            <p>If we decide to change our privacy policy, we will post those changes on our website so our customers are always aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. If at any point we decide to use personally identifiable information in a manner different from that stated at the time it was collected, we will notify customers by way of email. Customers will have a choice as to whether or not we use their information in this different manner. We will use information in accordance with the privacy policy under which the information was collected.</p>
            <p className="title">Internet Privacy Policy Statement</p>
            <p>SpoutINFO Pte. Ltd. will at all times fully observe the Personal Data (Privacy) Ordinance ("the Ordinance") in collecting, maintaining and using the personal data of customers.</p>
            <p>Customer's personal data are classified as confidential and can only be disclosed by us where permitted by the Ordinance or otherwise legally compelled to do so.</p>
            <p>Requests for access to data or correction of data or for information regarding policies and practices and kinds of data held should be addressed to:</p>
            <p>Managing Director</p>
            <p>SpoutINFO Pte. Ltd.</p>
            <p>10 Ubi Crescent,</p>
            <p>#05-87, Ubi Techpark,</p>
            <p>Singapore 408564</p>
            <p>If you have any questions about the security at our Website, you can send an email to <span className="email" onClick={sendMail}>admin@spoutinfo.com</span> </p>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}
