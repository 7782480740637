import React from 'react';
import PacmanLoader from 'react-spinners/ScaleLoader';
export default class loading extends React.Component {
  render() {
    return (
      <div className="loading_spontinfo">
        <PacmanLoader
          height={35}
          width={4}
          radius={2}
          margin={'2px'}
          color={'#455a64'}
          loading={true}
        />
      </div>
    );
  }
}