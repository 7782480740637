import axios from 'services/configApi';
const locale = 'en';
export const getCommentReport = async (data) => {
  try {
    var formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });   
    const res = await axios.post(`api/${locale}/data-search/report/comment`,formData);
    
    return res.data;
    
  } catch (error) {

    return Promise.reject(error);

  }
};
