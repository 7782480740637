import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import FormSelect from '../FormSelectProductDetail';
import filter from 'asset/images/filter_outlined.jpg';
import close from 'asset/images/close.png';

function getModalStyle() {
  const top = 0 ;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translateX(-${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 350,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid',
    // boxShadow: theme.shadows[5],
    padding: '10px',
  },
  icon:{
    width: '24px',
    height: '24px'
  },
  font:{
   fontSize:'16px',
   lineHeight:'22px'
  },
  padding:{
      padding:'0 0 0 20px'
  },
  iconClose:{
    width: '15px',
    height: '15px'
  },
  textRight:{
      textAlign:'right',
      padding:'0 22px 0 0'
  },
  container:{
      padding:'0 0 20px 0'
  },
  border:{
    background: '#FFFFFF',
    border: '1px solid #D5D5D5',
    // box-sizing: border-box;
    borderRadius:'5px',
    padding:'6px 13px 6px 13px',
    outline:'none'
  },
  scroll:{
    overflowY:'scroll'
  }
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.textRight}>
      <button className={classes.border} type="button" onClick={handleOpen}>
          <img className={classes.icon} src={filter}/><span> {props.name ? props.name : ''}</span>
      </button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
        className={classes.scroll}
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid className={classes.container}  container>
            <Grid className={classes.padding} item xs={6}>
                <img className={classes.icon} src={filter}/><span  className={classes.font}> Filter</span>
            </Grid>
            <Grid className={classes.textRight} item xs={6}>
                <Grid onClick={handleClose}> <img className={classes.iconClose} src={close}/></Grid>
            </Grid>
          </Grid>
          <FormSelect/>
        </div>
      </Modal>
    </div>
  );
}