import React, { useEffect } from 'react';
import Submited from './Submited';
import Default from './Default';
import Reset from '../ResetPassword';
import { useSelector, useDispatch } from "react-redux";
import { USER_UPDATE_STATUS_RESET } from 'redux/reducers/User/actionTypes';
const ForgetPassword = () => {
  const dispatch = useDispatch();
  const forget = useSelector(state => state.User);
  useEffect(()=>{
    dispatch({
      type: USER_UPDATE_STATUS_RESET,
    })
  },[])

  if(forget.statusForget == 1){
      return (
        <Default/>
      );
    }
  if(forget.statusForget == 2)
  {
    return (
      <Submited/>
    );
  }
  if(forget.statusForget == 3)
  {
    return (
      <Reset/>
    );
  }

}
export default ForgetPassword; 