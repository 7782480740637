import React, {useEffect} from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ProductDetail from 'components/ProductDetail';

const Subscribe  = (props) => {
	useEffect(() => {
	    document.title = 'Subscribed Product SpoutINFO'
	 });
  	return (
	    <div>
	      <Header breadcrumb={props.breadcrumb}/>
	      <ProductDetail/>
	      <Footer/>
	    </div>
  	);
}
export default Subscribe;