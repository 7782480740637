import React, { useEffect, useState } from 'react';
import useToggle from 'hooks/useToggle';
import styles from './styles';
import { Info } from 'DesignSystem/Icons';
import StyledInput from './components/StyledInput';
import InputContainer from './components/InputContainer';
import EyeIcon from './components/EyeIcon';
import WarningIcon from './components/WarningIcon';
import Label from './components/Label';
import Hint from './components/Hint';
import Optional from './components/Optional';
import ArrowTooltip from './components/ArrowTooltip';
import { useTheme } from '@material-ui/core/styles';



const Input = React.memo((props) => {
  const classes = styles();
  const theme = useTheme();
  let {
    id,
    label,
    type,
    fullWidth,
    placeholder,
    value,
    changeHandler,
    name,
    validate,
    error,
    hint,
    showInfo,
    required,
    classInputContainer,
    optional,
    onChangeFunction,
    information,
    disabled,
    iconLeft,
  } = props;
  const [isShowPassword, toggle] = useToggle(false);
  const [stateValue, setStateValue] = useState({
    name: name,
    value: value,
  });

  const renderType = (type) => {
    switch (type) {
      case 'password':
        return isShowPassword ? 'text' : 'password';
      case 'string':
        return 'string';
      case 'number':
        return 'number';
      default:
        return 'text';
    }
  };
  const onChange = e => {
    if (
      typeof changeHandler !== 'function' &&
      typeof onChangeFunction !== 'function'
    )
      return null;
    changeHandler && changeHandler(e.target.value);
    onChangeFunction &&
      setStateValue({
        name: e.target.name,
        value: e.target.value,
      });
  };
  useEffect(() => {
    const commitValue = async () => {
      onChangeFunction && onChangeFunction(stateValue);
    };
    commitValue();
  }, [stateValue]);
  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;
    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  };
  const tranformIconHasHit =
    typeof hint !== 'undefined' ? classes.tranformIconHasHit : '';

  let otherProps = {};
  if (type === 'number') {
    otherProps = {
      onKeyDown: handleKeypress,
      inputProps: {
        min: 0,
      },
    };
  }
  return (
    <React.Fragment>
      <Label className={classes.bold}>
        {/* {Boolean(required) && <Italic>* </Italic>} */}
        {label}
        {Boolean(showInfo) &&
          (information ? (
            <ArrowTooltip title={information}>
              <span className={classes.tranformIcon}>
                <Info
                  width="24px"
                  height="24px"
                  fill={theme.custom.color.paynesGrey}
                />
              </span>
            </ArrowTooltip>
          ) : (
            <Info
              width="24px"
              height="24px"
              fill={theme.custom.color.paynesGrey}
            />
          ))}
        {<Optional> {optional}</Optional>}
      </Label>
      <InputContainer className={classInputContainer}>
        <StyledInput
          id={id}
          name={name}
          type={renderType(type)}
          multiline={type === 'textarea'}
          fullWidth={fullWidth}
          placeholder={placeholder}
          error={Boolean(error)}
          defaultValue={value}
          inputRef={validate}
          onChange={onChange}
          disabled={disabled}
          classes={{
            root: iconLeft ? classes.hasIconLeft : undefined,
            disabled: classes.disabled,
          }}
          {...otherProps}
        />
        {type === 'password' && (
          <EyeIcon
            clickHandler={toggle}
            className={tranformIconHasHit}
            isEyeClose={isShowPassword}
          />
        )}
        { Boolean(error) && (
          <WarningIcon className={tranformIconHasHit} />
        )}
        <Hint>{hint}</Hint>
        {iconLeft && (
          <span className={classes.tranformIconLeft}>{iconLeft}</span>
        )}
      </InputContainer>
    </React.Fragment>
  );
});

export default Input;
