import React, {useEffect} from 'react';
import MarketCommentary from '../../components/MarketCommentary';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
const MarketComm  = (props) => {
  useEffect(() => {
    document.title = 'Market Commentary SpoutINFO'
  });
  return (
    <div>
      <Header breadcrumb={props.breadcrumb}/>
      <MarketCommentary />
      <Footer/>
    </div>
  );
}
export default MarketComm;