import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = makeStyles(() =>
  createStyles({
    pr16: {
      paddingRight: '16px',
    },
    iconInfo: {
      height: '24px',
      paddingLeft: '8px',
    },
    labelSelect: {
      display: 'flex',
      alignItems: 'center',
      '& i': {
        marginRight: 5,
      },
    },
  })
);
export default styles;
