import styled from 'styled-components';

const Label = styled.label`
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 14px;
  position: relative;
  line-height: 1.71;
  color: #242425;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export default Label;
