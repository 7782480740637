import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { purple } from '@material-ui/core/colors';

const BootstrapButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '7px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    width: '410px',
    backgroundColor: '#10253F',
    //borderColor: '#007bff',
    margin: '20px 0 0 0',
    color:'#FFF',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      
    ].join(','),
    
    '&:hover': {
      backgroundColor: '#10253F',
      borderColor: '#10253F',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#10253F',
      borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.1rem rgba(0,123,255,.5)',
    },
  },
})(Button);

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}))(Button);

// const useStyles = makeStyles(theme => ({
//   margin: {
//     margin: theme.spacing(1),
//   },
// }));

// const theme = createMuiTheme({
//   palette: {
//     primary: green,
//   },
// });
export default BootstrapButton;