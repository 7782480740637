
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { routes } from './routes';
import Home from '../pages/Home'
import About  from '../pages/About'
import Login  from '../pages/Login'
import ForgetPassword  from '../pages/ForgetPassword'
import SignUp  from '../pages/SignUp'
import MarketComm from '../pages/MarketComm';
import Subscribe from '../pages/Subscribe';
import Terms from '../pages/Terms'
import ScrollToTop from '../ScrollToTop'
import MarketData from '../pages/MarketData';
import Product from '../pages/ProductDetail';
import MarketForecast from '../pages/MarketForecast';
import UserProfile from '../pages/UserProfile';
import PrivateRouters from './privateRouters';
import PublicRouteters from './publicRouters';
import { useSelector } from "react-redux";
import MarketForecastDetail from '../pages/MarketForecastDetail';
import SubscriptionPlan from '../pages/SubscriptionPlan';

const Routers = () => {

   const isLogin = useSelector(state => state.User.islogin);
    return (
        <Router>
            <ScrollToTop />
           
            <Switch>
              <PrivateRouters
                path={routes.marketForecastDetail}
                component={MarketForecastDetail}
                isLoggedIn={isLogin}
              /> 
                <PrivateRouters
                path={routes.MarketForecastDetailNo}
                component={MarketForecastDetail}
                isLoggedIn={isLogin}
              /> 
              <PublicRouteters
                path={routes.login}
                component={Login}
                isLoggedIn={isLogin}
              />
              <PublicRouteters
                path={routes.signup}
                component={SignUp}
                isLoggedIn={isLogin}
              />
              <Route path={routes.forgetPassword}>
                <ForgetPassword />
              </Route>
              <Route path={routes.term}>
                <Terms breadcrumb={'Terms of Use'}/>
              </Route>
              <Route path={routes.privacy}>
                <Terms breadcrumb={'Privacy Statement'}/>
              </Route>
              <Route path={routes.about}>
                <About breadcrumb={'About Us'}/>
              </Route>
              <Route path={routes.subscribe}>
                <Subscribe breadcrumb={'Subscribe'}/>
              </Route>
              <PublicRouteters
                breadcrumb={'Market Commentary'}
                path={routes.marketComm}
                component={MarketComm}
                isLoggedIn={isLogin}
              />
              <PrivateRouters
                breadcrumb={'Subscribed Product'}
                path={routes.prodetail}
                component={Product}
                isLoggedIn={isLogin}
              />
              <PrivateRouters
                breadcrumb={'Market Data'}
                path={routes.marketData}
                component={MarketData}
                isLoggedIn={isLogin}
              />  
              <PrivateRouters
                breadcrumb={'Account'}
                path={routes.userProfile}
                component={UserProfile}
                isLoggedIn={isLogin}
              />
              <PrivateRouters
                breadcrumb={'Account'}
                path={routes.paymentMethod}
                component={UserProfile}
                isLoggedIn={isLogin}
              />
              <PrivateRouters
                breadcrumb={'My Subscriptions'}
                path={routes.subscriptionPlan}
                component={SubscriptionPlan}
                isLoggedIn={isLogin}
              />
              <PrivateRouters
                breadcrumb={'Market Forecast & Analysis'}
                path={routes.marketForecast}
                component={MarketForecast}
                isLoggedIn={isLogin}
              />
              <Route path={routes.dashboard}>
                <Home breadcrumb={'Home'}/>
              </Route>
            </Switch>
        </Router>
      );
}
export default Routers;
